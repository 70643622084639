import { Box, Button, Container, Image, Spinner, Stack, Text } from "@chakra-ui/react";
import DashBoardHeader from "../../components/Dashboard/Header/DashboardHeader";
import { ArrowRightIcon, ArrowLeftIcon } from "@chakra-ui/icons";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { categoryList, Productlist } from "../../assets/data/Data";
import { useEffect, useState } from "react";
import { GET, POST } from "../../utilities/ApiProvider";
import { useSelector } from "react-redux";
const ProductListingData = () => {

    const accountInfo = useSelector(state => state.value);


    const [listProducts, setListedProducts] = useState([])
    const [isloading, setisloading] = useState(false)

    const fetchData = async () => {
        setisloading(true)
        try {
            // Your code here will execute only once after the component mounts
          
            let marketPlaceData = await GET('/vendor/product/categorywise', { authorization: `Bearer ${accountInfo?.jwtToken}` });
            if (marketPlaceData.status == 200) {
                setListedProducts(marketPlaceData.data)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setisloading(false)
    };


    useEffect(() => {
      
        fetchData();

        // If you need to do some cleanup when the component unmounts, return a function
        return () => {
            // Your cleanup code here
        };
    }, [accountInfo]); // Empty dependency array signifies that this effect should run only once



    const syncProduct = async(product) => {
        setisloading(true)

        try
        {


            let products = await POST('/auth/sync',{} ,{ authorization: `Bearer ${accountInfo?.jwtToken}` });
                if (products.status == 200) {
                    setListedProducts(products.data)
                }
        }
        catch(error)
        {
            console.log("EROR");
        }

        setisloading(false)

    }


    return (
        <>



            <Box h={"100vh"}>
                <DashBoardHeader />

                <Box p={"20px 0px"}>
                    <Container maxW={"container.xl"}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={"35px"}
                        >
                            <Text
                                fontSize={{ base: '20px', sm: "24px" }}
                                fontWeight={"600"}
                            >
                                Product Listing
                            </Text>
                            <Text
                                fontSize={{ base: "14px", sm: "16px" }}
                                fontWeight={"500"}
                            >
                                {listProducts?.length} products
                            </Text>
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={"30px"}
                        >
                            <Text
                                fontSize={{ base: "16px", sm: '20px' }}
                                fontWeight={"500"}
                            >
                                Categories
                            </Text>
                            <Stack
                               
                            >
                                {
                                    listProducts.length? ''
                                    :
                                    <Button onClick={syncProduct} bg={'#D4FD44'}>Find Project</Button>

                                }
                            </Stack>
                        </Stack>

                       
                       {

                        isloading ? 
<Spinner/>
:

                        <Stack
                        direction={"row"}
                        alignItems={"center"}
                        flexWrap={"wrap"}

                        gap={"20px"}
                    >
                        {
                            listProducts?.map((val, key) => (
                                <Stack
                                    key={key}
                                    ml={"0px ! important"}
                                    w={{ base: '340px', lg: "400px" }}
                                    p={"15px 20px"}
                                    borderRadius={"12px"}
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={"10px"}
                                    bg={"#EEEEEE"}
                                >
                                    <Image
                                        src={val.picture}
                                        h={"150px"}
                                        w={"250px"}
                                        borderRadius={"11px"}
                                    />
                                    <Stack gap={1}>
                                        <Text
                                            fontSize={"18px"}
                                            fontWeight={"600"}
                                        >
                                            {val.name}
                                        </Text>
                                        <Text
                                            display={'flex'}
                                            fontSize={"14px"}
                                            fontWeight={"500"}
                                        >
                                        </Text>
                                        <Text
                                            fontWeight={"500"}
                                            display={'flex'}
                                        >
                                            <Text fontWeight={"700"}>Price</Text>  :  {val.price}
                                        </Text>
                                    </Stack>
                                </Stack>
                            ))
                        }
                    </Stack>
                       }
                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default ProductListingData;