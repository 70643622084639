import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Stack,
  useDisclosure,
  Container,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Select,
  Switch,
  useToast,
  Grid,
  GridItem,
  Icon,
} from '@chakra-ui/react';
import SuperHeader from '../../components/SuperAdminPanel/SuperHeader';
import { useSelector } from 'react-redux';
import {
  CheckIcon,
} from '@chakra-ui/icons';
import { DELETE, POST, PUT } from '../../utilities/ApiProvider';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Membership() {
  const location = useLocation();
  const navigate = useNavigate();
  const [feature, setFeature] = useState([]);
  const toast = useToast();
  const initialFeatures = [
    { text: 'Feature 1' },
    { text: 'Feature 2' },
    { text: 'Feature 3' },
  ];

  const [featureInputs, setFeatureInputs] = useState([]);
  const [features, setFeatures] = useState([]);
  const [signupInfo, setSignupInfo] = useState({
    features: featureInputs,
  });

  const [updatedData, setupdateData] = useState({

  });

  const membership = useSelector(state => state.membership);
  console.log(membership);
  const createPlan = async (req, res) => {
    try {
      let res = await POST(`/admin/plans`, signupInfo);
      if (res.status == 200) {
        toast({
          description: res.message,
        });
      } else {
        toast({
          description: res.message,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addFeature = () => {
    setFeatures([...features, '']);
    setFeatureInputs([...featureInputs, '']); // Initialize input value for the new feature
  };

  const handleFeatureChange = (index, value) => {
    const newFeatureInputs = [...featureInputs];
    newFeatureInputs[index] = value;
    setFeatureInputs(newFeatureInputs);
  };

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  // Define state variables for controlling modals
  const [isOpen3, setIsOpen3] = useState(false);
  const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
  // Function to open "Widget" modal
  const onOpen3 = () => setIsOpen3(true);
  // Function to open "Membership Plans" modal
  const onOpenMembershipModal = () => setIsMembershipModalOpen(true);

  // Define state variables for controlling modals
  const [isOpen4, setIsOpen4] = useState(false);
  // Function to open "Widget" modal
  const onOpen4 = () => setIsOpen4(true);



  //  EDIT PLAN SETTINGS

  const [isFree, setIsFree] = useState(false);
  const [setupEditInfo, setSetupEditInfo] = useState({});
  const [featuresList, setFeaturesList] = useState([]);
  const handleFeatureValChange = (index, value) => {
    // const newFeatureInputs = [...newFeatureInputs];
    // newFeatureInputs[index] = value;
    let newArr = featuresList?.map((val, ind) => {
      if (ind === index) {
        return {
          text: value
        }
      } else {
        return val
      }
    })
    setFeaturesList(newArr);
  };

  const removeFeature = (index) => {
    let newArr = featuresList?.filter((val, ind) => ind !== index)
    setFeaturesList(newArr);
  }

  const updatePlan = async () => {
    try {
      let res = await PUT(`/admin/plans/${setupEditInfo?.id}`, {
        title: setupEditInfo?.title,
        description: setupEditInfo?.description,
        price: setupEditInfo?.price,
        duration: setupEditInfo?.duration,
        features: featuresList
      });
      if (res.status == 200) {
        toast({
          description: res.message,
        });
      } else {
        toast({
          description: res.message,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deletePlan = async (id) => {
    try {
      let res = await DELETE(`/admin/plans/${id}`);
      if (res.status == 200) {
        toast({
          description: res.message,
        });
      } else {
        toast({
          description: res.message,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {/* create new plan Modal  */}
      <Modal isOpen={isOpen4} onClose={() => setIsOpen4(false)} isCentered>
        <ModalOverlay bg={'#0000004f'} />
        <ModalContent
          maxW={{ base: '350px', sm: '645px' }}
          p={{ base: '10px 20px', sm: '15px 50px' }}
          borderRadius={'24px'}
        >
          <ModalCloseButton top={'-35px'} right={'10px'} color={'#FFF'} />
          <ModalBody p={'10px 0px ! important'}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={'15px'}
            >
              <Text fontSize={'22px'} fontWeight={'600'}>
                Create New Plan
              </Text>
              <Button
                onClick={createPlan}
                fontSize={'18px'}
                fontWeight={'500'}
                bg={'#D4FD44'}
              >
                Save & Continue
              </Button>
            </Stack>
            <Box mb={'20px'}>
              <Box mb={'15px'}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  mb={'5px'}
                >
                  <Text fontSize={'17px'} fontWeight={'500'}>
                    Plan Type
                  </Text>
                </Stack>
                <Select
                  onChange={e =>
                    setSignupInfo({
                      ...signupInfo,
                      title: e.target.value,
                    })
                  }
                  bg={'#EEEEEE'}
                  borderRadius={'12px'}
                  border={'1px solid #00000073'}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  _focusVisible={{ boxShadow: 'none' }}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                  <option value="Free Trail">Free Trail</option>
                </Select>
              </Box>
              <Box>
                <FormLabel fontSize={'17px'} fontWeight={'500'}>
                  $Price
                </FormLabel>
                <Input
                  onChange={e =>
                    setSignupInfo({
                      ...signupInfo,
                      price: Number(e.target.value),
                    })
                  }
                  bg={'#EEEEEE'}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  type="number"
                  placeholder="Enter here"
                  _focusVisible={{ boxShadow: 'none' }}
                />
              </Box>
              <Box>
                <FormLabel fontSize={'17px'} fontWeight={'500'}>
                  description
                </FormLabel>
                <Input
                  onChange={e =>
                    setSetupEditInfo({
                      ...signupInfo,
                      description: e.target.value,
                    })
                  }
                  bg={'#EEEEEE'}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  placeholder="Enter here"
                  _focusVisible={{ boxShadow: 'none' }}
                />
              </Box>
              <Box>
                <FormLabel fontSize={'17px'} fontWeight={'500'}>
                  Duration
                </FormLabel>
                <Input
                  onChange={e =>
                    setSignupInfo({
                      ...signupInfo,
                      duration: e.target.value,
                    })
                  }
                  bg={'#EEEEEE'}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  placeholder="Enter here"
                  _focusVisible={{ boxShadow: 'none' }}
                />
              </Box>
            </Box>
            <Stack gap={'10px'}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text fontSize={'20px'} fontWeight={'500'}>
                  Features
                </Text>
                <Button
                  onClick={addFeature}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  bg={'#D4FD44'}
                >
                  Add Feature
                </Button>
              </Stack>
              {feature.length > 0 && feature?.map((val, index) => (
                <Stack key={index}>
                  <Input
                    bg={'#EEEEEE'}
                    fontSize={'18px'}
                    fontWeight={'500'}
                    placeholder="Enter here"
                    value={feature[val] ?? ''} // Use corresponding input value
                    onChange={event =>
                      handleFeatureChange(index, event.target.value)
                    }
                    _focusVisible={{ boxShadow: 'none' }}
                  />
                </Stack>
              ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* create new plan Modal  */}
      <Modal isOpen={isEditOpen} onClose={onEditClose} isCentered>
        <ModalOverlay bg={'#0000004f'} />
        <ModalContent
          maxW={{ base: '350px', sm: '645px' }}
          p={{ base: '10px 20px', sm: '15px 50px' }}
          borderRadius={'24px'}
        >
          <ModalCloseButton top={'-35px'} right={'10px'} color={'#FFF'} />
          <ModalBody p={'10px 0px ! important'}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={'35px'}
            >
              <Text fontSize={'22px'} fontWeight={'600'}>
                Edit Plan
              </Text>
              <Button fontSize={'18px'} fontWeight={'500'} bg={'#D4FD44'} onClick={updatePlan}>
                Save & Continue
              </Button>
            </Stack>
            <Box mb={'20px'}>
              <Box mb={'15px'}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  mb={'5px'}
                >
                  <Text fontSize={'17px'} fontWeight={'500'}>
                    Plan Type
                  </Text>
                  <Stack direction={'row'} alignItems={'center'} gap={'5px'}>
                    <Text fontSize={'17px'} fontWeight={'500'}>
                      Free Trial
                    </Text>
                    <Switch onChange={e => setIsFree(e.target.checked)} />
                  </Stack>
                </Stack>
                <Select
                  bg={'#EEEEEE'}
                  onChange={e =>
                    setSetupEditInfo({
                      ...setupEditInfo,
                      title: e.target.value,
                    })
                  }
                  borderRadius={'12px'}
                  border={'1px solid #00000073'}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  _focusVisible={{ boxShadow: 'none' }}
                  placeholder={setupEditInfo?.title}
                >
                  <option>Monthly</option>
                  <option>Yearly</option>
                </Select>
              </Box>
              <Box mb={3}>
                <FormLabel fontSize={'17px'} fontWeight={'500'}>
                  $Price
                </FormLabel>
                <Input
                  value={setupEditInfo?.price}
                  onChange={e =>
                    setSetupEditInfo({
                      ...setupEditInfo,
                      price: Number(e.target.value),
                    })
                  }
                  bg={'#EEEEEE'}
                  fontSize={'18px'}
                  fontWeight={'500'}
                  placeholder="Enter here"
                  _focusVisible={{ boxShadow: 'none' }}
                />
              </Box>
              <Stack gap={'10px'}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Text fontSize={'20px'} fontWeight={'500'}>
                    Add Features
                  </Text>
                  <Button
                    onClick={addFeature}
                    fontSize={'18px'}
                    fontWeight={'500'}
                    bg={'#D4FD44'}
                  >
                    Feature
                  </Button>
                </Stack>
                {featuresList.map((feature, index) => {
                  return (
                    <Stack key={index} direction={'row'} >
                      <Input
                        bg={'#EEEEEE'}
                        fontSize={'18px'}
                        fontWeight={'500'}
                        placeholder="Enter here"
                        value={feature?.text || ''} // Use corresponding input value
                        onChange={event =>
                          handleFeatureValChange(index, event.target.value)
                        }
                        _focusVisible={{ boxShadow: 'none' }}
                      />
                      <Button onClick={() => removeFeature(index)}>-</Button>
                    </Stack>
                  )
                })}
              </Stack>
            </Box>

          </ModalBody>
        </ModalContent>
      </Modal>

      <SuperHeader />

      <Box p={'40px 0px'}>
        <Container maxW={'container.xl'}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={'30px'}
          >
            <Box>
              <Text fontSize={'22px'} fontWeight={'600'}>
                Current Plans
              </Text>
              <Text fontSize={'15px'}>You’ve currently 3 plans</Text>
            </Box>
            <Button
              fontSize={'18px'}
              fontWeight={'500'}
              bg={'#D4FD44'}
              borderRadius={'12px'}
              onClick={() => {
                setIsMembershipModalOpen(false); // Close the first modal
                onOpen4(); // Open the second modal
              }}
            >
              Create New Plan
            </Button>
          </Stack>

          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {membership?.map(e => (
              <GridItem w="100%">
                <Box borderRadius={'13px'} bg={'#EEEEEE'}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    bg={'#15171F'}
                    borderRadius={'13px 13px 0px 0px'}
                    p={'15px 20px'}
                  >
                    <Text fontSize={'22px'} fontWeight={'600'} color={'#FFF'}>
                      <Text color={'#D4FD44'} fontSize={'15px'}>{e.title}</Text>
                      ${e.price}
                    </Text>
                    <Text
                      bg={'#D4FD44'}
                      fontSize={'13px'}
                      fontWeight={'500'}
                      p={'2px 8px'}
                      borderRadius={'6px'}
                    >
                      {e.duration}
                    </Text>
                  </Stack>

                  <Box p={'15px 20px'}>
                    <Text fontWeight={'500'} mb={'10px'}>
                      Features:
                    </Text>
                    <Stack mb={'35px'}>
                      {e.features?.map(fe => (
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          gap={'3px'}
                        >
                          <Stack
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#D4FD44'}
                            borderRadius={'50%'}
                            w={'18px'}
                            h={'18px'}
                          >
                            <CheckIcon fontSize={'12px'} />
                          </Stack>
                          <Text fontSize={'14px'}>
                            Lorem ipsum dolor sit amet
                          </Text>
                        </Stack>
                      ))}
                    </Stack>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Button
                        onClick={() => {
                          setSetupEditInfo({
                            title: e?.title,
                            price: e?.price,
                            duration: e?.duration,
                            id: e?._id,
                          });
                          setFeaturesList(e?.features)
                          console.log(e?.features)
                          onEditOpen();
                        }}

                        bg={'#D4FD44'}
                        border={'1px solid #D4FD44'}
                        h={'auto'}
                        w={'140px'}
                        p={'5px 0px'}
                        fontSize={'15px'}
                        fontWeight={'500'}
                        _hover={{
                          bg: 'none',
                          border: '1px solid #000',
                        }}
                      >
                        Edit Plan
                      </Button>
                      <Button
                        onClick={() => deletePlan(e?._id)}
                        bg={'none'}
                        border={'1px solid #000'}
                        h={'auto'}
                        w={'140px'}
                        p={'5px 0px'}
                        fontSize={'15px'}
                        fontWeight={'500'}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Container>
      </Box >
    </>
  );
}
