import { Box, Flex, Text, IconButton, Button, Stack, Collapse, Icon, useColorModeValue, useDisclosure, Container, Image, FormLabel, Input, useClipboard, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import plugin from '../../../assets/images/website/home/plugin.png';
import ssLogo from '../../../assets/images/website/home/sslogo.png';
import mem1 from '../../../assets/images/Dashboard/mem1.png';
import { GET } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

export default function DashBoardHeader() {
    const { isOpen, onToggle } = useDisclosure();
    const location = useLocation();

    const accountInfo = useSelector(state => state.value);


    const StaticAuthenticationKeyInput = () => {
        const staticKey = accountInfo?.widgetKey;
        const { hasCopied, onCopy } = useClipboard(staticKey);
        return (
            <Box pos={"relative"}>
                <Input
                    value={staticKey}
                    isReadOnly
                    type='text'
                    w={{ base: '300px', sm: '420px', lg: "360px", xl: '420px' }}
                    h={'auto'}
                    fontSize={{ base: "13px", sm: "15px" }}
                    fontWeight={"500"}
                    p={{ base: "5px 15px", sm: '7px 20px' }}
                    border={"1px solid #000 ! important"}
                    placeholder='pkFGZ5upw2'
                    _placeholder={{
                        color: "#363636"
                    }}
                    _focusVisible={{
                        boxShadow: "none"
                    }}
                />
                <Button
                    onClick={onCopy}
                    pos={"absolute"}
                    h={"auto"}
                    fontSize={{ base: '12px', sm: '16px', lg: "14px", xl: "16px" }}
                    p={{ base: '9px 25px', sm: "12px 45px", lg: '12px 35px', xl: "12px 45px" }}
                    top={"-1px"}
                    right={"-2px"}
                    bg={"#D4FD44"}
                >
                    {hasCopied ? "Copied!" : "Copy"}</Button>
            </Box>
        );
    };

    return (
        <Box
            bg={"#15171F"}
            borderRadius={"0px 0px 25px 25px"}
        >
            <Container maxW={"container.xl"}>
                <Flex
                    bg={'none'}
                    color={useColorModeValue('gray.600', 'white')}
                    minH={'60px'}
                    p={"30px 0px"}
                    borderBottom={"1px solid #FFFFFF73"}
                    align={'center'}>
                    <Flex
                        flex={{ base: 1, lg: 'auto' }}
                        ml={{ base: -2 }}
                        display={{ base: 'flex', lg: 'none' }}>

                        <IconButton
                            onClick={onToggle}
                            icon={
                                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                            }
                            variant={'ghost'}
                            aria-label={'Toggle Navigation'}
                        />
                    </Flex>
                    <Flex
                        flex={{ base: 1, sm: "none", lg: 1 }}
                        justify={{ base: 'center', lg: 'start' }}
                        alignItems={"center"}
                        justifyContent={{ base: "right", sm: "space-between", md: "space-between", lg: "space-between", xl: "space-between" }}
                    >
                        <Link to={"/dashboard"}>
                        <Image  
                src={ssLogo} 
                w={{base:"110px",sm:"150px",md:"150px",lg:"150px",xl:"150px"}}
              />
                        </Link>

                        <Flex
                            display={{ base: 'none', lg: 'flex' }}
                            ml={10}
                        >
                            <DesktopNav />
                        </Flex>
                    </Flex>
                </Flex>



                <Collapse in={isOpen} animateOpacity>
                    <MobileNav />
                </Collapse>

                {location.pathname === "/dashboard" ?
                    <Stack
                        p={"30px 0px"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={{ base: 'center', lg: "space-between" }}
                        flexWrap={"wrap"}
                        gap={{ base: "10px", lg: "0px" }}
                    >
                        <Box
                            bg={"#FFF"}
                            w={{ base: "330px", sm: "550px", xl: '670px' }}
                            p={{ base: '10px 15px', sm: '15px 20px', lg: "20px 30px" }}
                            borderRadius={"13px"}
                        >
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={{ base: '5px', lg: "10px" }}
                                >
                                    <Stack

                                        w={{ base: '40px', sm: '72px' }}
                                        h={{ base: '40px', sm: '72px' }}
                                        bg={"#D4FD44"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        borderRadius={"50%"}
                                    >
                                        <Image
                                            src={plugin}
                                            w={{ base: "20px", sm: '30px' }}
                                            h={{ base: "20px", sm: '30px' }}
                                        />
                                    </Stack>
                                    <Box ml={"0px ! important"}>
                                        <Text
                                            fontSize={{ base: '16px', sm: '22px', lg: '20px', xl: "22px" }}
                                            fontWeight={"500"}
                                        >
                                        <a href='https://idealjobsworld.site/dowanloadble/dummy-search-plugin.zip'>Download Plugin</a>    
                                        </Text>
                                        <Text
                                            fontSize={{ base: '13px', sm: '20px', lg: '18px', xl: '20px' }}
                                            textDecorationLine={"underline"}
                                        >
                                            How to install the plugin?
                                        </Text>
                                    </Box>
                                </Stack>
                                <a
                                    href='https://idealjobsworld.site/dowanloadble/dummy-search-plugin.zip'
                                    to={"#"}
                                    h={"auto"}
                                    p={{ base: "8px 8px", sm: "15px 35px", lg: '10px 25px', xl: "15px 35px" }}
                                    fontSize={{ base: '12px', sm: '18px', lg: '16px', xl: "18px" }}
                                    fontWeight={"500"}
                                    bg={"#D4FD44"}
                                    borderRadius={"12px"}
                                >
                                    Download
                                </a>
                            </Stack>
                        </Box>
                        <Box
                            ml={"0px ! important"}
                            bg={"#FFF"}
                            w={{ base: "330px", sm: "550px", lg: "400px", xl: '480px' }}
                            p={"20px 0px"}
                            borderRadius={"13px"}
                        >
                            <Stack alignItems={"center"}>
                                <Box>
                                    <FormLabel
                                        fontSize={{ base: "14px", sm: '16px' }}
                                        fontWeight={"500"}
                                    >
                                        Authentication Key:
                                    </FormLabel>
                                    <StaticAuthenticationKeyInput />
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                    : null
                }
            </Container>
        </Box>
    );
}

const NAV_ITEMS = [
    {
        label: 'Home',
        href: "/dashboard",
    },
    {
        label: 'Widget',
        href: "/widget",
    },
    {
        label: 'Product Listing',
        href: "/productlistingdata",
    },
    {
        label: 'Account',
        href: "/account",
    }
];

const Mob_NAV_ITEMS = [
    {
        label: 'Home',
        href: "/dashboard",
        isModal: false
    },
    {
        label: 'Widget',
        href: "/widget",
        isModal: false
    },
    {
        label: 'Product Listing',
        href: "/productlistingdata",
        isModal: false
    },
    {
        label: 'Account',
        href: "/account",
        isModal: false
    }, {
        label: 'Membership Plan',
        href: "#",
        isModal: true
    },
    {
        label: 'Logout',
        href: "/login",
        isModal: false
    }
];

const DesktopNav = () => {
    const location = useLocation();

    // Setup Membership information

    const [membership, setMembership] = useState([])
    const [selectedMemberShip, setSelectedMemberShip] = useState(null);


    const fetchData = async () => {
        try {
            // Your code here will execute only once after the component mounts
            let userData = await GET(`/admin/plans`, {});
            console.log(userData);
            if (userData.status == 200) {
                setMembership(userData.data)

            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData()


        return () => {
        }
    }, [])


    const [isOpen2, setIsOpen2] = useState(false);
    const onOpen2 = () => setIsOpen2(true);
    const onClose2 = () => setIsOpen2(false);
    return (
        <>
            <Stack direction={'row'} spacing={4} alignItems={"center"}>
                {NAV_ITEMS.map((navItem) => (
                    <Box key={navItem.label}>
                        <Button
                            as={Link}
                            to={navItem.href}
                            fontSize={{ base: '14px', sm: '16px', md: '16px', lg: '15px', xl: '16px' }}
                            fontWeight={"500"}
                            color={location.pathname === navItem.href ? "#15171F" : "#848484"}
                            bg={location.pathname === navItem.href ? "#D4FD44" : "none"}
                            textDecorationLine={location.pathname === navItem.href ? "none ! important" : "none"}
                            p={{ lg: "8px 10px", xl: "8px 15px" }}
                            height={"auto"}
                            borderRadius={"8px"}
                            _hover={{
                                textDecorationLine: "none ! important",
                                background: "#D4FD44",
                                color: "#15171F",
                            }}
                        >
                            {navItem.label}
                        </Button>
                    </Box>
                ))}
                <Box>
                    <Button
                        onClick={onOpen2}
                        fontSize={{ base: '14px', sm: '16px', md: '16px', lg: '15px', xl: '16px' }}
                        fontWeight={"500"}
                        color={"#848484"}
                        bg={"none"}
                        textDecorationLine={"none"}
                        p={{ lg: "8px 10px", xl: "8px 15px" }}
                        height={"auto"}
                        borderRadius={"8px"}
                        _hover={{
                            textDecorationLine: "none ! important",
                            background: "#D4FD44",
                            color: "#15171F",
                        }}
                    >
                        Membership Plan
                    </Button>
                    <Modal
                        isOpen={isOpen2}
                        onClose={onClose2}
                        isCentered
                    >
                        <ModalOverlay
                            bg={"#000"}
                            opacity={"0.78 ! important"}
                        />
                        <ModalContent
                            maxW={"692px"}
                            p={"15px 50px"}
                            borderRadius={"24px"}
                        >
                            <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                            <ModalBody
                                p={"10px 0px ! important"}
                            >
                                <Stack
                                    alignItems={"center"}
                                    mb={"15px"}
                                >
                                    <Stack
                                        h={"100px"}
                                        w={"100px"}
                                        borderRadius={"50%"}
                                        bg={"#D4FD44"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <Image src={mem1} />
                                    </Stack>
                                    <Text
                                        fontSize={"28px"}
                                        fontWeight={"600"}
                                    >
                                        Membership Plan
                                    </Text>
                                    <Text>Upgrade your membership plan to access all premium features</Text>
                                </Stack>
                                <Text
                                    fontSize={"17px"}
                                    fontWeight={"500"}
                                    mb={"10px"}
                                >
                                    Current Membership Plan
                                </Text>
                                {
                                    membership?.map((e) =>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"flex-end"}
                                            justifyContent={"space-between"}
                                            bg={selectedMemberShip === e?._id ? "#D4FD44" : 'transparent'}
                                            border={'2px solid'}
                                            borderColor={'#D4FD44'}
                                            p={"6px 30px"}
                                            borderRadius={"12px"}
                                            mb={"15px"}
                                            cursor={'pointer'}
                                            onClick={() => setSelectedMemberShip(e?._id)}
                                        >
                                            <Box>
                                                <Text
                                                    fontWeight={"500"}
                                                >
                                                    {e.title}
                                                </Text>
                                                <Text
                                                    fontSize={"22px"}
                                                    fontWeight={"500"}
                                                >
                                                    {e.duration}
                                                </Text>
                                            </Box>
                                            <Text
                                                as={Link}
                                                to={"#"}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                textDecorationLine={"underline ! important"}
                                            >
                                                Limited Features
                                            </Text>
                                        </Stack>
                                    )
                                }

                                {/* <Stack
                                direction={"row"}    
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                mb={"15px"}
                                >
                                <Stack 
                                    direction={"row"} 
                                    alignItems={"flex-end"} 
                                    justifyContent={"space-between"}
                                    bg={"#EAEAEA"}
                                    w={"280px"}
                                    p={"5px 20px"}
                                    borderRadius={"12px"}
                                    >
                                    <Box>
                                        <Text fontWeight={"500"}>Monthly</Text>
                                        <Text fontSize={"22px"} fontWeight={"500"}>$20.00</Text>
                                    </Box>
                                    <Text
                                        as={Link}
                                        to={"#"}
                                        fontSize={"14px"}
                                        fontWeight={"500"}
                                        textDecorationLine={"underline ! important"}
                                        >
                                        What’s Include
                                    </Text>
                                </Stack>
                                <Stack 
                                    direction={"row"} 
                                    alignItems={"flex-end"} 
                                    justifyContent={"space-between"}
                                    bg={"#EAEAEA"}
                                    w={"280px"}
                                    p={"5px 20px"}
                                    borderRadius={"12px"}
                                    >
                                    <Box>
                                        <Text fontWeight={"500"}>Yearly</Text>
                                        <Text fontSize={"22px"} fontWeight={"500"}>$180.00</Text>
                                    </Box>
                                    <Text
                                        as={Link}
                                        to={"#"}
                                        fontSize={"14px"}
                                        fontWeight={"500"}
                                        textDecorationLine={"underline ! important"}
                                        >
                                        What’s Include
                                    </Text>
                                </Stack>
                            </Stack> */}

                                <Stack alignItems={"center"}>
                                    <Button
                                        isDisabled = {true}
                                        bg={"#D4FD44"}
                                        borderRadius={"12px"}
                                        h={"auto"}
                                        p={"12px 35px"}
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </Box>
                <Button
                    as={Link}
                    to={"/login"}
                    h={"auto"}
                    p={{ lg: "7px 10px", xl: '7px 15px' }}
                    bg={"#D4FD44"}
                    fontSize={{ base: '14px', sm: '16px', md: '16px', lg: '15px', xl: '17px' }}
                    fontWeight={"500"}
                    color={"#15171F"}
                    border={"1px solid transparent"}
                    borderRadius={"8px"}
                    _hover={{
                        bg: "none",
                        color: "#D4FD44",
                        border: "1px solid #D4FD44"
                    }}
                >
                    Log Out
                </Button>
            </Stack >
        </>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ lg: 'none' }}>
            {Mob_NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}

        </Stack>
    );
};

const MobileNavItem = ({ label, children, href, isModal }) => {
    const { isOpen, onToggle } = useDisclosure();
    const navigate = useNavigate();
    const [isOpen2, setIsOpen2] = useState(false);
    const onOpen2 = () => setIsOpen2(true);
    const onClose2 = () => setIsOpen2(false);
    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}
                    onClick={() => isModal ? onOpen2() : navigate(`${href}`)}
                >
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} to={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
            {/* Membership Popup */}
            <Modal
                isOpen={isOpen2}
                onClose={onClose2}
                isCentered
            >
                <ModalOverlay
                    bg={"#000"}
                    opacity={"0.78 ! important"}
                />
                <ModalContent
                    maxW={{ base: '350px', sm: "692px" }}
                    p={{ base: '10px 20px', sm: "15px 50px" }}
                    borderRadius={"24px"}
                >
                    <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                    <ModalBody
                        p={"10px 0px ! important"}
                    >
                        <Stack
                            alignItems={"center"}
                            mb={"15px"}
                        >
                            <Stack
                                w={{ base: '60px', sm: '100px' }}
                                h={{ base: '60px', sm: '100px' }}
                                borderRadius={"50%"}
                                bg={"#D4FD44"}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Image
                                    src={mem1}
                                    w={{ base: '35px', sm: '52px' }}
                                    h={{ base: '30px', sm: '46px' }}
                                />
                            </Stack>
                            <Text
                                fontSize={{ base: '22px', sm: '28px' }}
                                fontWeight={"600"}
                            >
                                Membership Plan
                            </Text>
                            <Text
                                fontSize={{ base: "13px", sm: '16px' }}
                            >
                                Upgrade your membership plan to access all premium features
                            </Text>
                        </Stack>
                        <Text
                            fontSize={{ base: '15px', sm: "17px" }}
                            fontWeight={"500"}
                            mb={"10px"}
                        >
                            Current Membership Plan
                        </Text>
                        <Stack
                            direction={"row"}
                            alignItems={"flex-end"}
                            justifyContent={"space-between"}
                            bg={"#D4FD44"}
                            p={"6px 30px"}
                            borderRadius={"12px"}
                            mb={"15px"}
                        >
                            <Box>
                                <Text
                                    fontSize={{ base: '13px', sm: "16px" }}
                                    fontWeight={"500"}
                                >
                                    Free Trial
                                </Text>
                                <Text
                                    fontSize={{ base: '18px', sm: "22px" }}
                                    fontWeight={"500"}
                                >
                                    7 Days
                                </Text>
                            </Box>
                            <Text
                                as={Link}
                                to={"#"}
                                fontSize={{ base: '12px', sm: "14px" }}
                                fontWeight={"500"}
                                textDecorationLine={"underline ! important"}
                            >
                                Limited Features
                            </Text>
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            flexWrap={"wrap"}
                            gap={{ base: "15px", sm: "unset" }}
                            mb={"15px"}
                        >
                            <Stack
                                direction={"row"}
                                alignItems={"flex-end"}
                                justifyContent={"space-between"}
                                bg={"#EAEAEA"}
                                w={{ base: "100%", sm: "280px" }}
                                p={"5px 20px"}
                                borderRadius={"12px"}
                            >
                                <Box>
                                    <Text
                                        fontSize={{ base: '13px', sm: "16px" }}
                                        fontWeight={"500"}
                                    >
                                        Monthly
                                    </Text>
                                    <Text
                                        fontSize={{ base: '18px', sm: "22px" }}
                                        fontWeight={"500"}
                                    >
                                        $20.00
                                    </Text>
                                </Box>
                                <Text
                                    as={Link}
                                    to={"#"}
                                    fontSize={{ base: '12px', sm: "14px" }}
                                    fontWeight={"500"}
                                    textDecorationLine={"underline ! important"}
                                >
                                    What’s Include
                                </Text>
                            </Stack>
                            <Stack
                                ml={"0px ! important"}
                                direction={"row"}
                                alignItems={"flex-end"}
                                justifyContent={"space-between"}
                                bg={"#EAEAEA"}
                                w={{ base: "100%", sm: "280px" }}
                                p={"5px 20px"}
                                borderRadius={"12px"}
                            >
                                <Box>
                                    <Text
                                        fontSize={{ base: '13px', sm: "16px" }}
                                        fontWeight={"500"}
                                    >
                                        Yearly
                                    </Text>
                                    <Text
                                        fontSize={{ base: '18px', sm: "22px" }}
                                        fontWeight={"500"}
                                    >
                                        $180.00
                                    </Text>
                                </Box>
                                <Text
                                    as={Link}
                                    to={"#"}
                                    fontSize={{ base: '12px', sm: "14px" }}
                                    fontWeight={"500"}
                                    textDecorationLine={"underline ! important"}
                                >
                                    What’s Include
                                </Text>
                            </Stack>
                        </Stack>
                        <Box mb={{ base: '15px', sm: "20px" }}>
                            <FormLabel
                                fontSize={{ base: '15px', sm: "17px" }}
                                fontWeight={"500"}
                            >
                                Promo Code
                            </FormLabel>
                            <Input
                                type='text'
                                h={"auto"}
                                fontSize={{ base: '14px', sm: "18px" }}
                                fontWeight={"500"}
                                p={{ base: '5px 20px', sm: "6px 25px" }}
                                bg={"#EEEEEE"}
                                border={"1px solid #000 ! important"}
                                placeholder='Enter Here'
                                _placeholder={{
                                    color: "#000"
                                }}
                                _focusVisible={{
                                    boxShadow: "none"
                                }}
                            />
                        </Box>
                        <Stack alignItems={"center"}>
                            <Button
                                bg={"#D4FD44"}
                                borderRadius={"12px"}
                                h={"auto"}
                                p={{ base: '9px 30px', sm: "12px 35px" }}
                                fontSize={{ base: "13px", sm: '16px' }}
                            >
                                Continue
                            </Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Stack>
    );
};