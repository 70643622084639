import help1 from '../images/website/home/help1.png';
import help2 from '../images/website/home/help2.png';
import help3 from '../images/website/home/help3.png';
import sol1 from '../images/website/home/sol1.png';
import tool1 from '../images/website/home/tool1.png';
import tool2 from '../images/website/home/tool2.png';
import tool3 from '../images/website/home/tool3.png';
import tool4 from '../images/website/home/tool4.png';
import tool5 from '../images/website/home/tool5.png';
import tool6 from '../images/website/home/tool6.png';
import loved1 from '../images/website/home/loved1.png';
import loved2 from '../images/website/home/loved2.png';
import loved3 from '../images/website/home/loved3.png';
import type1 from '../images/website/Auth/type1.png';
import type2 from '../images/website/Auth/type2.png';
import type3 from '../images/website/Auth/type3.png';
import type4 from '../images/website/Auth/type4.png';
import type5 from '../images/website/Auth/type5.png';
import type6 from '../images/website/Auth/type6.png';
import type7 from '../images/website/Auth/type7.png';
import type8 from '../images/website/Auth/type8.png';
import productData from '../images/Dashboard/productData.png';
import searchItem from '../images/Dashboard/searchItem.png';
import searchItem2 from '../images/Dashboard/searchItem2.png';
import searchItem3 from '../images/Dashboard/searchItem3.png';
import searchItem4 from '../images/Dashboard/searchItem4.png';


const helpBox =[
    {
        img: help1,
        head: "Sign Up & Buying Membership Plan",
        text: "Sign up now and select a membership plan that brings about the latest features for a better search experience on your website.",
    },
    {
        img: help2,
        head: "Download & Install Plugin",
        text: "Download our Style Sense Plugin effortlessly and set up a superior search experience.",
    },
    {
        img: help3,
        head: "Customize Your Search Widget",
        text: "Get a personalized experience with Style Sense. Easily customize your search widget for a uniquely efficient browsing journey.",
    },
]

const topNotch=[
    {
        head:"Choose Your Website Type",
        text:"Add Style Sense to your platform – whether it's WooCommerce, Shopify, HTML, or more, we've got you covered!"
    },
    {
        head:"Choose Layout ",
        text:"Elevate your website's aesthetics with Style Sense – choose from a variety of layouts and themes tailored just for you!"
    },
    {
        head:"Product Inventory",
        text:"Effortlessly manage your product inventory with Style Sense – a seamless, user-friendly listing experience awaits."
    },
    {
        head:"Customizable Widgets",
        text:"Express your brand's personality – Style Sense lets you create customizable widgets with colors, fonts, and more!"
    },
    {
        head:"Search Items Via Image",
        text:"Discover a visual search revolution – find items with a snap using Style Sense's innovative image search feature!"
    },
]

const solutionArray=[
    {
        img:sol1,
        text:"“My site has never looked this good, thanks to Style Sense! There’s no doubt about the impact made by custom widgets or the magic that comes with an image search. This is something worth trying. It combines style and substance in one web solution. I love it!”",
        name:"- Sarah M.",
        des:""
    },
    {
        img:sol1,
        text:"“My site has never looked this good, thanks to Style Sense! There’s no doubt about the impact made by custom widgets or the magic that comes with an image search. This is something worth trying. It combines style and substance in one web solution. I love it!”",
        name:"- Sarah M.",
        des:""
    },
]

const FaqBox=[
    {
        head:"How does Style Sense enhance product search?",
        text:"Our innovative image search feature transforms the browsing experience, allowing users to locate unique and unconventional fashion styles effortlessly."
    },
    {
        head:"Is it easy to manage my product inventory using Style Sense?",
        text:"Absolutely! For website managers, Style Sense simplifies inventory management with a user-friendly listing experience, ensuring seamless control and organization."
    },
    {
        head:"What's the process for integrating Style Sense into my website?",
        text:"It's a breeze! Follow our three-step journey – Sign up, choose a membership plan, and download and install the plugin. Voilà! Your website is ready for an elevated search experience."
    },
    {
        head:"How does Style Sense cater to diverse fashion needs and preferences?",
        text:"Style Sense addresses the challenges of diverse fashion preferences. By leveraging machine learning algorithms, we provide personalized recommendations, celebrating individual styles and body types."
    },
    {
        head:"Can Style Sense be a game-changer for businesses in the fashion industry?",
        text:"Absolutely! Style Sense is a B2B fashion platform that revolutionizes how businesses engage with fashion. Our tech-driven approach fosters customer loyalty through personalized recommendations, creating a more engaging shopping experience."
    },
]

const toolBox=[
    {
        img:tool1
    },
    {
        img:tool2
    },
    {
        img:tool3
    },
    {
        img:tool4
    },
    {
        img:tool5
    },
    {
        img:tool6
    },
]

 const lovedUser=[
    {
        img:loved1,
        digit:"150+ reviews"
    },
    {
        img:loved2,
        digit:"231+ reviews"
    },
    {
        img:loved3,
        digit:"104+ reviews"
    },
]

 const typeWeb=[
    {
        img:type1,
        text:"Prestashop",
    },
    {
        img:type2,
        text:"WooCommerce",
    },
    {
        img:type3,
        text:"Magento",
    },
    {
        img:type4,
        text:"Drupal",
    },
    {
        img:type5,
        text:"HTML/CSS",
    },
    {
        img:type6,
        text:"BigCommerce",
    },
    {
        img:type7,
        text:"Shopify",
    },
    {
        img:type8,
        text:"Other",
    },

]

const categoryList=[
    {
        name:"Category 1"
    },
    {
        name:"Category 2"
    },
    {
        name:"Category 3"
    },
    {
        name:"Category 4"
    },
    {
        name:"Category 5"
    },
    {
        name:"Category 6"
    },
    {
        name:"Category 7"
    },
]

const Productlist=[
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
    {
        image:productData,
        name:"Product Name",
        category:"Fashion",
        price:"$25.00",
    },
]

const filterItem=[
    {
        img:searchItem,
        name:"Pouch Shoulder Bag",
        category:'Fashion',
        price:'$135.00'
    },
    {
        img:searchItem2,
        name:"Pouch Shoulder Bag",
        category:'Fashion',
        price:'$135.00'
    },
    {
        img:searchItem3,
        name:"Pouch Shoulder Bag",
        category:'Fashion',
        price:'$135.00'
    },
    {
        img:searchItem4,
        name:"Pouch Shoulder Bag",
        category:'Fashion',
        price:'$135.00'
    },
]

const adminUser=[
    {
        name:"John Doe",
        date:"Member Since: 1/22/2024"
    },
    {
        name:"Adam Knight",
        date:"Member Since: 1/17/2024"
    },
    {
        name:"Emily Clark",
        date:"Member Since: 1/11/2024"
    },
    {
        name:"John Doe",
        date:"Member Since: 1/22/2024"
    },
]

export {helpBox, topNotch, solutionArray, FaqBox,toolBox,lovedUser, typeWeb, categoryList,Productlist,filterItem, adminUser};