import {Text,Stack,Button,Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalCloseButton, Input} from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from "react-router-dom";

const BuyMem=()=>{
  
  return(
      <>
          <Button
            as={Link}
            to={"/signup"}
            fontSize={{base:'14px',sm:'17px',md:'17px',xl:'17px'}}
            fontWeight={"500"}
            color={"#15171F"}
            bg={"#D4FD44"}
            p={{base:"6px 27px",sm:"8px 35px",md:"8px 35px",xl:"8px 35px"}}
            height={"auto"}
            borderRadius={"12px"}
            border={"1px solid transparent"}
            _hover={{
              textDecorationLine:"none",
              background:"none",
              color:"#D4FD44",
              border:"1px solid #D4FD44"
            }}>
            Buy Membership
          </Button>
      </>
  )
}

export default BuyMem;