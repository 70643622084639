import { Box, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

const ColorPicker = ({ widgetColors, setWidgetColors, target }) => {
    const [selectedColor, setSelectedColor] = useState('');
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setSelectedColor(newColor);
        setWidgetColors({
            ...widgetColors,
            [target]: event.target.value
        })
    };
    // Function to convert color to hex format
    const rgbToHex = (color) => {
        // Check if the color is in hex format
        if (color.startsWith('#')) {
            return color.toUpperCase();
        }

        // Check if the color is in rgb format
        const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        if (rgbMatch) {
            const hex = (x) => ('0' + parseInt(x).toString(16)).slice(-2);
            const hexColor = '#' + hex(rgbMatch[1]) + hex(rgbMatch[2]) + hex(rgbMatch[3]);
            return hexColor.toUpperCase();
        }

        // If the color format is not recognized, return the original color
        return color.toUpperCase();
    };
    return (
        <Box>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"275px"}
                bg={"#EAEAEA"}
                p={{ base: '4px 15px', sm: "6px 15px" }}
                borderRadius={"12px"}
            >
                <Text
                    fontSize={{ base: "14px", sm: "18px" }}
                    fontWeight={"500"}
                >
                    {rgbToHex(selectedColor)}
                </Text>
                <Input
                    type="color"
                    id="colorPicker"
                    value={selectedColor}
                    onChange={handleColorChange}
                    p={"0px ! important"}
                    ml={"0px ! important"}
                    w={{ base: "30px", sm: "35px", lg: "40px" }}
                    h={{ base: "30px", sm: "35px", lg: "40px" }}
                    border={"none"}
                />
            </Stack>
        </Box>
    );
};

export default ColorPicker;