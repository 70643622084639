import { Box, Text, Button, Stack, Container, Image, Toast } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import SuperHeader from '../SuperHeader';

import userTheme from '../../../assets/images/AdminPanel/userTheme.png';
import { useEffect, useState } from 'react';
import { GET, PUT } from '../../../utilities/ApiProvider';
import { useParams } from 'react-router-dom';


const UserProfile = () => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const options = {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    };

    const [userInfo, setUserInfo] = useState({
        themes: [],
        orders: []
    })


    const fetchData = async () => {
        try {
            // Your code here will execute only once after the component mounts
            let userData = await GET(`/admin/users/${params.id}`, {});
            if (userData.status == 200) {
                setUserInfo(userData.data)
                console.log(userData)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const blocKUser = async (id, bool) => {
        setIsLoading(true)
        let userData = await PUT(`/admin/users/block/${id}`, {
            isActive: bool
        });
        if (userData.status == 200) {

        }
        setIsLoading(false)
    }



    useEffect(() => {

        fetchData();

        // If you need to do some cleanup when the component unmounts, return a function
        return () => {
            // Your cleanup code here
        };
    }, [params]); // Empty dependency array signifies that this effect should run only once

    return (
        <>
            <SuperHeader />

            <Box p={"40px 0px"}>
                <Container maxW={"container.xl"}>
                    <Box
                        mb={"15px"}
                    >
                        <Text
                            fontSize={"24px"}
                            fontWeight={"600"}
                        >
                            User Details
                        </Text>
                    </Box>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        bg={"#FFF"}
                        p={"20px 15px"}
                        borderRadius={"13px"}
                        mb={"20px"}
                    >
                        <Stack direction={"row"} alignItems={"center"} gap={"7px"}>
                            <Box h={"57px"} w={"57px"} bg={"#D9D9D9"} borderRadius={"50%"}></Box>
                            <Box>
                                <Text
                                    fontSize={"14px"}
                                >
                                    Name:
                                </Text>
                                <Text
                                    fontWeight={"500"}
                                >
                                    {userInfo?.fullName}
                                </Text>
                            </Box>
                        </Stack>
                        <Box>
                            <Text
                                fontSize={"14px"}
                            >
                                Member Since:
                            </Text>
                            <Text
                                fontWeight={"500"}
                            >   
                            {new Intl.DateTimeFormat("en-US", options).format(new Date(String(userInfo?.createdAt ?? '2024-01-01').split('T')[0]))}
                            </Text>
                        </Box>
                        <Box>
                            <Text
                                fontSize={"14px"}
                            >
                                Membership Plan:
                            </Text>
                            <Text
                                fontWeight={"500"}
                            >
                               {userInfo?.currentMembership?.plan?.title}
                            </Text>
                        </Box>
                        <Box>
                            <Text
                                fontSize={"14px"}
                            >
                                Website Type:
                            </Text>
                            <Text
                                fontWeight={"500"}
                            >
                                Woocommerce
                            </Text>
                        </Box>
                        <Box>

                        </Box>
                    </Stack>
                    <Box>
                        <Tabs>
                            <TabList border={"none"} gap={"15px"} mb={"30px"}>
                                <Tab
                                    bg={"none"}
                                    w={"178px"}
                                    fontSize={"18px"}
                                    fontWeight={"500"}
                                    border={"1px solid #000"}
                                    borderRadius={"12px"}
                                    _selected={{
                                        bg: "#D4FD44",
                                        color: "#000",
                                        border: "1px solid #D4FD44"
                                    }}
                                >
                                    Themes
                                </Tab>
                                <Tab
                                    bg={"none"}
                                    w={"178px"}
                                    fontSize={"18px"}
                                    fontWeight={"500"}
                                    border={"1px solid #000"}
                                    borderRadius={"12px"}
                                    _selected={{
                                        bg: "#D4FD44",
                                        color: "#000",
                                        border: "1px solid #D4FD44"
                                    }}
                                >
                                    Purchase Logs
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p={"0px ! important"}>
                                    <Box
                                        bg={"#EEEEEE"}
                                        borderRadius={"13px"}
                                        p={"20px 25px"}
                                    >
                                        <Text mb={"15px"}>Currently Free Theme is Available</Text>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            bg={"#fff"}
                                            borderRadius={"13px"}
                                            p={"25px 30px"}
                                        >
                                            <Image src={userTheme} />
                                            {
                                                userInfo?.themes?.map((theme) =>

                                                    <Box maxW={"600px"}>
                                                        <Stack
                                                            direction={"row"}
                                                            alignItems={"center"}
                                                            justifyContent={"space-between"}
                                                            mb={"15px"}
                                                        >
                                                            <Text
                                                                fontSize={"22px"}
                                                                fontWeight={"600"}
                                                            >
                                                            {theme?.plan?.title}
                                                            </Text>
                                                            <Text
                                                                fontSize={"14px"}
                                                                fontWeight={"600"}
                                                                bg={"#D4FD44"}
                                                                p={"4px 10px"}
                                                                borderRadius={"8px"}
                                                            >
                                                                {theme?.plan?.price}
                                                            </Text>
                                                        </Stack>
                                                        <Text
                                                            fontSize={"18px"}
                                                            fontWeight={"500"}
                                                            mb={"10px"}
                                                        >
                                                            {theme?.plan?.description}
                                                        </Text>
                                                        <Text
                                                            fontSize={"15px"}
                                                            mb={"35px"}
                                                        >
                                                            {theme?.plan?.description}
                                                        </Text>
                                                        <Text
                                                            fontSize={"18px"}
                                                            fontWeight={"500"}
                                                            mb={"50px"}
                                                        >
                                                            {theme?.price}
                                                        </Text>
                                                        {/* <Button
                                                    fontSize={"18px"}
                                                    fontWeight={"500"}
                                                    bg={"#D4FD44"}
                                                    border={"1px solid #D4FD44"}
                                                    w={"180px"}
                                                    borderRadius={"12px"}
                                                    _hover={{
                                                        bg:"none",
                                                        border:"1px solid #000"
                                                    }}
                                                    >
                                                    View Theme
                                                </Button> */}
                                                    </Box>

                                                )
                                            }
                                        </Stack>
                                    </Box>

                                </TabPanel>
                                <TabPanel p={"0px ! important"}>
                                    {
                                        userInfo?.orders?.map((e) => <Stack
                                            gap={"6px"}
                                            bg={"#EEEEEE"}
                                            p={"20px 25px"}
                                            borderRadius={"13px"}
                                        >
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                bg={"#FFF"}
                                                p={"20px 25px"}
                                                borderRadius={"13px"}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    gap={"5px"}
                                                    borderRight={"1px solid #000"}
                                                    w={"270px"}
                                                >
                                                    <Stack h={"57px"} w={"57px"} borderRadius={"50%"} bg={"#D4FD44"}></Stack>
                                                    <Box>
                                                        <Text fontSize={"14px"}>Log Type:</Text>
                                                        <Text fontWeight={"500"}>{e.orderType}</Text>
                                                    </Box>
                                                </Stack>
                                                <Box>
                                                    <Text fontSize={"14px"}>Purchase Date:</Text>
                                                    <Text fontWeight={"500"}>{
                                                        new Intl.DateTimeFormat("en-US", options).format(new Date(String(e?.createdAt ?? '2024-01-01').split('T')[0]))}</Text>
                                                </Box>
                                          
                                                <Box>
                                                    <Text fontSize={"14px"}>Item Type:</Text>
                                                    <Text fontWeight={"500"}>{e.plan?.title} Membership</Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize={"14px"}>$Price:</Text>
                                                    <Text fontWeight={"500"}>{e.price}</Text>
                                                </Box>
                                            </Stack>

                                        </Stack>)
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default UserProfile;