import {
  Box,
  Container,
  FormLabel,
  Text,
  Stack,
  Select,
  Checkbox,
  Input,
  Button,
  Image,
  useToast,
} from '@chakra-ui/react';
import DashImg from '../../assets/images/Dashboard/dashImg.png';
import { Link } from 'react-router-dom';
import DashBoardHeader from '../../components/Dashboard/Header/DashboardHeader';
import ColorPicker from '../../components/ColorPicker/colorPicker';
import { useEffect, useState } from 'react';
import { GET, POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

const Widget = () => {
  const accountInfo = useSelector(state => state.value);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [widget, setWidget] = useState({});
  const [widgetColors, setWidgetColors] = useState({
    primaryColor: '#000',
    secondryColor: '#000',
    headings: '#000',
    paragraph: '#000',
  });

  // Setup Theme Options

  const submitThemeOptions = async () => {
    setIsLoading(true);
    try {
      let res = await POST(`/vendor/theme-options`, widgetColors, {
        authorization: `Bearer ${accountInfo?.jwtToken}`,
      });
      if (res.status == 200) {
        toast({
          description: 'success',
        });
      }
    } catch (error) {}

    setIsLoading(false);
  };

  // Call Data from the Widget

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Your code here will execute only once after the component mounts
     
        let res = await GET(
          `/vendor/theme-options`,
          { authorization: `Bearer ${accountInfo?.jwtToken}` }
        );
        console.log('theme', res);
        if (res.status == 200) {
            setWidgetColors(res.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // If you need to do some cleanup when the component unmounts, return a function
    return () => {
      // Your cleanup code here
    };
  }, []); // Empty dependency array signifies that this effect should run only once

  useEffect(() => {
    console.log(widgetColors);
    return () => {};
  }, [widgetColors]);

  return (
    <>
      <DashBoardHeader />
      <Box p={'25px 0px'}>
        <Container maxW={'container.xl'}>
          <Box>
            <Stack
              direction="row"
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              gap={{ base: '30px', lg: 'unset' }}
            >
              <Box>
                <Box maxW={{ base: '100%', lg: '585px' }}>
                  <Text
                    fontSize={{ base: '18px', sm: '24px' }}
                    fontWeight={'600'}
                    mb={'15px'}
                  >
                    Widget
                  </Text>
                  <Box>
                    <Text
                      fontSize={{ base: '16px', sm: '20px' }}
                      fontWeight={'600'}
                      mb={'10px'}
                      onChange={e =>
                        setWidgetColors({
                          ...widgetColors,
                          primaryColor: e.target.value,
                        })
                      }
                    >
                      Colors:
                    </Text>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={{ base: 'unset', sm: 'space-between' }}
                      flexWrap={'wrap'}
                      gap={'10px'}
                      mb={'10px'}
                    >
                      <Box ml={'0px ! important'}>
                        <FormLabel fontSize={{ base: '14px', sm: '16px' }}>
                          Primary Color
                        </FormLabel>
                        <ColorPicker
                          widgetColors={widgetColors}
                          setWidgetColors={setWidgetColors}
                          target="primaryColor"
                        />
                      </Box>
                      <Box ml={'0px ! important'}>
                        <FormLabel fontSize={{ base: '14px', sm: '16px' }}>
                          Secondary Color
                        </FormLabel>
                        <ColorPicker
                          widgetColors={widgetColors}
                          setWidgetColors={setWidgetColors}
                          target="secondaryColor"
                        />
                      </Box>
                    </Stack>
                    <Text
                      fontSize={{ base: '16px', sm: '20px' }}
                      fontWeight={'600'}
                      mb={'10px'}
                      onChange={e =>
                        setWidgetColors({
                          ...widgetColors,
                          secondryColor: e.target.value,
                        })
                      }
                    >
                      Fonts:
                    </Text>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      flexWrap={'wrap'}
                      gap={{ base: '10px', sm: '0px' }}
                      mb={'20px'}
                    >
                     
                    </Stack>
                    
                    <Box textAlign={{ base: 'center', sm: 'unset' }}>
                      <Button
                        onClick={submitThemeOptions}
                        isLoading={isLoading}
                        as={Link}
                        to={'#'}
                        h={'auto'}
                        p={{ base: '7px 27px', sm: '10px 34px' }}
                        border={'1px solid #D4FD44'}
                        bg={'#D4FD44'}
                        fontSize={{ base: '14px', sm: '18px' }}
                        fontWeight={'500'}
                        _hover={{
                          bg: 'none',
                          border: '1px solid #000',
                        }}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Text
                    fontSize={{ base: '18px', sm: '24px' }}
                    fontWeight={'600'}
                    mb={'20px'}
                  >
                    Widget Themes
                  </Text>
                  <Stack
                    direction={{ base: 'column', sm: 'row', lg: 'column' }}
                    gap={{ base: 'unset', sm: '10px', lg: 'unset' }}
                  >
                    <Box mb={'10px'}>
                      <Stack
                        alignItems={'center'}
                        bg={'#EEEEEE'}
                        p={'10px'}
                        borderRadius={'13px'}
                        mb={'5px'}
                      >
                        <Box>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                          >
                            <Text fontSize={'14px'} fontWeight={'500'}>
                              Active Theme:
                            </Text>
                            <Button
                              h={'auto'}
                              p={'4px 20px'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              bg={'#D4FD44'}
                            >
                              Free
                            </Button>
                          </Stack>
                          <Image src={DashImg} w={'100%'} />
                        </Box>
                      </Stack>
                      <Text
                        fontSize={{ base: '16px', sm: '20px' }}
                        fontWeight={'500'}
                      >
                        Theme 1
                      </Text>
                    </Box>
                    <Box>
                      <Stack
                        alignItems={'center'}
                        bg={'#EEEEEE'}
                        p={'10px'}
                        borderRadius={'13px'}
                        mb={'5px'}
                        pos={'relative'}
                      >
                        <Box>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                          >
                            <Text fontSize={'14px'} fontWeight={'500'}>
                              Active Theme:
                            </Text>
                            <Button
                              h={'auto'}
                              p={'4px 20px'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              bg={'#D4FD44'}
                            >
                              Free
                            </Button>
                          </Stack>
                          <Image src={DashImg} w={'100%'} />
                        </Box>
                        <Stack
                          pos={'absolute'}
                          m={'0px ! important'}
                          top={'0'}
                          w={'100%'}
                          h={'100%'}
                          bg={'#FFFFFF'}
                          opacity={'0.75'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Text
                            color={'#000 ! important'}
                            fontSize={{ base: '20px', sm: '24px' }}
                            fontWeight={'500'}
                          >
                            Coming Soon
                          </Text>
                        </Stack>
                      </Stack>
                      <Text
                        fontSize={{ base: '16px', sm: '20px' }}
                        fontWeight={'500'}
                      >
                        Theme 2
                      </Text>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Widget;
