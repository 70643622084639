import { Box, Text, Button, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PUT } from '../../../utilities/ApiProvider';

const UserCard = ({ _id, fullName, isActive, fetchData }) => {

    const [isLoading, setIsLoading] = useState(false);

    // Block the User
    const blocKUser = async (id,bool) => {
        setIsLoading(true)
        let userData = await PUT(`/admin/users/block/${id}`, {
            isActive: bool
        });
        if (userData.status == 200) {
            fetchData();
        }
        setIsLoading(false)
    }

    return (
        <>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                bg={"#FFF"}
                p={"20px 15px"}
                borderRadius={"13px"}
            >
                <Stack direction={"row"} alignItems={"center"} gap={"7px"}>
                    <Box h={"57px"} w={"57px"} bg={"#D9D9D9"} borderRadius={"50%"}></Box>
                    <Box>
                        <Text
                            fontSize={"14px"}
                        >
                            Name:
                        </Text>
                        <Text
                            fontWeight={"500"}
                        >
                            {fullName}
                        </Text>
                    </Box>
                </Stack>
                <Box>
                    <Text
                        fontSize={"14px"}
                    >
                        Member Since:
                    </Text>
                    <Text
                        fontWeight={"500"}
                    >
                        1/11/2024
                    </Text>
                </Box>
                <Box>
                    <Text
                        fontSize={"14px"}
                    >
                        Membership Plan:
                    </Text>
                    <Text
                        fontWeight={"500"}
                    >
                        Free Trial
                    </Text>
                </Box>
                <Box>
                    <Text
                        fontSize={"14px"}
                    >
                        Website Type:
                    </Text>
                    <Text
                        fontWeight={"500"}
                    >
                        Shopify
                    </Text>
                </Box>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"10px"}
                >

                    <Button
                        as={Link}
                        to={ `/userprofile/${_id}`}
                        borderRadius={"8px"}
                        bg={"#D4FD44"}
                        border={"1px solid #D4FD44"}
                        _hover={{ bg: "none", border: "1px solid #000" }}
                    >
                        View Details
                    </Button>
                    {isActive === true ?
                        <Button
                            isLoading={isLoading}
                            onClick={() => blocKUser(_id,false)}
                            borderRadius={"8px"}
                            bg={"#FD4444"}
                            color={"#FFF"}
                            border={"1px solid #FD4444"}
                            _hover={{ bg: "none", border: "1px solid #000", color: "#000" }}
                        >
                            Block
                        </Button>
                        :
                        <Button
                            isLoading={isLoading}
                            onClick={() => blocKUser(_id,true)}
                            borderRadius={"8px"}
                            bg={"#FD4444"}
                            color={"#FFF"}
                            border={"1px solid #FD4444"}
                            _hover={{ bg: "none", border: "1px solid #000", color: "#000" }}
                        >
                            Un Block
                        </Button>
                    }

                </Stack>
            </Stack>
        </>
    )
}

export default UserCard;