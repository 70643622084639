import { Box, FormLabel, Image, Input, Stack, Text,Button,Spinner, useToast} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom"
//Images
import authentication from '../../assets/images/website/Auth/authentication.png';
import auth1 from '../../assets/images/website/Auth/auth1.png';
import backArrow from '../../assets/images/website/Auth/backArrow.png';
import { useSelector , useDispatch } from "react-redux";
import { useState } from "react";
import { POST } from "../../utilities/ApiProvider";
import { loadUser } from "../../reducers/useReducers";


const Authentication=()=>{

    // Setup State Mangement Here
    const [isLoading,setIsLoading] = useState(false);
    const [syncKeys, setSyncKeys] = useState({
        marketplace : '65c7933a1eba411a98aeadab'
    });
    const dispatch = useDispatch();



    const toast = useToast()
    const navigate = useNavigate();

    // user information

    const accountInfo = useSelector(state => state.value);


    const subscribe  = async() =>
    {
        setIsLoading(true);

        try
        {
            let res = await POST(`/vendor/subscribe`,syncKeys,{ authorization: `Bearer ${accountInfo?.jwtToken}` });
            if(res.status == 200)
            {
                toast({
                    description : "Login Successfull!"
                })
                dispatch(loadUser(res.data))
                navigate('/dashboard')
            }
            else
            {
                toast({
                    description: res.message
                });
            }
            
        }
        catch(error)
        {
            toast({
                description: error.message
              });
        }

        setIsLoading(false);

    }


    return(
        <>
        <Box
            w={"100vw"}
            h={"100vh"}
            display={"flex"}
            alignItems={"center"}
            >
            <Box 
                display={{base:'none',sm:"none",md:"none",lg:"block",xl:"block"}}
                w={"44%"} 
                h={"100vh"} 
                bgImage={authentication} 
                bgPos={"center"} 
                bgSize={"cover"} 
                bgRepeat={"no-repeat"}
                >
            </Box>
            <Stack
                w={{base:"100%",sm:"100%",md:"100%",lg:"56%",xl:"56%"}}  
                h={"100vh"}
                >
                <Stack 
                    pos={"relative"} 
                    alignItems={"center"} 
                    justifyContent={"center"}
                    w={{base:"350px",sm:"580px",md:"580px",lg:"500px",xl:"580px"}}
                    h={{base:"100vh",sm:"90vh"}}
                    m={"auto"} 
                    >
                    <Box w={"inherit"} position={'relative'}>
                        <Box 
                            pos={"absolute"} 
                            top={{base:"-40px"}} 
                            left={{base:"0",lg:"20px",xl:"0"}}
                            >
                            <Stack direction={"row"} alignItems={"center"}>
                                <Link to={"/signup"}>
                                    <Image src={backArrow}/>
                                </Link>
                                <Text fontWeight={"500"}>Back</Text>
                            </Stack>
                        </Box>
                        <Box> 
                        
                            <Box
                                bg={"rgba(212, 253, 68, 0.45)"}
                                borderRadius={"13px"}
                                p={{base:"8px 4px",sm:"14px 0px"}}
                                mb={"20px"}
                            >
                                <Text
                                    textAlign={"center"}
                                    fontSize={{base:"13px",sm:"15px"}}
                                    fontWeight={"500"}
                                    >
                                    If you want to change your website type, please click here: 
                                    <Text 
                                        as={Link} 
                                        to={"#"}
                                        fontWeight={"600"}
                                        textDecorationLine={"underline ! important"}
                                        >
                                        Change
                                    </Text>
                                </Text>
                            </Box>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                >
                                <Text
                                    fontSize={{base:"20px",sm:"28px",md:"28px",lg:"25px",xl:"28px"}}
                                    fontWeight={"700"}
                                    mb={"10px"}
                                    >
                                    Authentication
                                </Text>
                                <Stack
                                    as={Link}
                                    to={"#"}
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={{base:"2px",sm:"5px"}}
                                    >
                                    <Image 
                                        src={auth1} 
                                        h={{base:"15px",sm:"20px"}} 
                                        w={{base:"15px",sm:"20px"}}
                                    />
                                    <Text
                                        fontSize={{base:"13px",sm:"16px",md:"16px",lg:"15px",xl:"16px"}}
                                        fontWeight={"500"}
                                        textDecorationLine={"underline"}
                                        >
                                        How to get the keys?
                                    </Text>
                                </Stack>
                            </Stack>

                            <Box
                                mb={{base:'10px',sm:"20px"}}
                                >
                                <FormLabel 
                                    htmlFor="key" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Client Key
                                </FormLabel>
                                <Box>
                                    <Input
                                        onChange={e => setSyncKeys({
                                            ...syncKeys,
                                            client_id: e.target.value
                                          })}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none",border:"1px solid #000"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"key" }
                                        name={"key"} 
                                        autoComplete={"key"}
                                    />
                                </Box>
                            </Box>
                            <Box 
                                mb={{base:'10px',sm:"20px"}}
                                >
                                <FormLabel 
                                    htmlFor="secret" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Client Secret
                                </FormLabel>
                                <Box>
                                    <Input
                                         onChange={e => setSyncKeys({
                                            ...syncKeys,
                                            client_secret: e.target.value
                                          })}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"secret" }
                                        name={"secret"} 
                                        autoComplete={"secret"}
                                    />
                                </Box>
                            </Box>
                            <Box 
                                mb={{base:'20px',sm:"20px"}}
                                >
                                <FormLabel 
                                    htmlFor="callbackUrl" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    URL
                                </FormLabel>
                                <Box>
                                    <Input
                                        onChange={e => setSyncKeys({
                                            ...syncKeys,
                                            url: e.target.value
                                        })}
                                        type={"url"}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"callbackUrl" }
                                        name={"callbackUrl"} 
                                        autoComplete={"callbackUrl"}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Button 
                                    onClick={subscribe}
                                    isLoading={isLoading}                                   
                                     as={Link} 
                                    to={"#"}
                                    w={"100%"}
                                    h={"auto"}
                                    p={"12px 0px"}
                                    bg={"#D4FD44"}
                                    border={"1px solid #D4FD44"}
                                    borderRadius={"12px"}
                                    _hover={{bg:"none",border:"1px solid #000"}}
                                    >
                                    Verify
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        </>
    )
}

export default Authentication;