import { Box, Button, Container, Image, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import DashBoardHeader from "../../components/Dashboard/Header/DashboardHeader";
import product from '../../assets/images/Dashboard/product.png';

const ProductListing =()=>{
    return(
        <>
        <Box h={"100vh"}>
            <DashBoardHeader/>

            <Box p={"20px 0px"}>
                <Container maxW={"container.xl"}>
                    <Box>
                        <Text
                            fontSize={{base:'20px',sm:'24px'}}
                            fontWeight={"600"}
                            >
                            Product Listing
                        </Text>
                    </Box>
                    <Stack
                        gap={"10px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        pt={{base:"80px",sm:'150px'}}
                        >
                        <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            w={{base:'80px',sm:"130px"}}
                            h={{base:'80px',sm:"130px"}}
                            borderRadius={"50%"}
                            bg={"#D4FD44"}
                            >
                            <Image 
                            src={product} 
                            w={{base:"35px",sm:"57px"}}
                            h={{base:"35px",sm:"57px"}}
                            />
                        </Stack>
                        <Text
                            textAlign={"center"}
                            fontSize={{base:'22px',sm:'28px'}}
                            fontWeight={"500"}
                            >
                            Looks like you don’t have any product listing yet
                        </Text>
                        <Box>
                            <Button
                                as={Link}
                                to={"/productlistingdata"}
                                h={"auto"}
                                p={{base:"9px 30px",sm:'12px 40px'}}
                                fontSize={{base:'15px',sm:'18px'}}
                                fontWeight={"500"}
                                bg={"#D4FD44"}
                                border={"1px solid #D4FD44"}
                                _hover={{
                                    bg:"none",
                                    border:"1px solid #000"
                                }}
                                >
                                Sync Product Listing
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </Box>
        </>
    )
}

export default ProductListing;