import { Box, Button, Container, Image, Stack, Text, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Select, Input } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SuperHeader from "../../components/SuperAdminPanel/SuperHeader";
import UserCard from "../../components/SuperAdminPanel/Users/UserCard";
import { GET, PUT } from "../../utilities/ApiProvider";
import { useDispatch } from "react-redux";

const Users = () => {
    const [users, setUser] = useState({
        activeUsers: [],
        blockedUsers: []
    })
    // const [isOpen, setIsOpen] = useState(false);
    // const onOpen = () => setIsOpen(true);
    // const onClose = () => setIsOpen(false);

    // Fetch All Users Data

    const fetchData = async () => {

        try {
            // Your code here will execute only once after the component mounts
            let result = await GET('/admin/users');
            if (result.status == 200) {
                setUser({
                    activeUsers: result.data.activeUsers,
                    blockedUsers: result.data.blockedUsers

                })
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {


        fetchData();

        // If you need to do some cleanup when the component unmounts, return a function
        return () => {
            // Your cleanup code here
        };
    }, []); // Empty dependency array signifies that this effect should run only once


    return (
        <>
            <SuperHeader />

            <Box p={"40px 0px"}>
                <Container maxW={"container.xl"}>
                    <Box>
                        <Box
                            mb={"30px"}
                        >
                            <Text
                                fontSize={"24px"}
                                fontWeight={"600"}
                            >
                                Total Users
                            </Text>
                            <Text
                                fontSize={"15px"}
                            >
                                117 total user on the platform
                            </Text>
                        </Box>
                        <Box>
                            <Tabs>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={"25px"}
                                >
                                    <TabList border={"none"} gap={"15px"}>
                                        <Tab
                                            bg={"none"}
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            border={"1px solid #000"}
                                            borderRadius={"12px"}
                                            _selected={{
                                                bg: "#D4FD44",
                                                color: "#000",
                                                border: "1px solid #D4FD44"
                                            }}
                                        >
                                            Active Users
                                        </Tab>
                                        <Tab
                                            bg={"none"}
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            border={"1px solid #000"}
                                            borderRadius={"12px"}
                                            _selected={{
                                                bg: "#D4FD44",
                                                color: "#000",
                                                border: "1px solid #D4FD44"
                                            }}
                                        >
                                            Blocked Users
                                        </Tab>

                                    </TabList>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={"10px"}
                                    >
                                       
                                        {/* <Select
                                            w={"210px ! important"}
                                            border={"1px solid #000 ! important"}
                                            borderRadius={"12px"}  
                                            _focusVisible={{boxShadow:"none"}}
                                            >
                                            <option>Show 20</option>
                                            <option>Show 40</option>
                                            <option>Show All</option>
                                        </Select> */}
                                    </Stack>
                                </Stack>
                                <TabPanels>
                                    <TabPanel p={"0px ! important"}>
                                        <Box
                                            mb={"30px"}
                                        >
                                            <Text>{users?.activeUsers.length}  total active user on the platform</Text>
                                        </Box>
                                        <Stack
                                            gap={"6px"}
                                            bg={"#EEEEEE"}
                                            p={"20px 25px"}
                                            borderRadius={"13px"}
                                        >
                                            {
                                                users?.activeUsers?.map((e) =>
                                                    <UserCard fetchData={fetchData} {...e} bool={true} />

                                                )
                                            }

                                        </Stack>
                                    </TabPanel>
                                    <TabPanel p={"0px ! important"}>
                                        <Box
                                            mb={"30px"}
                                        >
                                            <Text>{users?.blockedUsers.length} total blocked user on the platform</Text>
                                        </Box>
                                        <Stack
                                            gap={"6px"}
                                            bg={"#EEEEEE"}
                                            p={"20px 25px"}
                                            borderRadius={"13px"}
                                        >
                                            {
                                                users?.blockedUsers?.map((e) =>
                                                    <UserCard fetchData={fetchData} {...e} bool={true} />
                                                )
                                            }
                                        </Stack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Users;





