import { Container, Box, Stack, Image, Text, Button, Input, Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalCloseButton } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
// Images
import ssLogo from '../../../assets/images/website/home/sslogo.png';
import { AiOutlineLinkedin, AiOutlineInstagram } from "react-icons/ai";
import { FiFacebook } from "react-icons/fi";

export default function Index() {

  return (
    <>
    <Box
      bg={"#15171F"}
      borderRadius={{base:"0px 0px 60px 0px",sm:"0px 0px 92px 0px",md:"0px 0px 92px 0px",xl:"0px 0px 92px 0px"}}
      >
      <Container maxW={"container.xl"}>
        <Box 
          borderBottom={"1.5px solid #3A3A3A"} 
          p={{base:"30px 0px",sm:"50px 0px",md:"50px 0px",xl:"50px 0px"}}
          >
          <Stack 
            direction={"row"} 
            justifyContent={"space-between"} 
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{base:"30px",sm:"unset",md:"unset",xl:"unset"}}
            >
            <Box>
              <Box mb={{base:"30px",sm:"50px",md:"50px",xl:"50px"}}>
                <Link to={"/"}>
                  <Image src={ssLogo} w={{base:"110px",sm:"190px",md:"160px",lg:"190px",xl:"190px"}}/>
                </Link>
              </Box>
              <Box>
                <form action="#">
                  <Box position="relative" w={{base:"340px",sm:"369px",md:"369px",xl:"369px"}}>
                    <Input
                      type="email"
                      isRequired
                      name="entry.1808449400"
                      p={{base:"7px 20px",sm:"10px 25px",md:"10px 25px",xl:"10px 25px"}}
                      height={"auto"}
                      rounded="12px"
                      border={"1px solid #FFF"}
                      _placeholder={{color: 'gray.300'}}
                      placeholder="Enter your email"
                      _focus={{ outline: 0}}
                      _focusVisible={{boxShadow:"none"}}
                      color="gray.100"
                    />
                    <Button
                      type="submit"
                      color="#15171F"
                      position="absolute"
                      top="0px"
                      right="0"
                      bg="#D4FD44"
                      border={"2px solid #D4FD44"}
                      rounded="12px"
                      h={"auto"}
                      p={{base:"8px 20px",sm:"11px 25px",md:"11px 25px",xl:"11px 25px"}}
                      _hover={{
                        color:"#D4FD44",
                        border:"2px solid #D4FD44",
                        bg:"none"
                      }}
                    >
                      Subscribe
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            <Box ml={"0px ! important"}>
              <Stack
                direction={'row'}
                spacing={"10px"}
                mb={"50px"}
                >
                <Button
                  as={Link}
                  to={"/login"}
                  fontSize={{base:'14px',sm:'17px',md:'17px',xl:'17px'}}
                  fontWeight={"500"}
                  color={"#D9D9D9"}
                  bg={"rgba(255, 255, 255, 0.05)"}
                  p={"8px 35px"}
                  height={"auto"}
                  borderRadius={"12px"}
                  border={"1px solid #fff"}
                  _hover={{
                    textDecorationLine:"none",
                    background:"none",
                    color:"#D4FD44",
                    border:"1px solid #D4FD44"
                  }}>
                  Log In
                </Button>
                <Button
                  as={Link}
                  to={"/signup"}
                  fontSize={{base:'14px',sm:'17px',md:'17px',xl:'17px'}}
                  fontWeight={"500"}
                  color={"#D9D9D9"}
                  bg={"rgba(255, 255, 255, 0.05)"}
                  p={"8px 35px"}
                  height={"auto"}
                  borderRadius={"12px"}
                  border={"1px solid #fff"}
                  _hover={{
                    textDecorationLine:"none",
                    background:"none",
                    color:"#D4FD44",
                    border:"1px solid #D4FD44"
                  }}>
                  Buy Membership
                </Button>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={{base:"flex-start",sm:"flex-end",md:"flex-end",xl:"flex-end"}}
                gap={"20px"}
              >
                <a href={"https://www.facebook.com/stylesnse"} target='_blank'  rel="noreferrer">
                  <FiFacebook color="#FFF" fontSize="35px" _hover={{color:"#D4FD44"}}/>
                </a>
                <a href={"https://www.linkedin.com/company/style-senseai/"} target='_blank'  rel="noreferrer">
                  <AiOutlineLinkedin color="#FFF" fontSize="35px"/>
                </a>
                <a target='_blank'  rel="noreferrer" style={{cursor:"pointer"}}>
                  <AiOutlineInstagram color="#FFF" fontSize="35px"/>
                </a>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box p={{base:"20px 0px",sm:"30px 0px",md:"30px 0px",xl:"30px 0px"}}>
          <Stack 
            direction={"row"} 
            alignItems={"center"} 
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={{base:"10px",sm:"unset",md:"unset",xl:"unset"}}
            >
            <Text 
              color={"#fff"} 
              fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}}
              >
              © StyleSnse 2024. All rights reserved
            </Text>
            <Stack 
              direction={"row"} 
              alignItems={"center"} 
              gap={"20px"} 
              mr={{base:"unset",sm:"unset",md:"20px ! important",lg:"unset"}}
              >
              <Text as={Link} to={"#"} color={"#fff"} fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}}>Privacy Policy</Text>
              <Text as={Link} to={"#"} color={"#fff"} fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}}>Terms of Service</Text>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
    {/* <Modal 
      isOpen={isOpen2} 
      onClose={onClose2}
      isCentered
      >
      <ModalOverlay 
        bg={"#000"} 
        opacity={"0.78 ! important"}
      />
      <ModalContent 
        w={{base:"300px",sm:"570px"}}
        p={"10px 30px"} 
        borderRadius={"24px"}
        >
        <ModalHeader 
          p={"10px 0px ! important"}
          >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            >
            <Text 
              fontSize={{base:"23px",sm:"30px"}}
              fontWeight={"600"}
              >
              Coming Soon
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton 
          top={"-35px"} 
          right={"10px"} 
          color={"#FFF"}
        />
        <ModalBody
          p={"10px 0px ! important"}
          textAlign={"center"}
          >
          <Text 
            fontWeight={"500"}
            mb={"10px"}
            fontSize={{base:"13px",sm:"16px"}}
            >
            Get notified when product goes live
          </Text>
          <Stack
            alignItems={"center"}
            mb={"10px"}
            >
            <Input
              type='email'
              border={"1px solid #000 ! important"}
              borderRadius={"12px"}
              h={"auto"}
              p={{base:"4px 20px",sm:"6px 20px"}}
              fontSize={{base:"13px",sm:"16px"}}
              placeholder='Enter Your Email'
              _placeholder={{
                fontSize:{base:"13px",sm:"15px"},
                fontWeight:"500",
                color:"#000"
              }}
              _focusVisible={{
                boxShadow:"none"
              }}
            />
            <Button 
              as={Link}
              to={"#"}
              zIndex={"1"}
              fontSize={{base:"13px",sm:"15px"}}
              fontWeight={"500"}
              w={"50%"}
              h={"auto"}
              p={{base:"7px 30px",sm:"11px 30px"}}
              ml={"0px ! important"}
              border={"1px solid #D4FD44"}
              bg={"#D4FD44"}
              borderRadius={"12px"}
              _hover={{
                bg:"#fff",
                color:"#000",
                border:"1px solid #000"
              }}
              >
              Subscribe
            </Button>
          </Stack>
          <Text 
            fontSize={{base:"13px",sm:"15px"}}
            mb={"10px"}
            >
            If you have any questions, please contact us!
          </Text>
            <a
              href={"mailto:info@stylesnse.com"}
              rel="noreferrer"
              style={{
              fontSize:{base:"13px",sm:"16px"},
              fontWeight:"500",
              textDecorationLine:"underline ! important"
              }}
              >
              info@stylesnse.com
            </a>
        </ModalBody>
      </ModalContent>
    </Modal> */}
    </>
  )
}