import React, { createContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound'
import Home from '../views/Website/Home';
import Login from '../views/Auth/Login';
import ForgotPassword from '../views/Auth/ForgotPassword';
import Verification from '../views/Auth/Verification';
import SetNewPassword from '../views/Auth/SetNewPassword';
import SignUp from '../views/Auth/SignUp';
import Authentication from '../views/Auth/Authetication';
import DashBoard from '../views/Dashboard/DashBoard';
import Widget from '../views/Dashboard/Widget';
import ProductListing from '../views/Dashboard/ProductListing';
import ProductListingData from '../views/Dashboard/ProductListingData';
import Account from '../views/Dashboard/Account';
import SearchFilter from '../views/Dashboard/SearchFilter';
import MainSuperAdmin from '../views/SuperAdmin/MainSuperAdmin';
import Users from '../views/SuperAdmin/User';
import UserProfile from '../components/SuperAdminPanel/Users/UserProfile';
import Membership from '../views/SuperAdmin/Membership';

export const UserContext = createContext()

export default function AppRoute() {

    return (
        <div>
            <Router>
                {/* <Header /> */}
                <RouteSwitch>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route exact path="/login" element={<Login />}></Route>
                    <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
                    <Route exact path="/verification" element={<Verification />}></Route>
                    <Route exact path="/setnewpassword" element={<SetNewPassword />}></Route>
                    <Route exact path="/signup" element={<SignUp />}></Route>
                    <Route exact path="/authentication" element={<Authentication />}></Route>
                    <Route exact path="*" element={<NotFound />}></Route>
                    {/* DashBoard */}
                    <Route exact path="/dashboard" element={<DashBoard />}></Route>
                    <Route exact path="/widget" element={<Widget />}></Route>
                    <Route exact path="/productlisting" element={<ProductListing />}></Route>
                    <Route exact path="/productlistingData" element={<ProductListingData />}></Route>
                    <Route exact path="/account" element={<Account />}></Route>
                    <Route exact path="/searchfilter" element={<SearchFilter />}></Route>
                    {/* Super Admin */}
                    <Route exact path="/mainsuperadmin" element={<MainSuperAdmin />}></Route>
                    <Route exact path="/users" element={<Users />}></Route>
                    <Route exact path="/membership" element={<Membership />}></Route>
                    <Route exact path="/userprofile/:id" element={<UserProfile />}></Route>
                </RouteSwitch>
                {/* <Footer /> */}
            </Router>
        </div>
    )
}