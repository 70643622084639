import { Box, FormLabel, Image, Input, Stack, Text,Button,Icon,useToast, Checkbox} from "@chakra-ui/react";
import { Link , useNavigate } from "react-router-dom";
import { useEffect, useState} from "react";
import { BsEyeSlash, BsEye, BsCheck } from 'react-icons/bs';
import { Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalCloseButton } from '@chakra-ui/react'
import { typeWeb } from "../../assets/data/Data";
//Images
import signup from '../../assets/images/website/Auth/signup.png';
import backArrow from '../../assets/images/website/Auth/backArrow.png';

import { POST , GET } from "../../utilities/ApiProvider";


import { useDispatch, useSelector  } from 'react-redux';
import { loadUser } from "../../reducers/useReducers";
import { loadMarketPlace } from "../../reducers/marketPlaceReducer";

const SignUp=()=>{


     // setup up signup related states
     const toast = useToast();
     const dispatch = useDispatch();
     const navigate = useNavigate();
     const [isLoading, setIsLoading] = useState(false);
     const [signupInfo, setSignupInfo] = useState({
         plan: "",
         fullName : '',
         email: '',
         websiteUrl : '',
         password: '',
         confirmPassword : ""
       });
       
    // ending


    // setup Registration Api
    
    const registerUser  = async() =>
    {
        setIsLoading(true);

        try
        {
           
            let res = await POST(`/auth/register`,signupInfo);
            if(res.status == 200)
            {
                toast({
                    description : "Registered Successfully"
                })
                dispatch(loadUser(res.data))
                navigate('/authentication')
            }
            else
            {
                toast({
                    description: res.message
                });
            }
            
        }
        catch(error)
        {
            toast({
                description: error.message
              });
        }
    }

    // Ending Registration Setup


    const [showpass, setshowpass] = useState(false);
    const [marketplace,setMarketPlace] = useState([]);
    // Modal 2 
    const [isOpen2, setIsOpen2] = useState(false);
    const onOpen2 = () => setIsOpen2(true);
    const onClose2 = () => setIsOpen2(false);
    // Package selection
    const [selectedOption, setSelectedOption] = useState(null);
    const onBoxClick = (option) => {
        setSelectedOption(option);
    };
    // signup button popup
    const [showModal, setShowModal] = useState(false);
    const [selectedTypeIndex, setSelectedTypeIndex] = useState(null);
    const handleSubmit = () => {
      setShowModal(true);
    };

    

    const handleWebsiteType = (index) => {
        setSignupInfo({
            ...signupInfo,
            plan: index
          }) 
        setSelectedTypeIndex(index);
    };
    const handleCloseModal = () => {
      setShowModal(false);
      setSelectedTypeIndex(null);
    };
    const handleContinue = () => {
    };


    useEffect(() => {
        const fetchData = async () => {
          try {
            // Your code here will execute only once after the component mounts
            let marketPlaceData = await GET('/admin/marketplace', {});
            if(marketPlaceData.status == 200)
            {
                dispatch(loadMarketPlace(marketPlaceData.data))
                setMarketPlace(marketPlaceData.data)
            }
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    
        // If you need to do some cleanup when the component unmounts, return a function
        return () => {
          // Your cleanup code here
        };
      }, []); // Empty dependency array signifies that this effect should run only once
    


    return(
        <>
        <Box
            h={"auto"}
            display={"flex"}
            alignItems={"center"}
            >
            <Box 
                display={{base:'none',sm:"none",md:"none",lg:"block",xl:"block"}}
                w={'44%'}
                >
                <Image src={signup}/>
            </Box>
            <Stack
                w={{base:"100%",sm:"100%",md:"100%",lg:"56%",xl:"56%"}}
                h={"auto"}
                pt={{base:"20px",sm:"0px"}}
                >
                <Stack 
                    pos={"relative"} 
                    alignItems={"center"} 
                    justifyContent={"center"}
                    w={{base:"330px",sm:"580px",md:"580px",lg:"500px",xl:"600px"}} 
                    h={{base:"auto",sm:"90vh",md:"90vh",lg:"100vh",xl:"inherit"}}
                    m={"auto"} 
                    >
                    <Box w={"inherit"} position={'relative'}>
                        <Box pos={"absolute"} top={"-40px"}  left={"0"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Link to={"/"}>
                                    <Image src={backArrow}/>
                                </Link>
                                <Text fontWeight={"500"}>Back</Text>
                            </Stack>
                        </Box>
                        <Box pt={{base:"50px",sm:"0px",md:"50px",lg:"0px"}}> 
                           
                            <Text
                                fontSize={{base:"20px",sm:"28px",md:"28px",lg:"23px",xl:"28px"}}
                                fontWeight={"700"}
                                mb={{sm:"10px",lg:"5px",xl:"10px"}}
                                >
                                Sign Up
                            </Text>
                            <Text
                                fontSize={{base:"13px",sm:"16px",md:"16px",lg:"14px",xl:"16px"}}
                                color={"#464646"}
                                mb={{sm:"10px",xl:"20px"}}
                                >
                                Enter your details to sign up your account
                            </Text>
                            <Box 
                                borderBottom={"2px solid #EAEAEA"}
                                >
                                <Text 
                                    mb={{sm:"5px",xl:"10px"}}
                                    fontSize={{base:"14px",sm:"17px",md:"17px",lg:"15px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Choose Membership Plan
                                </Text>
                                <Stack 
                                    direction={"row"} 
                                    alignItems={"center"} 
                                    justifyContent={"space-between"}
                                    flexWrap={"wrap"}
                                    gap={{base:"10px",sm:"0px",md:"10px",lg:"0px",xl:"0px"}}
                                    mb={{base:"10px",lg:"5px",xl:"20px"}}
                                    >
                                    <Box
                                        w={{base:"100%",sm:"280px",md:"100%",lg:"235px",xl:"280px"}}
                                        p={"6px 20px"}
                                        bg={selectedOption === 'monthly' ? "#D4FD44" : "#EAEAEA"}
                                        borderRadius={"12px"}
                                        _hover={{ bg: "#D4FD44", transition: "all 0.4s" }}
                                        onClick={() => onBoxClick('monthly')}
                                        >
                                        <Text 
                                            fontSize={{base:"13px",sm:"16px"}}
                                            fontWeight={"500"}
                                            >
                                            Monthly
                                        </Text>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            >
                                            <Text
                                                fontSize={{base:"18px",sm:"22px",md:"22px",lg:'20px',xl:"22px"}}
                                                fontWeight={"500"}
                                            >
                                                $20.00
                                            </Text>
                                            <Text
                                                onClick={onOpen2}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                textDecorationLine={"underline"}
                                                cursor={"pointer"}
                                                >
                                                What’s Include
                                            </Text>
                                            <Modal 
                                                isOpen={isOpen2} 
                                                onClose={onClose2}
                                                isCentered
                                                >
                                                <ModalOverlay 
                                                    bg={"#000"} 
                                                    opacity={"0.78 ! important"}
                                                />
                                                <ModalContent 
                                                    w={{base:"345px",sm:"570px"}}
                                                    p={{base:"10px 15px",sm:"10px 30px"}} 
                                                    borderRadius={"24px"}
                                                    >
                                                    <ModalHeader 
                                                        borderBottom={"1px solid #8A8A8A"}
                                                        p={"10px 0px ! important"}
                                                        >
                                                        <Stack
                                                            direction={"row"}
                                                            alignItems={"center"}
                                                            justifyContent={"space-between ! important"}
                                                        >
                                                            <Text 
                                                                fontSize={{base:"18px",sm:"22px"}}
                                                                fontWeight={"600"}
                                                                >
                                                                What’s include
                                                            </Text>
                                                            <Button
                                                                as={Link}
                                                                to={"#"}
                                                                bg={"#D4FD44"}
                                                                h={"auto"}
                                                                p={{base:"7px 15px",sm:"10px 15px"}}
                                                                fontSize={{base:"13px",sm:'16px'}}
                                                                borderRadius={"12px"}
                                                                >
                                                                Continue Free Trail
                                                            </Button>
                                                        </Stack>
                                                    </ModalHeader>
                                                    <ModalCloseButton 
                                                        top={"-35px"} 
                                                        right={"10px"} 
                                                        color={"#FFF"}
                                                    />
                                                    <ModalBody
                                                        p={"10px 0px ! important"}
                                                        >
                                                        <Stack 
                                                            direction={"row"}
                                                            alignItems={"center"}
                                                            justifyContent={"space-between"}
                                                            mb={"20px"}
                                                            >
                                                            <Text
                                                                fontSize={{base:'18px',sm:"22px"}}
                                                                fontWeight={"600"}
                                                                >
                                                                $20.00
                                                            </Text>
                                                            <Button 
                                                                fontSize={"13px"}
                                                                as={Link}
                                                                to={"#"}
                                                                bg={"#D4FD44"}
                                                                h={"auto"}
                                                                p={{base:"5px 15px",sm:"5px 20px"}}
                                                                borderRadius={"6px"}
                                                                >
                                                                7 Days Free Trial
                                                            </Button>
                                                        </Stack>
                                                        <Text 
                                                            mb={"10px"}
                                                            fontSize={{base:"15px",sm:"17px"}}
                                                            fontWeight={"500"}
                                                        >
                                                            Features:
                                                        </Text>
                                                        <Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Lorem ipsum dolor sit amet
                                                            </Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:'12px',sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Consectetur adipiscing elit
                                                            </Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Ut enim ad minim
                                                            </Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Duis aute irure
                                                            </Box>
                                                            <Box
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Excepteur sint occaecat cupidatat
                                                            </Box>
                                                        </Box>
                                                    </ModalBody>
                                                </ModalContent>
                                            </Modal>
                                        </Stack>
                                    </Box>
                                    <Box
                                        w={{base:"100%",sm:"280px",md:"100%",lg:"235px",xl:"280px"}}
                                        p={"6px 20px"}
                                        bg={selectedOption === 'yearly' ? "#D4FD44" : "#EAEAEA"}
                                        borderRadius={"12px"}
                                        ml={"0px ! important"}
                                        _hover={{ bg: "#D4FD44", transition: "all 0.4s" }}
                                        onClick={() => onBoxClick('yearly')}
                                        >
                                        <Text 
                                            fontSize={{base:"13px",sm:"16px"}}
                                            fontWeight={"500"}
                                            >
                                            Yearly
                                        </Text>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            >
                                            <Text
                                                fontSize={{base:"18px",sm:"22px",md:"22px",lg:'20px',xl:"22px"}}
                                                fontWeight={"500"}
                                            >
                                                $180.00
                                            </Text>
                                            <Text
                                                onClick={onOpen2}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                textDecorationLine={"underline"}
                                                cursor={"pointer"}
                                                >
                                                What’s Include
                                            </Text>
                                            <Modal 
                                                isOpen={isOpen2} 
                                                onClose={onClose2}
                                                isCentered
                                                >
                                                <ModalOverlay 
                                                    bg={"#000"} 
                                                    opacity={"0.78 ! important"}
                                                />
                                                <ModalContent 
                                                    w={{base:"345px",sm:"570px"}}
                                                    p={{base:"10px 15px",sm:"10px 30px"}} 
                                                    borderRadius={"24px"}
                                                    >
                                                    <ModalHeader 
                                                        borderBottom={"1px solid #8A8A8A"}
                                                        p={"10px 0px ! important"}
                                                        >
                                                        <Stack
                                                            direction={"row"}
                                                            alignItems={"center"}
                                                            justifyContent={"space-between ! important"}
                                                        >
                                                            <Text 
                                                                fontSize={{base:"18px",sm:"22px"}}
                                                                fontWeight={"600"}
                                                                >
                                                                What’s include
                                                            </Text>
                                                            <Button
                                                                as={Link}
                                                                to={"#"}
                                                                bg={"#D4FD44"}
                                                                h={"auto"}
                                                                p={{base:"7px 15px",sm:"10px 15px"}}
                                                                fontSize={{base:"13px",sm:'16px'}}
                                                                borderRadius={"12px"}
                                                                >
                                                                Continue Free Trail
                                                            </Button>
                                                        </Stack>
                                                    </ModalHeader>
                                                    <ModalCloseButton 
                                                        top={"-35px"} 
                                                        right={"10px"} 
                                                        color={"#FFF"}
                                                    />
                                                    <ModalBody
                                                        p={"10px 0px ! important"}
                                                        >
                                                        <Stack 
                                                            direction={"row"}
                                                            alignItems={"center"}
                                                            justifyContent={"space-between"}
                                                            mb={"20px"}
                                                            >
                                                            <Text
                                                                fontSize={{base:'18px',sm:"22px"}}
                                                                fontWeight={"600"}
                                                                >
                                                                $20.00
                                                            </Text>
                                                            <Button 
                                                                fontSize={"13px"}
                                                                as={Link}
                                                                to={"#"}
                                                                bg={"#D4FD44"}
                                                                h={"auto"}
                                                                p={{base:"5px 15px",sm:"5px 20px"}}
                                                                borderRadius={"6px"}
                                                                >
                                                                7 Days Free Trial
                                                            </Button>
                                                        </Stack>
                                                        <Text 
                                                            mb={"10px"}
                                                            fontSize={{base:"15px",sm:"17px"}}
                                                            fontWeight={"500"}
                                                        >
                                                            Features:
                                                        </Text>
                                                        <Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Lorem ipsum dolor sit amet
                                                            </Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:'12px',sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Consectetur adipiscing elit
                                                            </Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Ut enim ad minim
                                                            </Box>
                                                            <Box 
                                                                mb={"8px"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Duis aute irure
                                                            </Box>
                                                            <Box
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                gap={"5px"}
                                                                fontSize={{base:"12px",sm:"14px"}}
                                                                >
                                                                <Box 
                                                                    bg="#D4FD44" 
                                                                    padding="4px" 
                                                                    borderRadius={"5px"}
                                                                    >
                                                                    <BsCheck color="#15171F"/>
                                                                </Box>
                                                                Excepteur sint occaecat cupidatat
                                                            </Box>
                                                        </Box>
                                                    </ModalBody>
                                                </ModalContent>
                                            </Modal>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    bg={selectedOption === 'free' ? "#D4FD44" : "#EAEAEA"}
                                    alignItems={"end"}
                                    p={"6px 20px"}
                                    borderRadius={"12px"}
                                    mb={{base:"10px",lg:"5px",xl:"20px"}}
                                    _hover={{bg:"#D4FD44",transition:"all 0.4s"}}
                                    onClick={() => onBoxClick('free')}
                                    >
                                    <Box>
                                        <Text 
                                            fontSize={{base:"13px",sm:"16px"}}
                                            fontWeight={"500"}
                                            >
                                            Free Trial
                                        </Text>
                                        <Text 
                                            fontSize={{base:"18px",sm:"22px",md:"22px",lg:'20px',xl:"22px"}}
                                            fontWeight={"500"}
                                            >
                                            7 Days
                                        </Text>
                                    </Box>
                                    <Text
                                        
                                        fontSize={"14px"}
                                        fontWeight={"500"}
                                        textDecorationLine={"underline"}
                                        cursor={"pointer"}
                                        >
                                        Limited Features
                                    </Text>
                                </Stack>
                                <Box
                                    mb={{base:"15px",lg:"10px",xl:"20px"}}
                                    >
                                    <FormLabel 
                                        htmlFor="promo"
                                        fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                        fontWeight={"500"}
                                        >
                                        Promo Code
                                    </FormLabel>
                                    <Input 
                                        type={"text"}
                                        onChange={e => setSignupInfo({
                                            ...signupInfo,
                                            promoCode: e.target.value
                                          })}
                                        h={"auto"}
                                        p={"8px 30px"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"15px",xl:"18px"}}
                                        fontWeight={"500"}
                                        _focusVisible={{boxShadow:"none",border:"1px solid #000"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"promo" }
                                        name={"promo"} 
                                        autoComplete={"promo"}
                                    />
                                </Box>
                            </Box>
                            <Box
                                m={{base:"10px 0px",lg:"5px 0px",xl:"20px 0px"}}
                                >
                                <FormLabel 
                                    htmlFor="name" 
                                    fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                    fontWeight={"500"}
                                    >
                                    Full Name
                                </FormLabel>
                                <Box>
                                    <Input 
                                        type={"text"}
                                        onChange={e => setSignupInfo({
                                            ...signupInfo,
                                            fullName: e.target.value
                                          })}
                                        h={"auto"}
                                        p={"8px 30px"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"15px",xl:"18px"}}
                                        fontWeight={"500"}
                                        _focusVisible={{boxShadow:"none",border:"1px solid #000"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"name" }
                                        name={"name"} 
                                        autoComplete={"name"}
                                    />
                                </Box>
                            </Box>
                            <Box
                                mb={{sm:"5px",xl:"15px"}}
                                >
                                <FormLabel 
                                    htmlFor="email" 
                                    fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                    fontWeight={"500"}
                                    >
                                    Email Address
                                </FormLabel>
                                <Box>
                                    <Input 
                                        type={"email"}
                                        onChange={e => setSignupInfo({
                                            ...signupInfo,
                                            email: e.target.value
                                          })}
                                        h={"auto"}
                                        p={"8px 30px"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"15px",xl:"18px"}}
                                        fontWeight={"500"}
                                        _focusVisible={{boxShadow:"none",border:"1px solid #000"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"email" }
                                        name={"email"} 
                                        autoComplete={"email"}
                                    />
                                </Box>
                            </Box>
                            <Box
                                mb={{sm:"5px",xl:"15px"}}
                                >
                                <FormLabel 
                                    htmlFor="web" 
                                    fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                    fontWeight={"500"}
                                    >
                                    Website Url
                                </FormLabel>
                                <Box>
                                    <Input 
                                        type={"url"}
                                        onChange={e => setSignupInfo({
                                            ...signupInfo,
                                            websiteUrl: e.target.value
                                          })}
                                        h={"auto"}
                                        p={"8px 30px"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"15px",xl:"18px"}}
                                        fontWeight={"500"}
                                        _focusVisible={{boxShadow:"none",border:"1px solid #000"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"web" }
                                        name={"web"} 
                                        autoComplete={"web"}
                                    />
                                </Box>
                            </Box>
                            <Box 
                                mb={{base:"10px",lg:"5px",xl:"25px"}}
                                >
                                <FormLabel 
                                    htmlFor="pass" 
                                    fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                    fontWeight={"500"}
                                    >
                                    Password
                                </FormLabel>
                                <Box pos={"relative"}>
                                    <Input
                                        type={showpass ? 'text' : 'password'}

                                        onChange={e => setSignupInfo({
                                            ...signupInfo,
                                            password: e.target.value
                                          })}

                                        h={"auto"}
                                        p={"8px 30px"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"15px",xl:"18px"}}
                                        fontWeight={"500"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"pass" }
                                        name={"pass"} 
                                        autoComplete={"pass"}
                                    />
                                    <Button onClick={() => setshowpass(!showpass)} pos={"absolute"} right={"10px"} top={"2px"} bg={"none"}>
                                        <Icon as={showpass ? BsEye : BsEyeSlash} />
                                    </Button>
                                </Box>
                            </Box>
                            <Box 
                                mb={{base:"10px",lg:"5px",xl:"25px"}}
                                >
                                <FormLabel 
                                    htmlFor="pass" 
                                    fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                    fontWeight={"500"}
                                    >
                                    Confirm Password
                                </FormLabel>
                                <Box pos={"relative"}>
                                    <Input
                                        type={showpass ? 'text' : 'password'}

                                        onChange={e => setSignupInfo({
                                            ...signupInfo,
                                            confirmPassword: e.target.value
                                          })}

                                        h={"auto"}
                                        p={"8px 30px"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"15px",xl:"18px"}}
                                        fontWeight={"500"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"pass" }
                                        name={"pass"} 
                                        autoComplete={"pass"}
                                    />
                                    <Button onClick={() => setshowpass(!showpass)} pos={"absolute"} right={"10px"} top={"2px"} bg={"none"}>
                                        <Icon as={showpass ? BsEye : BsEyeSlash} />
                                    </Button>
                                </Box>
                            </Box>
                            <Button 
                                // as={Link} 
                                // to={"#"}
                                onClick={handleSubmit}
                                w={"100%"}
                                h={"auto"}
                                p={"10px 0px"}
                                bg={"#D4FD44"}
                                border={"1px solid #D4FD44"}
                                borderRadius={"12px"}
                                mb={{sm:"10px",xl:"20px"}}
                                fontSize={{base:"16px",lg:"15px",xl:"16px"}}
                                _hover={{bg:"none",border:"1px solid #000"}}
                                >
                                Sign Up
                            </Button>
                            <Modal 
                                isOpen={showModal} onClose={() => setShowModal(false)} 
                                isCentered
                                >
                                <ModalOverlay  bg={"#000"} opacity={"0.78 ! important"}/>
                                <ModalContent 
                                    maxW={{base:"320px",md:"650px",lg:"630px",xl:"740px"}} 
                                    p={{base:"10px 18px",sm:'15px 35px'}} 
                                    borderRadius={"24px"}
                                    >
                                    <ModalHeader
                                        p={{base:"5px 0px",sm:"10px 0px"}}
                                        borderBottom={"1px solid #8A8A8A"}
                                        >
                                        <Stack
                                            direction={"row"}    
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            >
                                            <Text
                                                fontSize={{base:"13px",lg:"20px",xl:"22px"}}    
                                                fontWeight={"600"}
                                                >
                                                Choose your website type
                                            </Text>
                                            <Button
                                                onClick={registerUser}
                                                h={"auto"}
                                                p={{base:'6px 18px',sm:"8px 25px"}}
                                                borderRadius={"12px"}
                                                bg={"#D4FD44"}
                                                fontSize={{base:"13px",lg:'16px',xl:'18px'}}
                                                fontWeight={"500"}
                                                >
                                                Continue
                                            </Button>
                                        </Stack>
                                    </ModalHeader>
                                    <ModalCloseButton 
                                        top={"-35px"} 
                                        right={"20px"} 
                                        color={"#FFF"}
                                        fontSize={"20px"}
                                        onClick={handleCloseModal}
                                    />
                                    <ModalBody
                                        p={{base:"5px 0px",sm:"10px 0px"}}    
                                        >
                                        <Stack 
                                            direction={"row"} 
                                            alignItems={"center"} 
                                            gap={{base:'5px',sm:'10px'}}
                                            flexWrap={"wrap"}
                                            mb={"10px"}
                                            >
                                           {marketplace.map((val, index) => {
    const isDisabled = val.title !== 'Woocommerce'; // Check if title is not 'Woocommerce'
    return (
        <Stack
            key={index} 
            direction={"row"} 
            alignItems={"center"} 
            gap={{base:"3px",sm:'10px'}} 
            bg={selectedTypeIndex === index ? "#D4FD44" : "#EEEEEE"}
            w={{base:"100%",lg:"270px",md:"280px",xl:"320px"}} 
            borderRadius={"14px"} 
            p={{base:"4px 5px",sm:'10px 10px'}} 
            ml={"0px ! important"}
            onClick={() => handleWebsiteType(val._id)}
            cursor={"pointer"}
            _hover={{
                bg:"#D4FD44",
                transition:"all 0.4s",
            }}
        >
            <Checkbox colorScheme='yellow' isDisabled={isDisabled}>
                <Text fontSize={'20px'}>{val.title}</Text>
            </Checkbox>
        </Stack>
    );
})}

                                        </Stack>
                                      
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                            <Text 
                                fontSize={{base:"14px",sm:"17px",lg:"15px",xl:"17px"}} 
                                textAlign={"center"}
                                >
                                Already have an account?{" "}
                                <Text 
                                    as={Link} 
                                    to={"/login"} 
                                    fontWeight={"600"}
                                    >
                                    Log In
                                </Text>
                            </Text>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        </>
    )
}

export default SignUp;