import { Box, FormLabel, Image, Input, Stack, Text,Button,Icon, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsEyeSlash, BsEye } from 'react-icons/bs';
//Images
import loginImg from '../../assets/images/website/Auth/loginImg.png';
import backArrow from '../../assets/images/website/Auth/backArrow.png';
import { useDispatch, useSelector } from "react-redux";
import { POST } from "../../utilities/ApiProvider";

const SetNewPassword=()=>{
    const [showpass, setshowpass] = useState(false);
    const [showpass1, setshowpass1] = useState(false);

    const accountInfo = useSelector(state => state.email);

    const [data,setData] = useState({
        email : accountInfo.email,
        password : '',
        confirmPassword : '' 
    })
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();




    // Hanlde Form Submission

    const changePassword = async() =>
    {
        try
        {
            let res = await POST(`/auth/change-password`,data,{ authorization: `Bearer ${accountInfo?.jwtToken}` });
            if(res.status == 200)
            {
                toast({
                    description : res.message
                })
               
                navigate('/login')

            }
        }
        catch(error)
        {

        }
    }



    return(
        <>
        <Box
            w={"100vw"}
            h={"100vh"}
            display={"flex"}
            alignItems={"center"}
            >
            <Box 
                display={{base:'none',sm:"none",md:"none",lg:"block",xl:"block"}}
                w={"44%"} 
                h={"100vh"} 
                bgImage={loginImg} 
                bgPos={"center"} 
                bgSize={"cover"} 
                bgRepeat={"no-repeat"}
                >
            </Box>
            <Stack
                w={{base:"100%",sm:"100%",md:"100%",lg:"56%",xl:"56%"}} 
                h={"100vh"}
                >
                <Stack
                    pos={"relative"} 
                    alignItems={"center"} 
                    justifyContent={"center"}
                    w={{base:"350px",sm:"580px",md:"580px",lg:"500px",xl:"580px"}}
                    h={{base:"100vh",sm:"90vh"}} 
                    m={"auto"}
                    >
                    <Box w={"inherit"}>
                        <Box 
                            pos={"absolute"} 
                            top={{base:"20px",sm:"0"}} 
                            left={{base:"0",lg:"20px",xl:"0"}}
                            >
                            <Stack direction={"row"} alignItems={"center"}>
                                <Link to={"#"}>
                                    <Image src={backArrow}/>
                                </Link>
                                <Text fontWeight={"500"}>Back</Text>
                            </Stack>
                        </Box>
                        <Box> 
                           
                            <Text
                                onChange={e => setData({
                                    ...data,
                                    password: e.target.value
                                  })}
                                fontSize={{base:"20px",sm:"28px",md:"28px",lg:"25px",xl:"28px"}}
                                fontWeight={"700"}
                                mb={"10px"}
                                >
                                Set New Password
                            </Text>
                            <Text
                             
                                fontSize={{base:"13px",sm:"16px",md:"16px",lg:"15px",xl:"16px"}}
                                color={"#464646"}
                                mb={"20px"}
                                >
                                Make sure your password should be strong and not a ordinary
                            </Text>
                            <Stack 
                                mb={"15px"}
                                >
                                <FormLabel 
                                    htmlFor="pass" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    New Password
                                </FormLabel>
                                <Box pos={"relative"}>
                                    <Input
                                        onChange={e => setData({
                                            ...data,
                                            password: e.target.value
                                        })}
                                        type={showpass ? 'text' : 'password'}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"pass" }
                                        name={"pass"} 
                                        autoComplete={"pass"}
                                    />
                                    <Button 
                                        onClick={() => setshowpass(!showpass)} 
                                        pos={"absolute"} 
                                        right={"10px"} 
                                        top={{base:"0px",sm:"2px"}}
                                        bg={"none"}
                                        >
                                        <Icon as={showpass ? BsEye : BsEyeSlash} />
                                    </Button>
                                </Box>
                            </Stack>
                            <Stack 
                                mb={{base:"20px",sm:"40px"}}
                                >
                                <FormLabel 
                                    htmlFor="pass" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Re-type Password
                                </FormLabel>
                                <Box pos={"relative"}>
                                    <Input

                                        onChange={e => setData({
                                            ...data,
                                            confirmPassword: e.target.value
                                        })}

                                        type={showpass1 ? 'text' : 'password'}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"pass" }
                                        name={"pass"} 
                                        autoComplete={"pass"}
                                    />
                                    <Button 
                                        onClick={() => setshowpass1(!showpass1)} 
                                        pos={"absolute"} 
                                        right={"10px"} 
                                        top={{base:"0px",sm:"2px"}} 
                                        bg={"none"}
                                        >
                                        <Icon as={showpass1 ? BsEye : BsEyeSlash} />
                                    </Button>
                                </Box>
                            </Stack>
                            <Button 
                                onClick={changePassword}
                                as={Link} 
                                to={"#"}
                                w={"100%"}
                                h={"auto"}
                                p={{base:"8px 0px",sm:"12px 0px"}}
                                fontSize={{base:"14px",sm:"16px",md:"16px",lg:"16px",xl:"16px"}}
                                bg={"#D4FD44"}
                                border={"1px solid #D4FD44"}
                                borderRadius={"12px"}
                                mb={"20px"}
                                _hover={{bg:"none",border:"1px solid #000"}}
                                >
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        </>
    )
}

export default SetNewPassword;