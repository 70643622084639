import {
  Box,
  Button,
  Container,
  FormLabel,
  Input,
  Text,
  Stack,
  Image,
  useToast,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import DashBoardHeader from '../../components/Dashboard/Header/DashboardHeader';
import accImg from '../../assets/images/Dashboard/accImg.png';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { loadUser,loadAdminPic } from '../../reducers/useReducers';
import { POST, PUT } from '../../utilities/ApiProvider';
import { imgUrl } from '../../utilities/config';

const Account = () => {
  // get data using useSelector
  const accountInfo = useSelector(state => state.value);

  // Update Account
  const imgReg = useRef(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [signupInfo, setSignupInfo] = useState({});
  const [uploadingImage, setUploadingImage] = useState(false);

 

  const registerUser = async () => {
    setIsLoading(true);

    try {
      let res = await PUT(`/vendor/profile`, signupInfo, {
        authorization: `Bearer ${accountInfo?.jwtToken}`,
      });
      if (res.status == 200) {
        toast({
          description: res.message,
        });
        dispatch(loadUser(res.data));
      } else {
        toast({
          description: res.message,
        });
      }
    } catch (error) {
      toast({
        description: error.message,
      });
    }
  };

  const uploadProfilePic = async () => {
    setUploadingImage(true);
    try {
      let form = document.getElementById('profilePicForm');
      let data = new FormData(form);
      let res = await PUT('/vendor/profilePicture', data, {
        authorization: `Bearer ${accountInfo?.jwtToken}`,
      });
      console.log('upload pic ', res);
      dispatch(loadAdminPic(res?.data?.picture));
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setUploadingImage(false);
  };

  return (
    <>
      <DashBoardHeader />

      <Box p={'30px 0px'}>
        <Container maxW={'container.xl'}>
          <Box>
            <Stack
              direction={'row'}
              alignItems={'self-start'}
              justifyContent={{ base: 'center', lg: 'space-between' }}
              flexWrap={'wrap-reverse'}
              gap={{ base: '15px', lg: 'unset' }}
            >
              <Box w={{ base: '330px', sm: '580px', lg: '500px', xl: '580px' }}>
                <Text
                  fontSize={{
                    base: '18px',
                    sm: '24px',
                    lg: '21px',
                    xl: '24px',
                  }}
                  fontWeight={'600'}
                  mb={'15px'}
                >
                  Account
                </Text>
                <Box mb={'30px'}>
                  <Box mb={'15px'}>
                    <FormLabel
                      fontSize={{
                        base: '14px',
                        sm: '17px',
                        lg: '15px',
                        xl: '17px',
                      }}
                      fontWeight={'500'}
                    ></FormLabel>
                    <Input
                      type="text"
                      onChange={e =>
                        setSignupInfo({
                          ...signupInfo,
                          fullName: e.target.value,
                        })
                      }
                      value={accountInfo?.fullName}
                      bg={'#EAEAEA'}
                      h={'auto'}
                      p={{
                        base: '7px 20px',
                        sm: '10px 25px',
                        lg: '8px 20px',
                        xl: '10px 25px',
                      }}
                      fontSize={{
                        base: '14px',
                        sm: '18px',
                        lg: '16px',
                        xl: '18px',
                      }}
                      fontWeight={'500'}
                      borderRadius={'12px'}
                      placeholder="John Doe"
                      _placeholder={{ color: '#000' }}
                      _focusVisible={{ boxShadow: 'none' }}
                    />
                  </Box>
                  <Box mb={'15px'}>
                    <FormLabel
                      fontSize={{
                        base: '14px',
                        sm: '17px',
                        lg: '15px',
                        xl: '17px',
                      }}
                      fontWeight={'500'}
                    >
                      Email Address
                    </FormLabel>
                    <Input
                      type="email"
                      value={accountInfo?.email}
                      bg={'#EAEAEA'}
                      h={'auto'}
                      p={{
                        base: '7px 20px',
                        sm: '10px 25px',
                        lg: '8px 20px',
                        xl: '10px 25px',
                      }}
                      fontSize={{
                        base: '14px',
                        sm: '18px',
                        lg: '16px',
                        xl: '18px',
                      }}
                      fontWeight={'500'}
                      borderRadius={'12px'}
                      placeholder="johndoe423@gmail.com"
                      _placeholder={{ color: '#000' }}
                      _focusVisible={{ boxShadow: 'none' }}
                    />
                  </Box>
                  <Box mb={'15px'}>
                    <FormLabel
                      fontSize={{
                        base: '14px',
                        sm: '17px',
                        lg: '15px',
                        xl: '17px',
                      }}
                      fontWeight={'500'}
                    >
                      Website Url
                    </FormLabel>
                    <Input
                      type="url"
                      onChange={e =>
                        setSignupInfo({
                          ...signupInfo,
                          websiteUrl: e.target.value,
                        })
                      }
                      value={accountInfo?.websiteUrl}
                      bg={'#EAEAEA'}
                      h={'auto'}
                      p={{
                        base: '7px 20px',
                        sm: '10px 25px',
                        lg: '8px 20px',
                        xl: '10px 25px',
                      }}
                      fontSize={{
                        base: '14px',
                        sm: '18px',
                        lg: '16px',
                        xl: '18px',
                      }}
                      fontWeight={'500'}
                      borderRadius={'12px'}
                      placeholder="https://yourname.com"
                      _placeholder={{ color: '#000' }}
                      _focusVisible={{ boxShadow: 'none' }}
                    />
                  </Box>
                </Box>
                <Text
                  fontSize={{
                    base: '18px',
                    sm: '24px',
                    lg: '21px',
                    xl: '24px',
                  }}
                  fontWeight={'600'}
                  mb={'15px'}
                >
                  Change Password
                </Text>
                <Box mb={{ base: '20px', sm: '40px' }}>
                  <Box mb={'15px'}>
                    <FormLabel
                      fontSize={{
                        base: '14px',
                        sm: '17px',
                        lg: '15px',
                        xl: '17px',
                      }}
                      fontWeight={'500'}
                    >
                      New Password
                    </FormLabel>
                    <Input
                      type="text"
                      onChange={e =>
                        setSignupInfo({
                          ...signupInfo,
                          password: e.target.value,
                        })
                      }
                      bg={'#EAEAEA'}
                      h={'auto'}
                      p={{
                        base: '7px 20px',
                        sm: '10px 25px',
                        lg: '8px 20px',
                        xl: '10px 25px',
                      }}
                      fontSize={{
                        base: '14px',
                        sm: '18px',
                        lg: '16px',
                        xl: '18px',
                      }}
                      fontWeight={'500'}
                      borderRadius={'12px'}
                      placeholder="Enter here"
                      _placeholder={{ color: '#000' }}
                      _focusVisible={{ boxShadow: 'none' }}
                    />
                  </Box>
                  <Box>
                    <FormLabel
                      fontSize={{
                        base: '14px',
                        sm: '17px',
                        lg: '15px',
                        xl: '17px',
                      }}
                      fontWeight={'500'}
                    >
                      Confirm Password
                    </FormLabel>
                    <Input
                      type="text"
                      onChange={e =>
                        setSignupInfo({
                          ...signupInfo,
                          confirmPassword: e.target.value,
                        })
                      }
                      bg={'#EAEAEA'}
                      h={'auto'}
                      p={{
                        base: '7px 20px',
                        sm: '10px 25px',
                        lg: '8px 20px',
                        xl: '10px 25px',
                      }}
                      fontSize={{
                        base: '14px',
                        sm: '18px',
                        lg: '16px',
                        xl: '18px',
                      }}
                      fontWeight={'500'}
                      borderRadius={'12px'}
                      placeholder="Enter here"
                      _placeholder={{ color: '#000' }}
                      _focusVisible={{ boxShadow: 'none' }}
                    />
                  </Box>
                </Box>
                <Box textAlign={{ base: 'center', lg: 'unset' }}>
                  <Button
                    onClick={registerUser}
                    as={Link}
                    to={'#'}
                    h={'auto'}
                    p={{ base: '7px 25px', sm: '10px 35px' }}
                    bg={'#D4FD44'}
                    fontSize={{
                      base: '14px',
                      sm: '18px',
                      lg: '16px',
                      xl: '18px',
                    }}
                    fontWeight={'500'}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>

              <Box
                w={{
                  base: '330px',
                  sm: '400px',
                  md: '470px',
                  lg: '400px',
                  xl: '470px',
                }}
              >
                <Box bg={'#EAEAEA'} p={'30px 0px'} borderRadius={'12px'}>
                  <Stack alignItems={'center'}>
                    <Image
                      src={`${imgUrl}${accountInfo?.picture}`}
                      w="100px"
                      h="100px"
                      objectFit="cover"
                      borderRadius={50}
                    />
                    <form id="profilePicForm">
              <Input
                onChange={uploadProfilePic}
                ref={imgReg}
                display={'none'}
                type="file"
                name="picture"
              />
            </form>
                    <Button
                    isLoading={uploadingImage}
                    onClick={() => imgReg?.current.click()}
                      h={'auto'}
                      w={{ base: '140px', sm: '180px' }}
                      p={{
                        base: '5px 0px',
                        sm: '8px 0px',
                        lg: '6px 0px',
                        xl: '8px 0px',
                      }}
                      fontSize={{
                        base: '13px',
                        sm: '17px',
                        lg: '15px',
                        xl: '17px',
                      }}
                      bg={'#D4FD44'}
                      border={'1px solid #D4FD44'}
                      fontWeight={'500'}
                      _hover={{
                        bg: 'none',
                        border: '1px solid #000',
                      }}
                    >
                      Change Picture
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Account;
