import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from "@chakra-ui/react"
import AppRoute from './routes/Route';
import { loadMembership, loadUser } from './reducers/useReducers'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { GET } from './utilities/ApiProvider';
import axios from 'axios';

function App() {



  const theme = extendTheme({
    colors: {
      primaryBlack: {
        100: '#1a1a1a',
      },
      primaryGreen: {
        100: '#0bd46e',
      }
    },
  });



 






  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      // Your code here will execute only once after the component mounts
      let Membership = await GET('/admin/plans', {});
      if(Membership.status == 200)
      {
          dispatch(loadMembership(Membership.data))
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    (async () => {
      if (localStorage.getItem('user') !== null) {
        let user = JSON.parse(localStorage.getItem('user') ?? '{}')
        dispatch(loadUser(user))
      } else if (Cookies.get('user') !== undefined) {
        let user = JSON.parse(Cookies.get('user') ?? '{}')
        dispatch(loadUser(user))
      }
      // Fetch Membership

      fetchData()

    })()
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <AppRoute />
    </ChakraProvider>
  );
}

export default App;
