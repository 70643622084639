import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { GET } from '../utilities/ApiProvider'
import Cookies from 'js-cookie'

// Basic Global State
const initialState = {};

// State Updating Functions
const marketPlaceReducer = createSlice({
    name: 'marketPlace',
    initialState,
    reducers: {
        loadMarketPlace: (state, action) => {
            state.value = action.payload;
            localStorage.setItem('marketPlace', JSON.stringify(action.payload));
        },
    }
})

// Exporting All the State Updating Functions
export const { loadMarketPlace } = marketPlaceReducer.actions
export default marketPlaceReducer.reducer
