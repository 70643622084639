import { Box, Flex, Text, IconButton, Button, Stack, Collapse, Icon, Image, useColorModeValue, useDisclosure, Container,  Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalCloseButton, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import ssLogo from '../../../assets/images/website/home/sslogo.png';
import {HamburgerIcon, CloseIcon, ChevronDownIcon} from '@chakra-ui/icons';

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box position={"absolute"} zIndex={"1"} w={"100%"}>
      <Container maxW={"container.xl"}>
        <Flex
          bg={'none'}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          p={"30px 0px"}
          align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', lg: 'none' }}
            >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex 
            flex={{ base: 1, sm: "none" ,lg: 1 }} 
            justify={{ base:'center', lg: 'start'}} 
            alignItems={"center"} 
            justifyContent={{base:"right",sm:"space-between",md:"space-between",lg:"space-between",xl:"space-between"}}
            >
            <Link to={"/"}>
              <Image 
                src={ssLogo} 
                w={{base:"110px",sm:"150px",md:"160px",lg:"190px",xl:"190px"}}
              />
            </Link>

            <Flex display={{ base: 'none', lg: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Container>
    </Box>
  );
}

const DesktopNav = () => {

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Button
            as={Link}
            to={navItem.href}
            fontSize={{base:'14px',sm:'17px',md:'16px',xl:'17px'}}
            fontWeight={"500"}
            color={"#D9D9D9"}
            bg={"rgba(255, 255, 255, 0.05)"}
            p={"8px 35px"}
            textDecoration={'inherit'}
            height={"auto"}
            borderRadius={"12px"}
            border={"1px solid #fff"}
            _hover={{
              textDecorationLine:"inherit",
              background:"none",
              color:"#D4FD44",
              border:"1px solid #D4FD44"
            }}>
            {navItem.label}
          </Button>
        </Box>
      ))}
    </Stack>
  );
};

  const MobileNav = () => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ lg: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };
  
  const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();
    const [isOpen2, setIsOpen2] = useState(false);
    const onOpen2 = () => setIsOpen2(true);
    const onClose2 = () => setIsOpen2(false);
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          // as={Link}
          // to={href ?? '#'}
          onClick={onOpen2}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          <Modal 
            isOpen={isOpen2} 
            onClose={onClose2}
            isCentered
            >
            <ModalOverlay 
              bg={"#000"} 
              opacity={"0.78 ! important"}
            />
            <ModalContent 
              w={{base:"300px",sm:"570px"}}
              p={"10px 30px"} 
              borderRadius={"24px"}
              >
              <ModalHeader 
                p={"10px 0px ! important"}
                >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  >
                  <Text 
                    fontSize={{base:"23px",sm:"30px"}}
                    fontWeight={"600"}
                    >
                    Coming Soon
                  </Text>
                </Stack>
              </ModalHeader>
              <ModalCloseButton 
                top={"-35px"} 
                right={"10px"} 
                color={"#FFF"}
              />
              <ModalBody
                p={"10px 0px ! important"}
                textAlign={"center"}
                >
                <Text 
                  fontWeight={"500"}
                  mb={"10px"}
                  fontSize={{base:"13px",sm:"16px"}}
                  >
                  Get notified when product goes live
                </Text>
                <Stack
                  alignItems={"center"}
                  mb={"10px"}
                  >
                  <Input
                    type='email'
                    border={"1px solid #000 ! important"}
                    borderRadius={"12px"}
                    h={"auto"}
                    p={{base:"4px 20px",sm:"6px 20px"}}
                    fontSize={{base:"13px",sm:"16px"}}
                    placeholder='Enter Your Email'
                    _placeholder={{
                      fontSize:{base:"13px",sm:"15px"},
                      fontWeight:"500",
                      color:"#000"
                    }}
                    _focusVisible={{
                      boxShadow:"none"
                    }}
                  />
                  <Button 
                    as={Link}
                    to={"#"}
                    zIndex={"1"}
                    fontSize={{base:"13px",sm:"15px"}}
                    fontWeight={"500"}
                    w={"50%"}
                    h={"auto"}
                    p={{base:"7px 30px",sm:"11px 30px"}}
                    ml={"0px ! important"}
                    border={"1px solid #D4FD44"}
                    bg={"#D4FD44"}
                    borderRadius={"12px"}
                    _hover={{
                      bg:"#fff",
                      color:"#000",
                      border:"1px solid #000"
                    }}
                    >
                    Subscribe
                  </Button>
                </Stack>
                <Text 
                  fontSize={{base:"13px",sm:"15px"}}
                  mb={"10px"}
                  >
                  If you have any questions, please contact us!
                </Text>
                <Text 
                  as={Link}
                  to={"#"}
                  fontSize={{base:"13px",sm:"16px"}}
                  fontWeight={"500"}
                  textDecorationLine={"underline ! important"}
                  >
                  info@stylesense.com
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children.map((child) => (
                <Link key={child.label} py={2} to={child.href}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  const NAV_ITEMS = [
  {
    label: 'Login',
    href:"/login"
  },
  {
    label: 'Buy Membership',
    href:"/signup"
  }
];