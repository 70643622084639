import { Box, Button, Container, Image, Stack, Text} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SuperHeader from "../../components/SuperAdminPanel/SuperHeader";
import themeImage from '../../assets/images/AdminPanel/themeImage.png';
import { adminUser } from "../../assets/data/Data";
// pie Chart 
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { GET } from "../../utilities/ApiProvider";
import { useEffect, useState } from "react";
ChartJS.register(ArcElement, Tooltip, Legend);

const MainSuperAdmin =()=>{

    // define states
    const [home,setHome] = useState({})
    const [markeplaceOptions , setMarketPlaceOptions] = useState([]);

    // Fetch data Here

    const fetchData = async () => {
        try {
          // Your code here will execute only once after the component mounts
          let homepage = await GET('/admin/home', {});
          if(homepage.status == 200)
          {
                setHome(homepage.data);
                // setMarketPlaceOptions( homepage.data?.themes?.map((e) => console.log(e))   )
                console.log(markeplaceOptions);
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  

    useEffect(() => {
        
        fetchData();
    
        // If you need to do some cleanup when the component unmounts, return a function
        return () => {
          // Your cleanup code here
        };
      }, []); // Empty dependency array signifies that this effect should run only once
    




    const data = {
        labels: ["WooCommerce"],
        datasets: [
          {
            label: '',
            data: [home?.allUsers],
            backgroundColor: [
                '#FF7E00'
            ],
            borderColor: [
                '#FF7E00',
                '#46A434',
                '#F82A2A',
            ],
            borderWidth: 1,
          },
        ],
    };
    const options = {
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
            },
          },
        }
    };
    const data2 = {
        labels: ["Free Trial"],
        datasets: [
          {
            label: '',
            data: [home?.allUsers],
            backgroundColor: [
                '#FF7E00',
                '#46A434',
                '#F82A2A'
            ],
            borderColor: [
                '#FF7E00',
                '#46A434',
                '#F82A2A',
            ],
            borderWidth: 1,
          },
        ],
    };
    const options2 = {
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
              
            },
          },
        }
    };

    return(
        <>
            <SuperHeader/>

            <Box p={"40px 0px"}>
                <Container maxW={"container.xl"}>
                    <Box>
                        <Stack
                            direction={"row"}    
                            alignItems={"center"}
                            justifyContent={{base:"space-between",sm:"center",lg:"space-between"}}
                            flexWrap={"wrap"}
                            gap={{base:"10px",sm:"15px",lg:"unset"}}
                            >
                            <Box
                                w={{base:"370px",sm:"360px",lg:"315px",xl:"370px"}}    
                                borderRadius={"13px"}
                                bg={"#EEEEEE"}
                                p={"10px 15px"}
                                >
                                <Stack
                                    direction={"row"}            
                                    alignItems={"flex-end"}
                                    justifyContent={"space-between"}
                                    >
                                    <Stack
                                        gap={"5px"}    
                                        >
                                        <Text
                                            fontSize={"14px"}
                                            fontWeight={"500"}
                                            >
                                            Total Users
                                        </Text>
                                        <Text
                                            fontSize={{base:'30px',sm:"40px"}}
                                            fontWeight={"500"}
                                            >
                                            {home?.allUsers}
                                        </Text>
                                    </Stack>
                                    
                                </Stack>
                            </Box>
                            <Box
                                w={{base:"370px",sm:"360px",lg:"315px",xl:"370px"}}    
                                borderRadius={"13px"}
                                bg={"#EEEEEE"}
                                p={"10px 15px"}
                                ml={"0px ! important"}
                                >
                                <Stack
                                    direction={"row"}            
                                    alignItems={"flex-end"}
                                    justifyContent={"space-between"}
                                    >
                                    <Stack
                                        gap={"5px"}    
                                        >
                                        <Text
                                            fontSize={"14px"}
                                            fontWeight={"500"}
                                            >
                                            Total Membership Plans
                                        </Text>
                                        <Text
                                            fontSize={{base:'30px',sm:"40px"}}
                                            fontWeight={"500"}
                                            >
                                          {home?.totalMembership}

                                        </Text>
                                    </Stack>
                                    
                                </Stack>
                            </Box>
                            <Box
                                w={{base:"370px",sm:"360px",lg:"315px",xl:"370px"}}   
                                borderRadius={"13px"}
                                bg={"#EEEEEE"}
                                p={"10px 15px"}
                                ml={"0px ! important"}
                                >
                                <Stack
                                    direction={"row"}            
                                    alignItems={"flex-end"}
                                    justifyContent={"space-between"}
                                    >
                                    <Stack
                                        gap={"5px"}    
                                        >
                                        <Text
                                            fontSize={"14px"}
                                            fontWeight={"500"}
                                            >
                                            Blocked Users
                                        </Text>
                                        <Text
                                            fontSize={{base:'30px',sm:"40px"}}
                                            fontWeight={"500"}
                                            >
                                          {home?.blockedUsers}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    <Box 
                        p={"40px 0px"}
                        >
                        <Stack
                            direction={"row"}    
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            flexWrap={"wrap"}
                            gap={{base:'10px',lg:"unset"}}
                            >
                            <Stack 
                                w={{base:'360px',sm:"100%",lg:"480px",xl:"570px"}}
                                >
                                <Text
                                    fontSize={{base:"19px",sm:"24px"}}
                                    fontWeight={"600"}
                                    >
                                    Most Website Stack
                                </Text>
                                <Stack
                                    h={{base:'220px',sm:"290px"}}
                                    alignItems={"center"}
                                    p={"0px 20px"}
                                    bg={"#EEEEEE"}
                                    borderRadius={"13px"}
                                    >
                                    <Pie data={data} options={options}/>
                                </Stack>
                            </Stack>
                            <Stack 
                                w={{base:'360px',sm:"100%",lg:"480px",xl:"570px"}}
                                ml={"0px ! important"}
                                >
                                <Text
                                    fontSize={{base:"19px",sm:"24px"}}
                                    fontWeight={"600"}
                                    >
                                    Membership Plans
                                </Text>
                                <Stack
                                    alignItems={"center"}
                                    h={{base:'220px',sm:"290px"}}
                                    p={"0px 20px"}
                                    bg={"#EEEEEE"}
                                    borderRadius={"13px"}
                                    >
                                    <Pie data={data2} options={options2}/>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack
                            direction={"row"}    
                            alignItems={"flex-start"}
                            justifyContent={"space-between"}
                            flexWrap={"wrap"}
                            gap={{base:"10px", lg:'unset'}}
                            >
                            <Box>
                                <Text
                                    fontSize={{base:"19px",sm:"24px"}}
                                    fontWeight={"600"}
                                    mb={"20px"}
                                    >
                                    Themes
                                </Text>
                                <Stack 
                                    bg={"#EEEEEE"} 
                                    p={{base:"10px 10px",sm:"15px 15px",xl:"15px 25px"}} 
                                    borderRadius={"13px"}
                                    gap={"10px"}
                                    w={{base:"unset",sm:"735px",lg:"unset"}}
                                    >
                                    {
                                        home?.themes?.map((e) =>
                                        <Stack
                                        p={{base:"10px",sm:'15px'}}
                                        bg={"#FFF"}
                                        borderRadius={"13px"}
                                        w={{base:"330px",sm:"100%",lg:"460px",xl:'530px'}}
                                        direction={"row"}    
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        gap={{base:'10px',sm:'unset'}}
                                        flexWrap={"wrap"}
                                        >
                                        <Stack
                                            direction={"row"}    
                                            alignItems={{base:'center',sm:"flex-start"}}
                                            gap={"10px"}
                                            >
                                            <Image src={themeImage}/>
                                            <Box>
                                                <Text
                                                    fontSize={{base:"15px",sm:"18px",lg:"16px",xl:"18px"}}
                                                    fontWeight={"600"}
                                                    >
                                                    {e.title}
                                                </Text>
                                                <Text
                                                    fontSize={{base:"12px",sm:"14px"}}
                                                    fontWeight={"500"}
                                                    >
                                                    Type: Free
                                                </Text>
                                            </Box>
                                        </Stack>
                                        
                                    </Stack>
                               
                                        )
                                    }
                                   
                                </Stack>
                            </Box>
                            <Box 
                                ml={"0px ! important"}
                                w={{base:"unset",sm:"735px",lg:"unset"}}
                                >
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={"20px"}
                                    >
                                    <Text
                                        fontSize={{base:"19px",sm:"24px"}}
                                        fontWeight={"600"}
                                        >
                                        Users
                                    </Text>
                                    <Link to={"#"} style={{fontSize:{base:"15px",sm:"18px"},fontWeight:"500"}}>
                                        View All
                                    </Link>
                                </Stack>
                                <Stack 
                                    bg={"#EEEEEE"} 
                                    p={{base:"10px 10px",sm:"15px 15px",xl:"15px 25px"}} 
                                    borderRadius={"13px"}
                                    gap={"10px"}
                                    >
                                    {
                                        home?.users?.map((e,key)=>(
                                            <Stack
                                                key={key}
                                                p={{base:"10px",sm:"15px"}}
                                                bg={"#FFF"}
                                                borderRadius={"13px"}
                                                 w={{base:"330px",sm:"100%",lg:"460px",xl:'530px'}}
                                                direction={"row"}    
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                flexWrap={"wrap"}
                                                gap={{base:'10px',sm:"unset"}}
                                                >
                                                <Stack
                                                    direction={"row"}    
                                                    alignItems={{base:"center",sm:"flex-start"}}
                                                    gap={"10px"}
                                                    >
                                                    <Box w={"68px"} h={"68px"} bg={"#D9D9D9"} borderRadius={"50%"}></Box>
                                                    <Box>
                                                        <Text
                                                            fontSize={{base:"15px",sm:"18px",lg:"16px",xl:"18px"}}
                                                            fontWeight={"600"}
                                                            >
                                                            {e.fullName}
                                                        </Text>
                                                        <Text
                                                            fontSize={{base:"12px",sm:"14px"}}
                                                            fontWeight={"500"}
                                                            >
                                                            {e.createdAt}
                                                        </Text>
                                                    </Box>
                                                </Stack>
                                                <Button 
                                                    bg={"#D4FD44"} 
                                                    borderRadius={"8px"}
                                                    fontSize={{base:"14px",sm:"16px",lg:"15px",xl:'16px'}}
                                                    border={"1px solid #D4FD44"}
                                                    _hover={{
                                                        bg:"none",
                                                        border:"1px solid #000"
                                                    }}
                                                    >
                                                    View Details
                                                </Button>
                                            </Stack>
                                        ))
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default MainSuperAdmin;





