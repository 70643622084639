import { Box, Button, Container, Image, Stack, Text, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, SimpleGrid} from "@chakra-ui/react";
import { useState } from "react";
import DashImg from '../../assets/images/Dashboard/dashImg.png';
import dash1 from '../../assets/images/Dashboard/dash1.png';
import DashBoardHeader from "../../components/Dashboard/Header/DashboardHeader";

const DashBoard =()=>{
    const [isOpen2, setIsOpen2] = useState(false);
    const onOpen2 = () => setIsOpen2(true);
    const onClose2 = () => setIsOpen2(false);

    return(
        <>
            <DashBoardHeader/>

            <Box p={"20px 0px"}>
                <Container maxW={"container.xl"}>
                    <Box>
                        <Text
                            fontSize={{base:'20px',sm:'24px'}}
                            fontWeight={"600"}
                            mb={"20px"}
                            >
                            Widget Themes
                        </Text>
                        <SimpleGrid minChildWidth='350px' gap={"20px"}>
                            <Box>
                                <Stack 
                                    alignItems={"center"}
                                    bg={"#EEEEEE"}
                                    p={"10px 0px"}
                                    borderRadius={"13px"}
                                    mb={"10px"}
                                    cursor={"pointer"}
                                    onClick={onOpen2}
                                    >
                                    <Box>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            mb={"10px"}
                                            >
                                            <Text
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                >
                                                Active Theme:
                                            </Text>
                                            <Box
                                                p={"3px 20px"}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                bg={"#D4FD44"}
                                                borderRadius={"6px"}
                                                >
                                                Free
                                            </Box>
                                        </Stack>
                                        <Image src={DashImg} w={"100%"}/>
                                    </Box>
                                </Stack>
                                <Modal 
                                    isOpen={isOpen2} 
                                    onClose={onClose2}
                                    isCentered
                                    >
                                    <ModalOverlay 
                                        bg={"#000"} 
                                        opacity={"0.78 ! important"}
                                    />
                                    <ModalContent
                                        maxW={{base:'350px',sm:'750px',lg:'900px',xl:'970px'}}
                                        p={{base:'10px 20px',sm:'10px 30px'}} 
                                        borderRadius={"24px"}
                                        >
                                        <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"}/>
                                        <ModalBody
                                            p={"10px 0px ! important"}
                                            >
                                            <Stack
                                                direction={"row"}        
                                                alignItems={"center"}
                                                gap={"10px"}
                                                >
                                                <Box 
                                                    display={{base:'none',sm:"block"}}    
                                                    >
                                                    <Image src={dash1}/>
                                                </Box>
                                                <Box maxW={"430px"}>
                                                    <Stack
                                                        direction={"row"}    
                                                        alignItems={"center"}
                                                        justifyContent={"space-between"}
                                                        mb={"25px"}
                                                        >
                                                        <Text
                                                            fontSize={{base:'18px',sm:"22px"}}
                                                            fontWeight={"600"}
                                                            >
                                                            Defult Theme
                                                        </Text>
                                                        {/* <Box
                                                            p={"3px 20px"}
                                                            bg={"#D4FD44"}
                                                            borderRadius={"6px"}
                                                            fontSize={"14px"}
                                                            fontWeight={"500"}
                                                            >
                                                            Premium
                                                        </Box> */}
                                                    </Stack>
                                                    <Text
                                                        fontSize={{base:'15px',sm:"18px"}}
                                                        fontWeight={"500"}
                                                        mb={"20px"}
                                                        >
                                                        About Theme
                                                    </Text>
                                                    <Text
                                                        fontSize={{base:'13px',sm:'15px'}}
                                                        >
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    </Text>
                                                    <Box 
                                                        pt={{base:'30px',sm:'80px'}} 
                                                        textAlign={{base:"center",sm:"unset"}}
                                                        >
                                                        <Button
                                                            h={"auto"}
                                                            p={{base:'9px 25px',sm:"12px 30px"}}
                                                            bg={"#D4FD44"}
                                                            borderRadius={"12px"}
                                                            fontSize={{base:'14px',sm:"18px"}}
                                                            fontWeight={"500"}
                                                            >
                                                            Buy Now: $10.00
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                                <Text 
                                    fontSize={{base:'16px',sm:"20px"}}
                                    fontWeight={"500"}
                                    >
                                    Theme 1
                                </Text>
                            </Box>
                            <Box>
                                <Stack 
                                    alignItems={"center"}
                                    bg={"#EEEEEE"}
                                    p={"10px 0px"}
                                    borderRadius={"13px"}
                                    mb={"10px"}
                                    pos={"relative"}
                                    >
                                    <Box>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            mb={"10px"}
                                            >
                                            <Text
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                >
                                                Active Theme:
                                            </Text>
                                            <Button
                                                h={"auto"}
                                                p={"4px 20px"}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                bg={"#D4FD44"}
                                                >
                                                Free
                                            </Button>
                                        </Stack>
                                        <Image src={DashImg} w={"100%"}/>
                                    </Box>
                                    <Stack 
                                        pos={"absolute"} 
                                        m={"0px ! important"}
                                        top={"0"} 
                                        w={"100%"} 
                                        h={"100%"} 
                                        bg={"#FFFFFF"} 
                                        opacity={"0.75"} 
                                        alignItems={"center"} 
                                        justifyContent={"center"}
                                        >
                                        <Text 
                                            color={"#000 ! important"} 
                                            fontSize={"24px"} 
                                            fontWeight={"500"}
                                            >
                                            Coming Soon
                                        </Text>
                                    </Stack>
                                </Stack>
                                <Text 
                                    fontSize={{base:'16px',sm:"20px"}}
                                    fontWeight={"500"}
                                    >
                                    Theme 2
                                </Text>
                            </Box>
                            <Box>
                                <Stack 
                                    alignItems={"center"}
                                    bg={"#EEEEEE"}
                                    p={"10px 0px"}
                                    borderRadius={"13px"}
                                    mb={"10px"}
                                    pos={"relative"}
                                    >
                                    <Box>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            mb={"10px"}
                                            >
                                            <Text
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                >
                                                Active Theme:
                                            </Text>
                                            <Button
                                                h={"auto"}
                                                p={"4px 20px"}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                bg={"#D4FD44"}
                                                >
                                                Free
                                            </Button>
                                        </Stack>
                                        <Image src={DashImg} w={"100%"}/>
                                    </Box>
                                    <Stack 
                                        pos={"absolute"} 
                                        m={"0px ! important"}
                                        top={"0"} 
                                        w={"100%"} 
                                        h={"100%"} 
                                        bg={"#FFFFFF"} 
                                        opacity={"0.75"} 
                                        alignItems={"center"} 
                                        justifyContent={"center"}
                                        >
                                        <Text 
                                            color={"#000 ! important"} 
                                            fontSize={"24px"} 
                                            fontWeight={"500"}
                                            >
                                            Coming Soon
                                        </Text>
                                    </Stack>
                                </Stack>
                                <Text 
                                    fontSize={{base:'16px',sm:"20px"}}
                                    fontWeight={"500"}
                                    >
                                    Theme 3
                                </Text>
                            </Box>
                        </SimpleGrid>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default DashBoard;





