import { Stack, Text, Box, Container, Image, Accordion,AccordionItem,AccordionButton,AccordionPanel} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import React, { useRef } from 'react';
import { MinusIcon, AddIcon, StarIcon } from "@chakra-ui/icons";
// Components
import BuyMem from '../../components/website/Button/BuyMem';
import Header from '../../components/website/Header/index';
import Footer from '../../components/website/Footer';
//Images & Data
import heroBanner from '../../assets/images/website/home/heroBanner.png';
import topImg from '../../assets/images/website/home/topImg.png';
import heroImg from '../../assets/images/website/home/heroImg.png';
import comma from '../../assets/images/website/home/comma.png';
import left from '../../assets/images/website/home/left.png';
import right from '../../assets/images/website/home/right.png';
import prof1 from '../../assets/images/website/home/prof1.png';
import prof2 from '../../assets/images/website/home/prof2.png';
import prof3 from '../../assets/images/website/home/prof3.png';
import Discover from '../../assets/images/website/home/Discover.png';
import redefine from '../../assets/images/website/home/redefine.png';
import {FaqBox, helpBox, solutionArray, topNotch, toolBox,lovedUser } from "../../assets/data/Data";


export default function Home() {

  // 1st Swiper 
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  return (
    <>
    <Header/>

    <Box
      position={"relative"}
      pt={{base:"110px",sm:"150px",md:"150px",xl:"150px"}}
      pb={{base:"40px",sm:"0px",md:"0px",xl:"0px"}}
      borderRadius={{base:"0px 0px 60px 0px",sm:"0px 0px 92px 0px",md:"0px 0px 92px 0px",xl:"0px 0px 92px 0px"}}
      bgImage={heroBanner}
      bgPosition={"center"}
      bgSize={"cover"}
      bgRepeat={"no-repeat"}
      >
      <Container maxW={"container.xl"}>
        <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        >
          <Box>
            <Stack 
              direction={"row"}
              alignItems={"center"}
              justifyContent={{base:"center",sm:"unset",md:"unset",lg:"unset"}}
              mb={"20px"}
              >
              <Image w={"52px"} h={"52px"} src={prof1} ml={"0px"}/>
              <Image w={"52px"} h={"52px"} src={prof2} ml={"-20px ! important"}/>
              <Image w={"52px"} h={"52px"} src={prof3} ml={"-20px ! important"}/>
              <Box 
                w={"52px"} 
                h={"52px"} 
                bg={"#D4FD44"} 
                borderRadius={"50%"} 
                ml={"-20px ! important"} 
                display={"flex"} 
                alignContent={"center"}
                >
                <Text fontSize={"18px"} margin={"auto"}>+85</Text>
              </Box>
            </Stack>
            <Box 
              maxW={"570px"}
              mb={"20px"}
              >
              <Text 
                fontSize={{base:"25px",sm:"44px",md:"27px",lg:"35px",xl:"44px"}} 
                textAlign={{base:"center",sm:"unset",md:"unset",xl:"unset"}}
                fontWeight={"600"} 
                color={"#FFF"} 
                mb={"15px"}
                >
                Let’s Elevate Your Style Sense!
              </Text>
              <Text 
                fontSize={{base:"13px",sm:"16px",md:"14px",xl:"16px"}}
                textAlign={{base:"center",sm:"unset",md:"unset",xl:"unset"}}
                color={"#959595"}
                >
                Our SAAS Plugin tailors recommendations to match your unique style preferences effortlessly.
              </Text>
            </Box>
            <Box textAlign={{base:"center",sm:"unset",md:"unset",xl:"unset"}}>
              <BuyMem/>
            </Box>
          </Box>
          <Box>
            <Image 
              display={{base:"none",sm:"block",md:"block",xl:"block"}}
              src={heroImg} 
              w={{base:"634px",sm:"634px",md:"580px",xl:"555px"}}
              h={{base:"545px",sm:"545px",md:"470px",xl:"560px"}}
              mb={"-13px"}
            />
          </Box>
        </Stack>
      </Container>
    </Box>

    <Box p={"50px 0px"}>
      <Container maxW={"container.xl"}>
        <Box 
          textAlign={"center"} 
          mb={{base:"30px",sm:"80px",md:"60px",xl:"80px"}}
          >
          <Text
            fontSize={{base:"20px",sm:"30px",md:"25px",xl:"30px"}}
            fontWeight={"600"}
            lineHeight={"55px"}
            >
            How Can We Help You?
          </Text>
          <Text
            fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}}
            color={"#464646"}
            lineHeight={"26px"}
            >
            We're here to assist you in every step of the way. Here's how:
          </Text>
        </Box>
        <Stack 
          direction={"row"}
          alignItems={"start"}
          justifyContent={{base:"space-between",sm:"space-between",md:"center",lg:"space-between",xl:"space-between"}}
          flexWrap={"wrap"}
          gap={{base:"15px",sm:"0px",md:"20px",lg:"0px",xl:"0px"}}
          >
          {
            helpBox.map((val,key)=>{
              return(
                <Box key={key}>
                  <Stack 
                    bg={"#D4FD44"} 
                    w={{base:"70px",sm:"94px",md:"80px",xl:"94px"}} 
                    h={{base:"65px",sm:"89px",md:"70px",xl:"89px"}} 
                    borderRadius={"12px"}
                    alignItems={"center"} 
                    justifyContent={"center"} 
                    m={{base:"auto",sm:"unset",md:"auto",lg:"unset",xl:"unset"}}
                    >
                    <Image 
                      src={val.img}
                      w={"36px"}
                      h={"38px"}
                    />
                  </Stack>
                  <Box 
                    maxW={{base:"373px",sm:"373px",md:"325px",xl:"373px"}} 
                    textAlign={{base:"center",sm:"unset",md:"center",lg:"unset",xl:"unset"}}
                    >
                    <Text 
                      fontSize={{base:"16px",sm:"21px",md:"18px",xl:"21px"}}
                      fontWeight={"600"}
                      lineHeight={"55px"}
                      >
                      {val.head}
                    </Text>
                    <Text 
                      color={"#464646"}
                      fontSize={{base:"13px",sm:"16px",md:"14px",xl:"16px"}}
                      >
                      {val.text}
                    </Text>
                  </Box>
                </Box>
              )
            })
          }
        </Stack>
      </Container>
    </Box>

    <Box
      pos={"relative"}
      h={{base:"580px",sm:"752px",md:"570px",lg:"650px",xl:"752px"}}
      p={{base:"30px 0px",sm:"60px 0px",md:"40px 0px",lg:"60px 0px",xl:"60px 0px"}}
      borderRadius={{base:"0px 0px 60px 0px",sm:"0px 0px 92px 0px",md:"0px 0px 92px 0px",xl:"0px 0px 92px 0px"}}
      bgImage={heroBanner}
      bgPosition={"center"}
      bgSize={"cover"}
      bgRepeat={"no-repeat"}
      >
      <Container maxW={"container.xl"}>
        <Box 
          mb={{base:"50px",sm:"100px",md:"30px",lg:"50px",xl:"100px"}}
          textAlign={{base:"center",sm:"unset",md:"unset",xl:"unset"}}
          >
          <Text
            fontSize={{base:"20px",sm:"30px",md:"25px",xl:"30px"}}
            fontWeight={"600"}
            color={"#FFF"}
            lineHeight={"55px"}
            >
            Our Top-Notch Features
          </Text>
          <Text
            fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}}
            color={"#959595"}
            lineHeight={"26px"}
            >
            These factors make our Style Sense plug-in stand out from the rest:
          </Text>
        </Box>
        <Box>
          {topNotch.map((val,key)=>{
            return(
              <Box 
                key={key}
                color={"#fff"} 
                bg={"rgba(255, 255, 255, 0.05)"}
                border={"1px solid #fff"}
                borderRadius={"10px"}
                p={"10px 20px"}
                w={{base:"320px",sm:"470px",md:"365px",lg:"440px",xl:"470px"}}
                fontSize={{base:"14px",sm:"22px",md:"18px",xl:"22px"}}
                fontWeight={"500"}
                cursor={"pointer"}
                m={{base:"auto",sm:"unset",md:"unset",xl:"unset"}}
                mb={"25px ! important"}
                className="ourTop"
                >
                {val.head}
                <Box 
                  fontSize={{base:"12px",sm:"13px",md:"13px",xl:"13px"}} 
                  color={"#15171F"} 
                  className="topNotch" 
                  transition={"all 0.4s"}
                  >
                  {val.text}
                </Box>
              </Box>
            )
          })}
        </Box>
      </Container>
      <Box 
        pos={"absolute"} 
        bottom={"0"} 
        right={"0"} 
        display={{base:"none",sm:"block",md:"block",xl:"block"}}
        >
        <Image 
          src={topImg} 
          w={{base:"749px",sm:"749px",md:"370px",lg:"545px",xl:"749px"}} 
          h={{base:"648px",sm:"648px",md:"400px",lg:"510px",xl:"648px"}}
        />
      </Box>
    </Box>

    <Box
      p={"40px 0px"}
      >
      <Container maxW={"container.xl"}>
        <Stack
          pos={"relative"}
          h={{base:"unset",sm:"unset",md:"unset",lg:"390px",xl:"470px"}}
          bg={"#D4FD44"} 
          justifyContent={"center"}
          p={{base:"30px 30px",sm:"35px 35px",md:"35px 35px",lg:"60px 40px",xl:"60px 40px"}}
          borderRadius={"12px"}
          >
          <Box 
            maxW={{base:"522px",sm:"522px",md:"522px",lg:"460px",xl:"522px"}}
            m={{base:"unset",sm:"auto",md:"auto",lg:"unset",xl:"unset"}}
            textAlign={{base:"unset",sm:"unset",md:"left",lg:"unset",xl:"unset"}}
            pb={{base:"unset",sm:"unset",md:"15px",lg:"unset",xl:"unset"}}
            >
            <Text
              fontSize={{base:"18px",sm:"23px",md:"23px",lg:"21px",xl:"23px"}}
              fontWeight={"600"}
              mb={"20px"}
              textAlign={{base:"unset",sm:"center",md:"center",lg:"unset",xl:"unset"}}
              >
              Discover the Future of Fashion:
            </Text>
            <Text
              fontSize={{base:"14px",sm:"16px",md:"16px",lg:"14px",xl:"16px"}}
              color={"#464646"}
              mb={"15px"}
              >
              <Text
                as={"span"}
                fontWeight={"600"}
                >
                Personalized Recommendations:{" "}
              </Text>
              Style Sense redefines how businesses and individuals interrelate with fashion. With our state-of-the-art algorithms and data analytics, businesses can provide personalized recommendations based on customers’ preferences, thus giving them a better shopping experience.
            </Text>
            <Text
              fontSize={{base:"14px",sm:"16px",md:"16px",lg:"14px",xl:"16px"}}
              color={"#464646"}
              >
              <Text
                as={"span"}
                fontWeight={"600"}
                >
                Engaging Shopping Experience:{" "}
              </Text>
              Businesses can adopt technology that improves customer retention by using Style Sense. Customized recommendations make customers loyal as each shopping hunt transforms into a remarkable act by the very virtue of personalizing their experience
            </Text>
          </Box>
          <Box 
            pos={{base:"absolute",sm:"absolute",md:"unset",lg:"absolute",xl:"absolute"}} 
            right={{base:"40px",sm:"40px",md:"40px",lg:"25px",xl:"40px"}} 
            bottom={"0"}
            >
            <Image 
              src={Discover} 
              w={{base:"470px",sm:"470px",md:"520px",lg:"440px",xl:"550px"}}
              m={{base:"unset",sm:"unset",md:"auto",lg:"unset",xl:"unset"}}
              display={{base:"none",sm:"block",md:"block",lg:"block",xl:"block"}}
            />
          </Box>
        </Stack>
      </Container>
    </Box>

    <Box>
      <Container maxW={"container.xl"}>
        <Stack
          pos={"relative"}
          h={{base:"unset",sm:"unset",md:"unset",lg:"260px",xl:"306px"}}
          bg={"#F1F1F1"}
          justifyContent={"center"}
          p={{base:"30px 30px",sm:"60px 40px",md:"35px 35px",lg:"60px 40px",xl:"60px 40px"}} 
          borderRadius={"12px"}
          >
          <Box 
            maxW={{base:"522px",sm:"522px",md:"600px",lg:"460px",xl:"522px"}}
            m={{base:"unset",sm:"unset",md:"auto",lg:"unset",xl:"unset"}}
            textAlign={{base:"unset",sm:"unset",md:"center",lg:"unset",xl:"unset"}}
            pb={{base:"unset",sm:"unset",md:"15px",lg:"unset",xl:"unset"}}
            >
            <Text
              fontSize={{base:"18px",sm:"23px",md:"23px",lg:"21px",xl:"23px"}}
              fontWeight={"600"}
              mb={"20px"}
              >
              Join us in Redefining Fashion:
            </Text>
            <Text
              fontSize={{base:"14px",sm:"16px",md:"16px",lg:"14px",xl:"16px"}}
              color={"#464646"}
              >
              Whether you are an individual looking for a customer-oriented shopping experience or a business hoping to transform global ecommerce, Style Sense is your chance to create the future of fashion. Become different, be yourself, and get closer to fashion than ever before.
            </Text>
          </Box>
          <Box
            pos={{base:"absolute",sm:"absolute",md:"unset",lg:"absolute",xl:"absolute"}} 
            right={{base:"40px",sm:"40px",md:"40px",lg:"25px",xl:"40px"}} 
            bottom={"0"}
            >
            <Image 
              src={redefine} 
              m={{base:"unset",sm:"unset",md:"auto",lg:"unset",xl:"unset"}}
              w={{base:"470px",sm:"470px",md:"550px",lg:"440px",xl:"550px"}}
              display={{base:"none",sm:"block",md:"block",lg:"block",xl:"block"}}
            />
          </Box>
        </Stack>
      </Container>
    </Box>

    <Box p={{base:"30px 0px",sm:"40px 0px",md:"40px 0px",xl:"40px 0px"}}>
      <Container maxW={"container.xl"}>
        <Box mb={"50px"}>
          <Stack 
            direction={"row"} 
            alignItems={"center"} 
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={{base:"20px",sm:"unset",md:"unset",lg:"unset"}}
            >
            <Box w={{base:"590px",sm:"590px",md:"360px",lg:"490px",xl:"590px"}}>
              <Text
                fontSize={{base:"17px",sm:"24px",md:"18px",lg:"20px",xl:"24px"}}
                fontWeight={"500"}
                textAlign={"center"}
                mb={"15px"}
                >
                Connects to the tools you already use
              </Text>
              <Box
                h={"290px"}
                display={"flex"}
                alignItems={"center"}
                bg={"#D4FD44"}
                borderRadius={"12px"}
                >
                <Stack 
                  direction={"row"} 
                  alignItems={"center"} 
                  justifyContent={"center"} 
                  gap={"22px"}
                  flexWrap={"wrap"}
                  >
                  {toolBox.map((val,key)=>{
                    return(
                      <Box 
                        key={key} 
                        display={"flex"} 
                        alignItems={"center"} 
                        justifyContent={"center"} 
                        bg={"#FFF"} 
                        w={{base:"100px",sm:"134px",md:"110px",lg:"127px",xl:"134px"}} 
                        h={{base:"65px",sm:"96px",md:"65px",lg:"85px",xl:"96px"}} 
                        borderRadius={"8px"}
                        >
                        <Image src={val.img}/>
                      </Box>
                    )
                  })}
                </Stack>
              </Box>
            </Box>
            <Box w={{base:"590px",sm:"590px",md:"360px",lg:"490px",xl:"590px"}}>
              <Text
                fontSize={{base:"17px",sm:"24px",md:"18px",lg:"20px",xl:"24px"}}
                fontWeight={"500"}
                textAlign={"center"}
                mb={"15px"}
                >
                Loved by users everywhere
              </Text>
              <Box 
                h={"290px"} 
                bg={"#F1F1F1"} 
                p={"33px 30px"} 
                borderRadius={"12px"}
                >
                <Stack 
                  alignItems={"center"} 
                  gap={"10px"}
                  >
                  {lovedUser.map((val,key)=>{
                    return(
                      <Stack 
                        key={key}
                        direction={"row"} 
                        alignItems={"center"} 
                        gap={"25px"}
                        >
                        <Box 
                          bg={"#FFF"} 
                          w={"102px"} 
                          h={"63px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderRadius={"8px"}
                          >
                          <Image src={val.img}/>
                        </Box>
                        <Box>
                          <Text
                            fontSize={"18px"}
                            fontWeight={"500"}
                            color={"#000"}
                            >
                            {val.digit}
                          </Text>
                          <Stack direction={"row"} alignItems={"center"}>
                            <StarIcon color={"#E7B919"}/>
                            <StarIcon color={"#E7B919"}/>
                            <StarIcon color={"#E7B919"}/>
                            <StarIcon color={"#E7B919"}/>
                            <StarIcon color={"#E7B919"}/>
                          </Stack>
                        </Box>
                      </Stack>
                    )
                  })}
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Text
            fontSize={{base:"20px",sm:"30px",md:"25px",xl:"30px"}}
            fontWeight={"600"}
            textAlign={"center"}
            mb={"50px"}
            >
            See What Our Clients Say
          </Text>
          <Box>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              spaceBetween={"20px"}
              loop={true}
              >
              {solutionArray.map((val,key)=>{
                  return(
                    <SwiperSlide key={key}>
                      <Box>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexWrap={"wrap"}
                          gap={"30px"}
                          >
                          <Image 
                          src={val.img}
                          w={{base:'160px',sm:'227px',md:'227px',xl:'227px'}}
                          h={{base:'150px',sm:'215px',md:'215px',xl:'215px'}}
                          />
                          <Box maxW={"670px"}>
                            <Image 
                              src={comma} 
                              mb={"15px"} 
                              w={{base:'35px',sm:'44px',md:'44px',xl:'44px'}}
                            />
                            <Text 
                              fontSize={{base:"16px",sm:"20px",md:"17px",xl:"20px"}} 
                              mb={"15px"}
                              >
                              {val.text}
                            </Text>
                            <Box>
                              <Text 
                                fontSize={{base:"14px",sm:"18px",md:"18px",xl:"18px"}} 
                                fontWeight={"600"} 
                                mb={"5px"}
                                >
                                {val.name}
                              </Text>
                              <Text 
                                fontSize={{base:"13px",sm:"13px",md:"13px",xl:"13px"}} 
                                fontWeight={"500"}
                                >
                                {val.des}
                              </Text>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </SwiperSlide>
                  )
              })}
            </Swiper>
          </Box>
          <Stack 
            direction={"row"} 
            alignItems={"center"} 
            justifyContent={{base:"center",sm:"flex-end",md:"flex-end",xl:"flex-end"}} 
            gap={"10px"}
            m={"15px 0px"}
            >
            <Image 
              src={left} 
              w={"30px"} 
              h={"18px"} 
              onClick={handlePrev} 
              cursor={"pointer"}
            />
            <Image 
              src={right} 
              w={"30px"} 
              h={"18px"} 
              onClick={handleNext} 
              cursor={"pointer"}
            />
          </Stack>
        </Box>
      </Container>
    </Box>

    <Box p={{base:"30px 0px",sm:"40px 0px",md:"40px 0px",xl:"40px 0px"}}>
      <Container maxW={"container.xl"}>
        <Box maxW={"800px"}>
          <Text 
            fontSize={{base:"20px",sm:"30px",md:"25px",xl:"30px"}} 
            fontWeight={"600"} 
            mb={"15px"}
            >
            Frequently Asked Questions and Resources
          </Text>
          <Text fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}} >
            Do you have questions about Yourname? See the list below for our most frequently asked questions.
          </Text>
          <Text fontSize={{base:"13px",sm:"16px",md:"16px",xl:"16px"}} >
            If your question is not listed here, then please{" "}
            <Text 
              as={Link} 
              to={"#"} 
              fontWeight={"500"}
              >
              Contact Us!
            </Text>
          </Text>
        </Box>
        <Box m={"30px 0px"}>
          <Accordion defaultIndex={[0]} allowMultiple>
            {FaqBox.map((val,key)=>{
              return(
                <AccordionItem
                  key={key}
                  border={"none"}
                  bg={"#F1F1F1"}
                  w={{base:"340px",sm:"750px",md:"730px",lg:"950px",xl:"1070px"}}
                  mb={"20px"}
                  borderRadius={"10px"}
                  >
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          bg={"none"}
                          p={"15px 40px"} 
                          boxShadow={"none"}
                          borderRadius={"10px 10px 0px 0px"}
                          _expanded={{ bg: '#D4FD44', color: '#15171F' }}
                          >
                          <Box 
                            as={"span"} 
                            flex={'1'} 
                            textAlign={'left'} 
                            fontSize={{base:"14px",sm:"22px",md:"18px",xl:"22px"}} 
                            fontWeight={"500"}
                            >
                            {val.head}
                          </Box>
                          {isExpanded ? (
                            <MinusIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        p={"20px 40px"} 
                        borderRadius={"0px 0px 10px 10px"}
                        bg={"#D4FD44"}
                        >
                        <Text 
                          textAlign='left' 
                          fontSize={"14px"} 
                          fontWeight={"400"}
                          color={"#15171F"}
                          maxW={"1018px"}
                          >
                          {val.text}
                        </Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              )
            })}
          </Accordion>
        </Box>
      </Container>
    </Box>

    <Box p={{base:"30px 0px",sm:"40px 0px",md:"40px 0px",xl:"40px 0px"}}>
      <Container maxW={"container.xl"}>
        <Box textAlign={"center"}>
            <Text
              fontSize={{base:"20px",sm:"30px",md:"25px",xl:"30px"}}
              fontWeight={"600"}
              mb={"5px"}
              >
              Ready to Get Started?
            </Text>
            <Text
              fontSize={{base:"13px",sm:"16px",md:"14px",xl:"16px"}}
              mb={"20px"}
              >
              Style Sense – where fashion meets technology, and every style finds its perfect match.
            </Text>
            <Box>
              <BuyMem/>
            </Box>
        </Box>
      </Container>
    </Box>

    <Footer/>
    </>
  );
}