import { Box, FormLabel, Image, Input, Stack, Text,Button,Icon, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsEyeSlash, BsEye } from 'react-icons/bs';
//Images
import loginImg from '../../assets/images/website/Auth/loginImg.png';
import backArrow from '../../assets/images/website/Auth/backArrow.png';
import { POST } from "../../utilities/ApiProvider";
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from "../../reducers/useReducers";


const Login=()=>{
    const [showpass, setshowpass] = useState(false);

    // setup up login related states
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: ''
      });


    // ending login related states


    // creating a login method here

    const submitLogin  = async() =>
    {
        setIsLoading(true);

        try
        {
           
            let res = await POST(`/auth/login`,loginInfo);
            console.log('login resp ',res);
            
            
           
            if(res.status == 200)
            {
                toast({
                    description : "Login Successfull!"
                })

                if(res.data.type == 'admin')
                {
                    dispatch(loadUser(res.data))
                    navigate('/mainsuperadmin')

                }
                else
                {
                    dispatch(loadUser(res.data))
                    navigate('/dashboard')

                }

             
            }
            else
            {
                toast({
                    description: res.message
                });
            }
            
        }
        catch(error)
        {
            toast({
                description: error.message
              });
        }
    }
    
    // Ending Login method



    return(
        <>
        <Box
            w={"100vw"}
            h={"100vh"}
            display={"flex"}
            alignItems={"center"}
            >
            <Box 
                display={{base:'none',sm:"none",md:"none",lg:"block",xl:"block"}}
                w={"44%"} 
                h={"100vh"} 
                bgImage={loginImg} 
                bgPos={"center"} 
                bgSize={"cover"} 
                bgRepeat={"no-repeat"}
                >
            </Box>
            <Stack
                w={{base:"100%",sm:"100%",md:"100%",lg:"56%",xl:"56%"}} 
                h={"100vh"}
                >
                <Stack 
                    pos={"relative"} 
                    alignItems={"center"} 
                    justifyContent={"center"}
                    w={{base:"350px",sm:"580px",md:"580px",lg:"500px",xl:"580px"}}
                    h={{base:"100vh",sm:"90vh"}}
                    m={"auto"}
                    >
                    <Box w={"inherit"} position={'relative'}>
                        <Box 
                            pos={"absolute"} 
                            top={{base:"-40px"}} 
                            left={{base:"0",lg:"20px",xl:"0"}}
                            >
                            <Stack direction={"row"} alignItems={"center"}>
                                <Link to={"/"}>
                                    <Image src={backArrow}/>
                                </Link>
                                <Text fontWeight={"500"}>Back</Text>
                            </Stack>
                        </Box>
                        <Box> 
                   
                            <Text
                                fontSize={{base:"20px",sm:"28px",md:"28px",lg:"25px",xl:"28px"}}
                                fontWeight={"700"}
                                mb={"10px"}
                                >
                                Log In
                            </Text>
                            <Text
                                fontSize={{base:"13px",sm:"16px",md:"16px",lg:"15px",xl:"16px"}}
                                color={"#464646"}
                                mb={"20px"}
                                >
                                Enter your details to log in your account
                            </Text>
                            <Stack
                                mb={"20px"}
                                >
                                <FormLabel 
                                    htmlFor="email" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Email Address
                                </FormLabel>
                                <Box>
                                    <Input 
                                        type={"email"}
                                        onChange={e => setLoginInfo({
                                            ...loginInfo,
                                            email: e.target.value
                                          })}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none",border:"1px solid #000"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"email" }
                                        name={"email"} 
                                        autoComplete={"email"}
                                    />
                                </Box>
                            </Stack>
                            <Stack 
                                mb={"5px"}
                                >
                                <FormLabel 
                                    htmlFor="pass" 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Password
                                </FormLabel>
                                <Box pos={"relative"}>
                                    <Input
                                        type={showpass ? 'text' : 'password'}
                                        onChange={e => setLoginInfo({
                                            ...loginInfo,
                                            password: e.target.value
                                          })}
                                        h={"auto"}
                                        p={{base:"6px 20px",sm:"10px 30px"}}
                                        borderRadius={"12px"}
                                        bg={"#EAEAEA"}
                                        fontSize={{base:"14px",sm:"18px",md:"18px",lg:"16px",xl:"18px"}}
                                        fontWeight={"500"}
                                        border={"1px solid rgba(0, 0, 0, 0.45)"}
                                        _focusVisible={{boxShadow:"none"}}
                                        placeholder={"Enter here"}
                                        _placeholder={{
                                            color:"#000"
                                        }}
                                        id={"pass" }
                                        name={"pass"} 
                                        autoComplete={"pass"}
                                    />
                                    <Button 
                                        onClick={() => setshowpass(!showpass)} 
                                        pos={"absolute"} 
                                        right={{base:"5px",sm:"10px"}} 
                                        top={{base:"0px",sm:"2px",md:"2px",lg:"3px"}} 
                                        bg={"none"}
                                        >
                                        <Icon as={showpass ? BsEye : BsEyeSlash} />
                                    </Button>
                                </Box>
                            </Stack>
                            <Stack 
                                alignItems={"flex-end"} 
                                mb={"20px"}
                                >
                                <Text
                                    as={Link}
                                    to={"/forgotpassword"}
                                    textDecorationLine={"underline ! important"}
                                    fontSize={{base:"14px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                >
                                    Forgot Password ?
                                </Text>
                            </Stack>
                            <Button 
                                onClick={submitLogin}
                                as={Link} 
                                to={"#"}
                                w={"100%"}
                                h={"auto"}
                                p={{base:"8px 0px",sm:"12px 0px"}}
                                bg={"#D4FD44"}
                                border={"1px solid #D4FD44"}
                                fontSize={{base:"14px",sm:"16px",md:"16px",lg:"16px",xl:"16px"}}
                                borderRadius={"12px"}
                                mb={"20px"}
                                _hover={{bg:"none",border:"1px solid #000"}}
                                >
                                Login
                            </Button>
                            <Text 
                                fontSize={{base:"14px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}} 
                                textAlign={"center"}
                                >
                                Don’t have an account ?{" "}
                                <Text 
                                    as={Link} 
                                    to={"/signup"} 
                                    fontWeight={"600"}
                                    >
                                    Sign Up
                                </Text>
                            </Text>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        </>
    )
}

export default Login;