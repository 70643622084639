import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { GET } from '../utilities/ApiProvider'
import Cookies from 'js-cookie'

// Basic Global State
const initialState = {};

// State Updating Functions
const userReducer = createSlice({
    name: 'person',
    initialState,
    reducers: {
        loadUser: (state, action) => {
            state.value = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        loadMembership: (state, action) => {
            state.membership = action.payload;
            localStorage.setItem('loadMembership', JSON.stringify(action.payload));
        },
        loadEmail : (state,action) =>{
            console.log(action.payload)
            state.email = action.payload;
            localStorage.setItem('email',action.payload)
        },
        loadAdminPic: (state, action) => {
            state.value.picture = action.payload;
            localStorage.setItem('styleuser', JSON.stringify(state.value));
        },
    }
})

// Exporting All the State Updating Functions
export const { loadUser,loadMembership,loadEmail,loadAdminPic } = userReducer.actions
export default userReducer.reducer
