import { Box, Button, Checkbox, Container, FormLabel, Image, Input, Select, Stack, Text , isloading, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
  } from '@chakra-ui/react'
import DashBoardHeader from "../../components/Dashboard/Header/DashboardHeader";
import fileImg from '../../assets/images/Dashboard/fileImg.png';
import { filterItem } from "../../assets/data/Data";
import { useSelector } from "react-redux";
import { GET, POST } from "../../utilities/ApiProvider";

const SearchFilter =()=>{


    const accountInfo = useSelector(state => state.value);

    const [widgetData,setWidgetData] = useState({})
    const [isloading,setisloading] = useState(false)
    const [products,setProduct] = useState([])
    const [search,setSearch] = useState([])
    
    const toast = useToast()
    // Handle On Change

    const handleChange = (e) => {
        let { name, value } = e.target;
        if(name == 'total_sales')
        {
            value = Number(value);
        }
        setSearch(prevSearch => {
            const updatedSearch = prevSearch.filter(item => item.field !== name); // Remove existing entry for the same field
            return [...updatedSearch, { field: name, value: value }]; // Add new entry
        });
    };




    // ApplY Filter

    const applyFilter = async() =>
    {
        try
        {
            let response = await POST(`/vendor/search-filter/${accountInfo.widgetKey}`,search,{ authorization: `Bearer ${accountInfo?.jwtToken}` });
            if(response.status == 200)
            {
                setProduct(response.data);
              
            }  
            else
            {
                toast({
                    description : response.message
                })
            }          
            
        }
        catch(error)
        {

        }
    }


    const fetchData = async () => {
        setisloading(true)
        try {
            // Your code here will execute only once after the component mounts
          
            let widget = await GET('/vendor/fetch-widget-options', { authorization: `Bearer ${accountInfo?.jwtToken}` });
            if (widget.status == 200) {
                setWidgetData(widget.data);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setisloading(false)
    };

    useEffect(() => {
      

        fetchData();

        // If you need to do some cleanup when the component unmounts, return a function
        return () => {
            // Your cleanup code here
        };
    }, [accountInfo]);


    const [priceRange, setPriceRange] = useState([5, 15]);

    const handleSliderChange = (newRange) => {
      setPriceRange(newRange);
    };
    return(
        <>
        <Box h={"100vh"}>
            <DashBoardHeader/>

            <Box p={"20px 0px"}>
                <Container maxW={"container.xl"}>
                    <Stack
                        direction={"row"}    
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        mb={"35px"}
                        gap={6}
                        >
                        <Text
                            fontSize={{base:'20px',sm:"24px"}}
                            fontWeight={"600"}
                            >
                            Filters
                        </Text>
                        <Text
                            fontSize={{base:"14px",sm:'16px'}}
                            fontWeight={"500"}    
                            >
                 
                        </Text>
                    </Stack>
                    <Stack 
                        direction={"row"} 
                        alignItems={"flex-start"} 
                        justifyContent={"flex-start"}
                        flexWrap={"wrap"}
                        gap={{base:'20px',lg:'8'}}
                        >
                        <Stack 
                            maxW={"335px"} 
                            p={"20px 20px"} 
                            bg={"#EEEEEE"} 
                            borderRadius={"12px"} 
                            gap={"10px"}
                            >
                                {console.log(widgetData)}
                            {
                                
                                widgetData?.name ?
                             <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    Search
                                </FormLabel>
                                <Input 

                                    onChange={handleChange}
                                    name = "name"
                                    type="text"
                                    bg={"#DBDBDB"}
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    placeholder="Search Here"
                                    _placeholder={{
                                        color:"#000"
                                    }}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                />
                            </Box>:''

                            }

                            {
                                
                                widgetData?.name ?
                             <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    SKU
                                </FormLabel>
                                <Input 
                                    onChange={handleChange}
                                    name = "sku"
                                    type="text"
                                    bg={"#DBDBDB"}
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    placeholder="Search Here"
                                    _placeholder={{
                                        color:"#000"
                                    }}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                />
                            </Box>:''

                            }

                            {
                                
                                widgetData?.name ?
                             <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    Dimensions length
                                </FormLabel>
                                <Input 
                                    type="text"
                                    onChange={handleChange}
                                    name = "dimensions.length"
                                    bg={"#DBDBDB"}
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    placeholder="Search Here"
                                    _placeholder={{
                                        color:"#000"
                                    }}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                />
                            </Box>:''

                            }

                            {
                                
                                widgetData?.name ?
                             <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    Dimensions Width
                                </FormLabel>
                                <Input 

                                    onChange={handleChange}
                                    name = "dimensions.width"
                                    type="text"
                                    bg={"#DBDBDB"}
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    placeholder="Search Here"
                                    _placeholder={{
                                        color:"#000"
                                    }}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                />
                            </Box>:''

                            }
                            {
                                
                                widgetData?.name ?
                             <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    Dimensions Height
                                </FormLabel>
                                <Input 

                                    onChange={handleChange}
                                    name = "dimensions.height"

                                    type="text"
                                    bg={"#DBDBDB"}
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    placeholder="Search Here"
                                    _placeholder={{
                                        color:"#000"
                                    }}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                />
                            </Box>:''

                            }
                            {
                                
                                widgetData?.total_sales ?
                             <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    Total Sales
                                </FormLabel>
                                <Input 
                                    onChange={handleChange}
                                    name = "total_sales"

                                    type="number"
                                    bg={"#DBDBDB"}
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    placeholder="Search Here"
                                    _placeholder={{
                                        color:"#000"
                                    }}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                />
                            </Box>:''

                            }
                         
                            
                            <Box>
                                <FormLabel
                                    fontSize={"15px"}
                                    fontWeight={"500"}
                                    >
                                    Categories
                                </FormLabel>
                                <Select
                                    onChange={handleChange}
                                     name = "categories.category"
                                    bg={"#DBDBDB"}
                                    placeholder='Choose an option'
                                    fontSize={{base:'15px',sm:"17px"}}
                                    fontWeight={"500"}
                                    _focusVisible={{
                                        boxShadow:"none"
                                    }}
                                    >
                                           {

                                            widgetData?.categories?.map((e) =>
                                                <option value={e._id}>{e.title}</option>
                                            )  
                                            
                                            }
                                  

                                </Select>
                            </Box>
                            
                            <Stack>
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}                 
                                        >
                                        Best Sellers
                                    </Text>
                                </Stack>
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}                 
                                        >
                                        Is Virtual
                                    </Text>
                                </Stack>
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}                 
                                        >
                                        Is taxable
                                    </Text>
                                </Stack>
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}                 
                                        >
                                        Shipping Required
                                    </Text>
                                </Stack>

                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}                 
                                        >
                                        Shipping Taxable
                                    </Text>
                                </Stack>

                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}                 
                                        >
                                        Best Rated
                                    </Text>
                                </Stack>
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}     
                                        >
                                        Top Rated
                                    </Text>
                                </Stack>
                                <Stack
                                    direction={"row"}    
                                    alignItems={"center"}
                                    gap={"5px"}
                                    >
                                    <Checkbox borderColor={"#464646"}/>
                                    <Text
                                        fontSize={{base:'13px',sm:'16px'}}    
                                        >
                                        Related Products
                                    </Text>
                                </Stack>
                            </Stack>
                            <Box>
                                <Text 
                                    fontSize={{base:'14px',sm:'16px'}} 
                                    >
                                    $Price Range
                                </Text>
                                <Box mb={"20px"}>
                                {
                                     widgetData?.price ?
                                     <RangeSlider
                                        aria-label={['min', 'max']}
                                        defaultValue={widgetData?.price}
                                        onChange={handleSliderChange}
                                    >
                                        <RangeSliderTrack>
                                        <RangeSliderFilledTrack bg="#D4FD44" />
                                        </RangeSliderTrack>
                                        <RangeSliderThumb index={0} bg="#D4FD44">
                                        <Box fontSize={"12px"} position="absolute" bottom="-25px" left="50%" transform="translateX(-50%)">
                                            ${widgetData?.price?.[0]}
                                        </Box>
                                        </RangeSliderThumb>
                                        <RangeSliderThumb index={1} bg="#D4FD44">
                                        <Box fontSize={"12px"} position="absolute" bottom="-25px" left="50%" transform="translateX(-50%)">
                                            ${widgetData?.price?.[1]}
                                        </Box>
                                        </RangeSliderThumb>
                                    </RangeSlider>:''
                                }
                                    
                                </Box>
                                
                                
                            </Box>
                            
                            
                            <Button 
                                onClick={applyFilter}
                                bg={"#D4FD44"} 
                                borderRadius={"12px"} 
                                border={"1px solid #D4FD44"} 
                                fontSize={{base:"14px",sm:"16px"}}
                                _hover={{bg:"none",border:"1px solid #000"}}
                                >
                                    Apply
                            </Button>
                        </Stack>
                        
                        
                        <Stack
                            ml={"0px ! important"}
                            maxW={{base:'330px',sm:'375px',lg:"630px",xl:'800px'}}
                            direction={"row"}
                            flexWrap={"wrap"}
                            justifyContent={'flex-start'}
                            gap={"35px"}
                            >
                            { products.map((val,key)=>(
                                <Box 
                                    key={key} 
                                    ml={"0px ! important"}
                                    w={{base:'370px',lg:"295px",xl:'370px'}} 
                                    >
                                    <Box    
                                        bg={"#EEEEEE"}
                                        p={{base:'15px 15px',sm:"15px 0px",lg:'15px 15px',xl:"15px 0px"}}
                                        borderRadius={"12px"}
                                        >
                                        <Image src={val.picture} m={"auto"}/>
                                    </Box>
                                    <Box p={"5px 5px"}>
                                        <Stack 
                                            direction={"row"} 
                                            alignItems={"center"} 
                                            justifyContent={"space-between"}
                                            mb={"10px"}
                                            >
                                            <Box>
                                                <Text
                                                    fontSize={{base:'17px',sm:"20px",lg:"18px",xl:'20px'}}
                                                    fontWeight={"500"}
                                                    mb={"5px"}
                                                    >
                                                    {val.name}
                                                </Text>
                                                <Text 
                                                    fontSize={{base:"14px",sm:"16px",lg:'15px',xl:'16px'}}
                                                    fontWeight={"500"}
                                                    >
                                                    {val.category}
                                                </Text>
                                            </Box>
                                            <Text
                                                fontSize={{base:'18px',sm:"24px",lg:"20px",xl:"24px"}}
                                                fontWeight={"500"}
                                                >
                                                {val.price}
                                            </Text>
                                        </Stack>
                                        <a 
                                            href={val.slug}
                                            target="_blanck"
                                            link  = {val.slug}
                                            h={"auto"}
                                            w={"100%"}
                                            p={{base:'6px 0px',sm:"9px 0px",lg:"7px 0px",xl:'9px 0px'}}
                                            border={"1px solid #D4FD44"}
                                            bg={"#D4FD44"}
                                            fontSize={{base:'15px',sm:"18px", lg:'16px',xl:'18px'}}
                                            fontWeight={"500"}
                                            _hover={{
                                                bg:"none",
                                                border:"1px solid #000"
                                            }}
                                            >
                                            View Product Details
                                        </a>
                                    </Box>
                                </Box>
                            ))}
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
        </>
    )
}

export default SearchFilter;