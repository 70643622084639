import { Box, FormLabel, Image, Stack, Text,Button, HStack, PinInput,PinInputField, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
//Images
import loginImg from '../../assets/images/website/Auth/loginImg.png';
import backArrow from '../../assets/images/website/Auth/backArrow.png';
import { useSelector , useDispatch } from "react-redux";
import { useState } from "react";
import { POST } from "../../utilities/ApiProvider";
import { loadEmail } from "../../reducers/useReducers";

const Verification=()=>{
    const accountInfo = useSelector(state => state.email);

    const [data,setData] = useState({
        email : accountInfo.email,
        otp : 2281
    })
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();




    // Hanlde Form Submission

    const forgetPassword = async() =>
    {
        try
        {
            let res = await POST(`/auth/verify-otp`,data,{ authorization: `Bearer ${accountInfo?.jwtToken}` });
            console.log(res);
            if(res.status == 200)
            {
                toast({
                    description : res.message
                })
               
                navigate('/SetNewPassword')

            }
        }
        catch(error)
        {

        }
    }


    return(
        <>
        <Box
            w={"100vw"}
            h={"100vh"}
            display={"flex"}
            alignItems={"center"}
            >
            <Box 
                display={{base:'none',sm:"none",md:"none",lg:"block",xl:"block"}}
                w={"44%"} 
                h={"100vh"} 
                bgImage={loginImg} 
                bgPos={"center"} 
                bgSize={"cover"} 
                bgRepeat={"no-repeat"}
                >
            </Box>
            <Stack
                w={{base:"100%",sm:"100%",md:"100%",lg:"56%",xl:"56%"}} 
                h={"100vh"}
                >
                <Stack
                    pos={"relative"} 
                    alignItems={"center"} 
                    justifyContent={"center"}
                    w={{base:"350px",sm:"580px",md:"580px",lg:"500px",xl:"580px"}}
                    h={{base:"100vh",sm:"90vh"}}
                    m={"auto"}
                    >
                    <Box w={"inherit"}>
                        <Box 
                            pos={"absolute"} 
                            top={{base:"20px",sm:"0"}} 
                            left={{base:"0",lg:"20px",xl:"0"}}
                            >
                            <Stack direction={"row"} alignItems={"center"}>
                                <Link to={"#"}>
                                    <Image src={backArrow}/>
                                </Link>
                                <Text fontWeight={"500"}>Back</Text>
                            </Stack>
                        </Box>
                        <Box> 
                            
                            <Text
                                fontSize={{base:"20px",sm:"28px",md:"28px",lg:"25px",xl:"28px"}}
                                fontWeight={"700"}
                                mb={"10px"}
                                >
                                Verification
                            </Text>
                            <Text
                                fontSize={{base:"13px",sm:"16px",md:"16px",lg:"15px",xl:"16px"}}
                                color={"#464646"}
                                mb={"20px"}
                                >
                                Enter your code that we sent you on your email
                            </Text>
                            <Stack 
                                mb={"50px"}
                                >
                                <FormLabel 
                                    fontSize={{base:"15px",sm:"17px",md:"17px",lg:"16px",xl:"17px"}}
                                    fontWeight={"500"}
                                    >
                                    Code
                                </FormLabel>
                                <Box className="verifield">
                                    <HStack justifyContent={"space-between"}>
                                        <PinInput placeholder="-">
                                            <PinInputField/>
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                        </PinInput>
                                    </HStack>
                                </Box>
                            </Stack>
                            <Button 
                                onClick={forgetPassword}
                                as={Link} 
                                to={"#"}
                                w={"100%"}
                                h={"auto"}
                                p={{base:"8px 0px",sm:"15px 0px"}}
                                fontSize={{base:"13px",sm:"16px",md:"16px",lg:"15px",xl:"16px"}}
                                bg={"#D4FD44"}
                                border={"1px solid #D4FD44"}
                                borderRadius={"12px"}
                                mb={"20px"}
                                _hover={{bg:"none",border:"1px solid #000"}}
                                >
                                Verify
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        </>
    )
}

export default Verification;