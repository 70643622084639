import { Box, Flex, Text, IconButton, Button, Stack, Collapse, Icon, useColorModeValue, useDisclosure, Container, Image, FormLabel, Input, useClipboard, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Select, Switch, useToast, } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from 'react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import plugin from '../../assets/images/website/home/plugin.png';
import { useSelector } from 'react-redux';
import {POST} from '../../utilities/ApiProvider';
import ssLogo from '../../assets/images/website/home/sslogo.png';

export default function SuperHeader() {
    const { isOpen, onToggle } = useDisclosure();
    const location = useLocation();

    // Create a Membership Plan

    const StaticAuthenticationKeyInput = () => {
        const staticKey = "pkFGZ5upw2";
        const { hasCopied, onCopy } = useClipboard(staticKey);
        return (
            <Box pos={"relative"}>
                <Input
                    value={staticKey}
                    isReadOnly
                    type='text'
                    w={{ base: '300px', sm: '420px', lg: "360px", xl: '420px' }}
                    h={'auto'}
                    fontSize={{ base: "13px", sm: "15px" }}
                    fontWeight={"500"}
                    p={{ base: "5px 15px", sm: '7px 20px' }}
                    border={"1px solid #000 ! important"}
                    placeholder='pkFGZ5upw2'
                    _placeholder={{
                        color: "#363636"
                    }}
                    _focusVisible={{
                        boxShadow: "none"
                    }}
                />
                <Button
                    onClick={onCopy}
                    pos={"absolute"}
                    h={"auto"}
                    fontSize={{ base: '12px', sm: '16px', lg: "14px", xl: "16px" }}
                    p={{ base: '9px 25px', sm: "12px 45px", lg: '12px 35px', xl: "12px 45px" }}
                    top={"-1px"}
                    right={"-2px"}
                    bg={"#D4FD44"}
                >
                    {hasCopied ? "Copied!" : "Copy"}</Button>
            </Box>
        );
    };

    return (
        <Box
            bg={"#15171F"}
            borderRadius={"0px 0px 25px 25px"}
        >
            <Container maxW={"container.xl"}>
                <Flex
                    bg={'none'}
                    color={useColorModeValue('gray.600', 'white')}
                    minH={'60px'}
                    p={"30px 0px"}
                    borderBottom={"1px solid #FFFFFF73"}
                    align={'center'}>
                    <Flex
                        flex={{ base: 1, lg: 'auto' }}
                        ml={{ base: -2 }}
                        display={{ base: 'flex', lg: 'none' }}>

                        <IconButton
                            onClick={onToggle}
                            icon={
                                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                            }
                            variant={'ghost'}
                            aria-label={'Toggle Navigation'}
                        />
                    </Flex>
                    <Flex
                        flex={{ base: 1, sm: "none", lg: 1 }}
                        justify={{ base: 'center', lg: 'start' }}
                        alignItems={"center"}
                        justifyContent={{ base: "right", sm: "space-between", md: "space-between", lg: "space-between", xl: "space-between" }}
                    >
                        <Link to={"/mainsuperadmin"}>
                        <Image  
                src={ssLogo} 
                w={{base:"110px",sm:"150px",md:"150px",lg:"150px",xl:"150px"}}
              />
                        </Link>

                        <Flex
                            display={{ base: 'none', lg: 'flex' }}
                            ml={10}
                        >
                            <DesktopNav />
                        </Flex>
                    </Flex>
                </Flex>



                <Collapse in={isOpen} animateOpacity>
                    <MobileNav />
                </Collapse>

                {location.pathname === "/dashboard" ?
                    <Stack
                        p={"30px 0px"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={{ base: 'center', lg: "space-between" }}
                        flexWrap={"wrap"}
                        gap={{ base: "10px", lg: "0px" }}
                    >
                        <Box
                            bg={"#FFF"}
                            w={{ base: "330px", sm: "550px", xl: '670px' }}
                            p={{ base: '10px 15px', sm: '15px 20px', lg: "20px 30px" }}
                            borderRadius={"13px"}
                        >
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={{ base: '5px', lg: "10px" }}
                                >
                                    <Stack

                                        w={{ base: '40px', sm: '72px' }}
                                        h={{ base: '40px', sm: '72px' }}
                                        bg={"#D4FD44"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        borderRadius={"50%"}
                                    >
                                        <Image
                                            src={plugin}
                                            w={{ base: "20px", sm: '30px' }}
                                            h={{ base: "20px", sm: '30px' }}
                                        />
                                    </Stack>
                                    <Box ml={"0px ! important"}>
                                        <Text
                                            fontSize={{ base: '16px', sm: '22px', lg: '20px', xl: "22px" }}
                                            fontWeight={"500"}
                                        >
                                            Download Plugin
                                        </Text>
                                        <Text
                                            fontSize={{ base: '13px', sm: '20px', lg: '18px', xl: '20px' }}
                                            textDecorationLine={"underline"}
                                        >
                                            How to install the plugin?
                                        </Text>
                                    </Box>
                                </Stack>
                                <Button
                                    as={Link}
                                    to={"#"}
                                    h={"auto"}
                                    p={{ base: "8px 8px", sm: "15px 35px", lg: '10px 25px', xl: "15px 35px" }}
                                    fontSize={{ base: '12px', sm: '18px', lg: '16px', xl: "18px" }}
                                    fontWeight={"500"}
                                    bg={"#D4FD44"}
                                    borderRadius={"12px"}
                                >
                                    Download
                                </Button>
                            </Stack>
                        </Box>
                        <Box
                            ml={"0px ! important"}
                            bg={"#FFF"}
                            w={{ base: "330px", sm: "550px", lg: "400px", xl: '480px' }}
                            p={"20px 0px"}
                            borderRadius={"13px"}
                        >
                            <Stack alignItems={"center"}>
                                <Box>
                                    <FormLabel
                                        fontSize={{ base: "14px", sm: '16px' }}
                                        fontWeight={"500"}
                                    >
                                        Authentication Key:
                                    </FormLabel>
                                    <StaticAuthenticationKeyInput />
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                    : null
                }
            </Container>
        </Box>
    );
}

const NAV_ITEMS = [
    {
        label: 'Home',
        href: "/mainsuperadmin",
        isModal: false
    },
    {
        label: 'MemberShip',
        href:"/membership",
      
    },
    // {
    //     label: 'Membership Plans',
    //     href: "#",
    //     isModal: true,
    //     modal: "onOpenMembership"
    // },
    {
        label: 'Users',
        href: "/users",
        isModal: false
    }
];

const DesktopNav = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [feature,setFeature] = useState([]);
    const toast = useToast()
    const initialFeatures = [{ text: "Feature 1" }, { text: "Feature 2" }, { text: "Feature 3" }];

    const [featureInputs, setFeatureInputs] = useState([]);
    const [features, setFeatures] = useState([]);
    const [signupInfo,setSignupInfo] = useState({
        
        features : featureInputs
    })

    // // Get Membership Data

    // console.log
    const membership = useSelector(state => state.membership);

    const createPlan = async(req,res) =>
    {
         
        try
        {
            let res = await POST(`/admin/plans`,signupInfo);
            if(res.status == 200)
            {
                toast({
                    description : res.message
                })
            }
            else
            {
                toast({
                    description : res.message
                })
            }
        }
        catch(error)
        {
            console.log(error.message)
        }
            
        
       
    }

    const addFeature = () => {
        setFeatures([...features, ""]);
        setFeatureInputs([...featureInputs, ""]); // Initialize input value for the new feature
    };

    const handleFeatureChange = (index, value) => {
        const newFeatureInputs = [...featureInputs];
        newFeatureInputs[index] = value;
        setFeatureInputs(newFeatureInputs);
    };


    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();



    // Define state variables for controlling modals
    const [isOpen3, setIsOpen3] = useState(false);
    const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
    // Function to open "Widget" modal
    const onOpen3 = () => setIsOpen3(true);
    // Function to open "Membership Plans" modal
    const onOpenMembershipModal = () => setIsMembershipModalOpen(true);

    // Define state variables for controlling modals
    const [isOpen4, setIsOpen4] = useState(false);
    // Function to open "Widget" modal
    const onOpen4 = () => setIsOpen4(true);
    return (
        <>
            <Stack direction={'row'} spacing={4} alignItems={"center"}>
                {NAV_ITEMS.map((navItem) => (
                    <Box key={navItem.label}>
                        <Button
                            onClick={() => {
                                if (navItem.isModal) {
                                    if (navItem.modal === "onOpen3") {

                                        onOpen3();
                                    } else if (navItem.modal === "onOpenMembership") {
                                        onOpenMembershipModal();
                                    }
                                } else {
                                    navigate(navItem.href);
                                }
                            }}
                            fontSize={{ base: '14px', sm: '16px', md: '16px', lg: '15px', xl: '16px' }}
                            fontWeight={"500"}
                            color={location.pathname === navItem.href ? "#15171F" : "#848484"}
                            bg={location.pathname === navItem.href ? "#D4FD44" : "none"}
                            textDecorationLine={location.pathname === navItem.href ? "none ! important" : "none"}
                            p={{ lg: "8px 10px", xl: "8px 15px" }}
                            height={"auto"}
                            borderRadius={"8px"}
                            _hover={{
                                textDecorationLine: "none ! important",
                                background: "#D4FD44",
                                color: "#15171F",
                            }}
                        >
                            {navItem.label}
                        </Button>

                        {/* Widget Modal  */}
                        <Modal
                            isOpen={isOpen3}
                            onClose={() => setIsOpen3(false)}
                            isCentered
                        >
                            <ModalOverlay bg={"#0000004f"} />
                            <ModalContent
                                maxW={{ base: '350px', sm: "646px" }}
                                p={{ base: '10px 20px', sm: "15px 50px" }}
                                borderRadius={"24px"}
                            >
                                <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                                <ModalBody
                                    p={"10px 0px ! important"}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        mb={"15px"}
                                    >
                                        <Text
                                            fontSize={"22px"}
                                            fontWeight={"600"}
                                        >
                                            Widget
                                        </Text>
                                        <Button
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            bg={"#D4FD44"}
                                        >
                                            Save & Continue
                                        </Button>
                                    </Stack>
                                    <Box mb={"20px"}>
                                        <Box
                                            mb={"15px"}
                                        >
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                mb={"5px"}
                                            >
                                                <Text
                                                    fontSize={"17px"}
                                                    fontWeight={"500"}
                                                >
                                                    Enter Placeholder Name
                                                </Text>
                                                <Stack
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    gap={"5px"}
                                                >
                                                    <Text
                                                        fontSize={"17px"}
                                                        fontWeight={"500"}
                                                    >
                                                        Premium
                                                    </Text>
                                                    <Switch />
                                                </Stack>
                                            </Stack>
                                            <Input
                                                bg={"#EEEEEE"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                placeholder='Search Products Via $Price'
                                                _placeholder={{ color: "#000" }}
                                                _focusVisible={{ boxShadow: "none" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box mb={"20px"}>
                                        <Box
                                            mb={"15px"}
                                        >
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                mb={"5px"}
                                            >
                                                <Text
                                                    fontSize={"17px"}
                                                    fontWeight={"500"}
                                                >
                                                    Enter Placeholder Name
                                                </Text>
                                                <Stack
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    gap={"5px"}
                                                >
                                                    <Text
                                                        fontSize={"17px"}
                                                        fontWeight={"500"}
                                                    >
                                                        Premium
                                                    </Text>
                                                    <Switch />
                                                </Stack>
                                            </Stack>
                                            <Input
                                                bg={"#EEEEEE"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                placeholder='Search Products Via Image'
                                                _placeholder={{ color: "#000" }}
                                                _focusVisible={{ boxShadow: "none" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Stack alignItems={"flex-end"}>
                                        <Text fontWeight={"500"}>Add New Feature</Text>
                                    </Stack>
                                </ModalBody>
                            </ModalContent>
                        </Modal>

                        {/* Membership Plan Modal  */}
                        <Modal
                            isOpen={isMembershipModalOpen}
                            onClose={() => setIsMembershipModalOpen(false)}
                            isCentered
                        >
                            <ModalOverlay bg={"#0000004f"} />
                            <ModalContent
                                maxW={{ base: '350px', sm: "692px", xl: "1166px" }}
                                p={{ base: '10px 20px', sm: "15px 50px" }}
                                borderRadius={"24px"}
                            >
                                <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                                <ModalBody
                                    p={"10px 0px ! important"}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        mb={"30px"}
                                    >
                                        <Box>
                                            <Text
                                                fontSize={"22px"}
                                                fontWeight={"600"}
                                            >
                                                Current Plans
                                            </Text>
                                            <Text
                                                fontSize={"15px"}
                                            >
                                                You’ve currently 3 plans
                                            </Text>
                                        </Box>
                                        <Button
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            bg={"#D4FD44"}
                                            borderRadius={"12px"}
                                            onClick={() => {
                                                setIsMembershipModalOpen(false); // Close the first modal
                                                onOpen4(); // Open the second modal
                                            }}
                                        >
                                            Create New Plan
                                        </Button>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                    >
                                        {membership?.map((e) =>
                                            <Box
                                                maxW={"345px"}
                                                borderRadius={"13px"}
                                                bg={"#EEEEEE"}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                    bg={"#15171F"}
                                                    borderRadius={"13px 13px 0px 0px"}
                                                    p={"15px 20px"}
                                                >
                                                    <Text
                                                        fontSize={"22px"}
                                                        fontWeight={"600"}
                                                        color={"#FFF"}
                                                    >
                                                        ${e.price}
                                                    </Text>
                                                    <Text
                                                        bg={"#D4FD44"}
                                                        fontSize={"13px"}
                                                        fontWeight={"500"}
                                                        p={"2px 8px"}
                                                        borderRadius={"6px"}
                                                    >
                                                        {e.duration}
                                                    </Text>
                                                </Stack>
                                                <Box
                                                    p={"15px 20px"}
                                                >
                                                    <Text
                                                        fontWeight={"500"}
                                                        mb={"10px"}
                                                    >
                                                        Features:
                                                    </Text>
                                                    <Stack
                                                        mb={"35px"}
                                                    >
                                                        {
                                                            e.features?.map((fe) =>
                                                                <Stack
                                                                    direction={"row"}
                                                                    alignItems={"center"}
                                                                    gap={"3px"}
                                                                >
                                                                    <Stack
                                                                        alignItems={"center"}
                                                                        justifyContent={"center"}
                                                                        bg={"#D4FD44"}
                                                                        borderRadius={"50%"}
                                                                        w={"18px"}
                                                                        h={"18px"}
                                                                    >
                                                                        <CheckIcon fontSize={"12px"} />
                                                                    </Stack>
                                                                    <Text
                                                                        fontSize={"14px"}
                                                                    >
                                                                        Lorem ipsum dolor sit amet
                                                                    </Text>
                                                                </Stack>
                                                            )
                                                        }


                                                    </Stack>
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        justifyContent={"space-between"}
                                                    >
                                                        <Button
                                                            onClick={onEditOpen}
                                                            bg={"#D4FD44"}
                                                            border={"1px solid #D4FD44"}
                                                            h={"auto"}
                                                            w={"140px"}
                                                            p={"5px 0px"}
                                                            fontSize={"15px"}
                                                            fontWeight={"500"}
                                                            _hover={{
                                                                bg: "none",
                                                                border: "1px solid #000"
                                                            }}

                                                        >
                                                            Edit Plan
                                                        </Button>
                                                        <Button
                                                            bg={"none"}
                                                            border={"1px solid #000"}
                                                            h={"auto"}
                                                            w={"140px"}
                                                            p={"5px 0px"}
                                                            fontSize={"15px"}
                                                            fontWeight={"500"}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        )}


                                    </Stack>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                        {/* create new plan Modal  */}
                        <Modal
                            isOpen={isOpen4}
                            onClose={() => setIsOpen4(false)}
                            isCentered
                        >
                            <ModalOverlay bg={"#0000004f"} />
                            <ModalContent
                                maxW={{ base: '350px', sm: "645px" }}
                                p={{ base: '10px 20px', sm: "15px 50px" }}
                                borderRadius={"24px"}
                            >
                                <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                                <ModalBody
                                    p={"10px 0px ! important"}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        mb={"15px"}
                                    >
                                        <Text
                                            fontSize={"22px"}
                                            fontWeight={"600"}
                                        >
                                            Create New Plan
                                        </Text>
                                        <Button
                                            onClick={createPlan}
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            bg={"#D4FD44"}
                                        >
                                            Save & Continue
                                        </Button>
                                    </Stack>
                                    <Box mb={"20px"}>
                                        <Box
                                            mb={"15px"}
                                        >
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                mb={"5px"}
                                            >
                                                <Text
                                                    fontSize={"17px"}
                                                    fontWeight={"500"}
                                                >
                                                    Plan Type
                                                </Text>
                                       
                                            </Stack>
                                            <Select
                                                onChange={e => setSignupInfo({
                                                    ...signupInfo,
                                                    title: e.target.value
                                                  })}
                                                bg={"#EEEEEE"}
                                                borderRadius={"12px"}
                                                border={"1px solid #00000073"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                _focusVisible={{ boxShadow: "none" }}
                                            >
                                                <option value='Monthly'>Monthly</option>
                                                <option value='Yearly' >Yearly</option>
                                                <option value='Free Trail'>Free Trail</option>
                                            </Select>
                                        </Box>
                                        <Box>
                                            <FormLabel
                                                fontSize={"17px"}
                                                fontWeight={"500"}
                                            >
                                                $Price
                                            </FormLabel>
                                            <Input
                                                onChange={e => setSignupInfo({
                                                    ...signupInfo,
                                                    price: e.target.value
                                                  })}
                                                bg={"#EEEEEE"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                placeholder='Enter here'
                                                _focusVisible={{ boxShadow: "none" }}
                                            />
                                        </Box>
                                        <Box>
                                            <FormLabel
                                                fontSize={"17px"}
                                                fontWeight={"500"}
                                            >
                                                Duration
                                            </FormLabel>
                                            <Input
                                                onChange={e => setSignupInfo({
                                                    ...signupInfo,
                                                    duration: e.target.value
                                                  })}
                                                bg={"#EEEEEE"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                placeholder='Enter here'
                                                _focusVisible={{ boxShadow: "none" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Stack gap={"10px"}>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                        >
                                            <Text
                                                    fontSize={"20px"}
                                                    fontWeight={"500"}
                                                >
                                                    Features
                                                </Text>
                                                <Button
                                                onClick={addFeature}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                bg={"#D4FD44"}
                                            >
                                                Feature
                                            </Button>
                                        </Stack>
                                        {features.map((feature, index) => (
                                                <Stack key={index}>
                                                    <Input
                                                        bg={"#EEEEEE"}
                                                        fontSize={"18px"}
                                                        fontWeight={"500"}
                                                        placeholder='Enter here'
                                                        value={featureInputs[index] || ''} // Use corresponding input value
                                                        onChange={(event) => handleFeatureChange(index, event.target.value)}
                                                        _focusVisible={{ boxShadow: "none" }}
                                                    />
                                                </Stack>
                                            ))}
                                        
                                        
                                    </Stack>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                        {/* create new plan Modal  */}
                        <Modal
                            isOpen={isEditOpen}
                            onClose={onEditClose}
                            isCentered
                        >
                            <ModalOverlay bg={"#0000004f"} />
                            <ModalContent
                                maxW={{ base: '350px', sm: "645px" }}
                                p={{ base: '10px 20px', sm: "15px 50px" }}
                                borderRadius={"24px"}
                            >
                                <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                                <ModalBody
                                    p={"10px 0px ! important"}
                                >
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        mb={"15px"}
                                    >
                                        <Text
                                            fontSize={"22px"}
                                            fontWeight={"600"}
                                        >
                                            Edit Plan
                                        </Text>
                                        <Button
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            bg={"#D4FD44"}
                                        >
                                            Save & Continue
                                        </Button>
                                    </Stack>
                                    <Box mb={"20px"}>
                                        <Box
                                            mb={"15px"}
                                        >
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                mb={"5px"}
                                            >
                                                <Text
                                                    fontSize={"17px"}
                                                    fontWeight={"500"}
                                                >
                                                    Plan Type
                                                </Text>
                                                <Stack
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    gap={"5px"}
                                                >
                                                    <Text
                                                        fontSize={"17px"}
                                                        fontWeight={"500"}
                                                    >
                                                        Free Trial
                                                    </Text>
                                                    <Switch />
                                                </Stack>
                                            </Stack>
                                            <Select
                                                bg={"#EEEEEE"}
                                                borderRadius={"12px"}
                                                border={"1px solid #00000073"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                _focusVisible={{ boxShadow: "none" }}
                                            >
                                                <option>Monthly</option>
                                                <option>Yearly</option>
                                            </Select>
                                        </Box>
                                        <Box>
                                            <FormLabel
                                                fontSize={"17px"}
                                                fontWeight={"500"}
                                            >
                                                $Price
                                            </FormLabel>
                                            <Input
                                                bg={"#EEEEEE"}
                                                fontSize={"18px"}
                                                fontWeight={"500"}
                                                placeholder='Enter here'
                                                _focusVisible={{ boxShadow: "none" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Stack gap={"10px"}>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                        >
                                            <Text
                                                fontSize={"20px"}
                                                fontWeight={"500"}
                                            >
                                                Features
                                            </Text>
                                            <Text
                                                fontWeight={"500"}
                                            >
                                                Add New Feature
                                            </Text>
                                        </Stack>
                                        <Input
                                            bg={"#EEEEEE"}
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            placeholder='Enter here'
                                            _focusVisible={{ boxShadow: "none" }}
                                        />
                                        <Input
                                            bg={"#EEEEEE"}
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            placeholder='Enter here'
                                            _focusVisible={{ boxShadow: "none" }}
                                        />
                                        <Input
                                            bg={"#EEEEEE"}
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            placeholder='Enter here'
                                            _focusVisible={{ boxShadow: "none" }}
                                        />
                                    </Stack>
                                </ModalBody>
                            </ModalContent>
                        </Modal>

                    </Box>
                ))}
                <Button
                    as={Link}
                    to={"/login"}
                    h={"auto"}
                    p={{ lg: "7px 10px", xl: '7px 15px' }}
                    bg={"#D4FD44"}
                    fontSize={{ base: '14px', sm: '16px', md: '16px', lg: '15px', xl: '17px' }}
                    fontWeight={"500"}
                    color={"#15171F"}
                    border={"1px solid transparent"}
                    borderRadius={"8px"}
                    _hover={{
                        bg: "none",
                        color: "#D4FD44",
                        border: "1px solid #D4FD44"
                    }}
                >
                    Log Out
                </Button>
            </Stack>
        </>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ lg: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}

        </Stack>
    );
};

const MobileNavItem = ({ label, children, href, isModal, modal }) => {
    const { isOpen, onToggle } = useDisclosure();
    const navigate = useNavigate();
    // Define state variables for controlling modals
    const [isOpen3, setIsOpen3] = useState(false);
    const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
    // Function to open "Widget" modal
    const onOpen3 = () => setIsOpen3(true);
    // Function to open "Membership Plans" modal
    const onOpenMembershipModal = () => setIsMembershipModalOpen(true);

    // Define state variables for controlling modals
    const [isOpen4, setIsOpen4] = useState(false);
    // Function to open "Widget" modal
    const onOpen4 = () => setIsOpen4(true);

    // Define state variables for controlling modals
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}
                    // onClick={()=> isModal ? {modal} : navigate(`${href}`)}
                    onClick={() => {
                        if (isModal) {
                            if (modal === "onOpen3") {
                                onOpen3();
                            } else if (modal === "onOpenMembership") {
                                onOpenMembershipModal();
                            }
                        } else {
                            navigate(href);
                        }
                    }}
                >
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} to={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>

            {/* Widget Modal  */}
            <Modal
                isOpen={isOpen3}
                onClose={() => setIsOpen3(false)}
                isCentered
            >
                <ModalOverlay bg={"#0000004f"} />
                <ModalContent
                    maxW={{ base: '350px', sm: "646px" }}
                    p={{ base: '10px 20px', sm: "15px 50px" }}
                    borderRadius={"24px"}
                >
                    <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                    <ModalBody
                        p={"10px 0px ! important"}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={"15px"}
                        >
                            <Text
                                fontSize={{ base: "18px", sm: "22px" }}
                                fontWeight={"600"}
                            >
                                Widget
                            </Text>
                            <Button
                                fontSize={{ base: "15px", sm: "18px" }}
                                fontWeight={"500"}
                                bg={"#D4FD44"}
                            >
                                Save & Continue
                            </Button>
                        </Stack>
                        <Box mb={"20px"}>
                            <Box
                                mb={"15px"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={"5px"}
                                >
                                    <Text
                                        fontSize={{ base: "14px", sm: "17px" }}
                                        fontWeight={"500"}
                                    >
                                        Enter Placeholder Name
                                    </Text>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={"5px"}
                                    >
                                        <Text
                                            fontSize={{ base: "14px", sm: "17px" }}
                                            fontWeight={"500"}
                                        >
                                            Premium
                                        </Text>
                                        <Switch />
                                    </Stack>
                                </Stack>
                                <Input
                                    bg={"#EEEEEE"}
                                    fontSize={{ base: "14px", sm: "18px" }}
                                    fontWeight={"500"}
                                    placeholder='Search Products Via $Price'
                                    _placeholder={{ color: "#000" }}
                                    _focusVisible={{ boxShadow: "none" }}
                                />
                            </Box>
                        </Box>
                        <Box mb={"20px"}>
                            <Box
                                mb={"15px"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={"5px"}
                                >
                                    <Text
                                        fontSize={{ base: "14px", sm: "17px" }}
                                        fontWeight={"500"}
                                    >
                                        Enter Placeholder Name
                                    </Text>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={"5px"}
                                    >
                                        <Text
                                            fontSize={{ base: "14px", sm: "17px" }}
                                            fontWeight={"500"}
                                        >
                                            Premium
                                        </Text>
                                        <Switch />
                                    </Stack>
                                </Stack>
                                <Input
                                    bg={"#EEEEEE"}
                                    fontSize={{ base: "15px", sm: "18px" }}
                                    fontWeight={"500"}
                                    placeholder='Search Products Via Image'
                                    _placeholder={{ color: "#000" }}
                                    _focusVisible={{ boxShadow: "none" }}
                                />
                            </Box>
                        </Box>
                        <Stack
                            alignItems={"flex-end"}
                        >
                            <Text
                                fontSize={{ base: "14px", sm: "16px" }}
                                fontWeight={"500"}
                            >
                                Add New Feature
                            </Text>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>


            {/* Membership Plan Modal  */}
            <Modal
                isOpen={isMembershipModalOpen}
                onClose={() => setIsMembershipModalOpen(false)}
                isCentered
            >
                <ModalOverlay bg={"#0000004f"} />
                <ModalContent
                    maxW={{ base: '350px', sm: "692px", xl: "1166px" }}
                    p={{ base: '10px 20px', sm: "15px 50px" }}
                    borderRadius={"24px"}
                >
                    <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                    <ModalBody
                        p={"10px 0px ! important"}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={"30px"}
                        >
                            <Box>
                                <Text
                                    fontSize={"22px"}
                                    fontWeight={"600"}
                                >
                                    Current Plans
                                </Text>
                                <Text
                                    fontSize={"15px"}
                                >
                                    You’ve currently 3 plans
                                </Text>
                            </Box>
                            <Button
                                fontSize={"18px"}
                                fontWeight={"500"}
                                bg={"#D4FD44"}
                                borderRadius={"12px"}
                                onClick={() => { setIsMembershipModalOpen(false); onOpen4(); }}
                            >
                                Create New Plan
                            </Button>
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Box
                                maxW={"345px"}
                                borderRadius={"13px"}
                                bg={"#EEEEEE"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    bg={"#15171F"}
                                    borderRadius={"13px 13px 0px 0px"}
                                    p={"15px 20px"}
                                >
                                    <Text
                                        fontSize={"22px"}
                                        fontWeight={"600"}
                                        color={"#FFF"}
                                    >
                                        $20.00
                                    </Text>
                                    <Text
                                        bg={"#D4FD44"}
                                        fontSize={"13px"}
                                        fontWeight={"500"}
                                        p={"2px 8px"}
                                        borderRadius={"6px"}
                                    >
                                        Monthly
                                    </Text>
                                </Stack>
                                <Box
                                    p={"15px 20px"}
                                >
                                    <Text
                                        fontWeight={"500"}
                                        mb={"10px"}
                                    >
                                        Features:
                                    </Text>
                                    <Stack
                                        mb={"35px"}
                                    >
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Lorem ipsum dolor sit amet
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Consectetur adipiscing elit
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Ut enim ad minim
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Duis aute irure
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Excepteur sint occaecat cupidatat
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <Button
                                            bg={"#D4FD44"}
                                            border={"1px solid #D4FD44"}
                                            h={"auto"}
                                            w={"140px"}
                                            p={"5px 0px"}
                                            fontSize={"15px"}
                                            fontWeight={"500"}
                                            _hover={{
                                                bg: "none",
                                                border: "1px solid #000"
                                            }}
                                            onClick={onEditOpen}
                                        >
                                            Edit Plan
                                        </Button>
                                        <Button
                                            bg={"none"}
                                            border={"1px solid #000"}
                                            h={"auto"}
                                            w={"140px"}
                                            p={"5px 0px"}
                                            fontSize={"15px"}
                                            fontWeight={"500"}
                                        >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box
                                maxW={"345px"}
                                borderRadius={"13px"}
                                bg={"#EEEEEE"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    bg={"#15171F"}
                                    borderRadius={"13px 13px 0px 0px"}
                                    p={"15px 20px"}
                                >
                                    <Text
                                        fontSize={"22px"}
                                        fontWeight={"600"}
                                        color={"#FFF"}
                                    >
                                        $180.00
                                    </Text>
                                    <Text
                                        bg={"#D4FD44"}
                                        fontSize={"13px"}
                                        fontWeight={"500"}
                                        p={"2px 8px"}
                                        borderRadius={"6px"}
                                    >
                                        Yearly
                                    </Text>
                                </Stack>
                                <Box
                                    p={"15px 20px"}
                                >
                                    <Text
                                        fontWeight={"500"}
                                        mb={"10px"}
                                    >
                                        Features:
                                    </Text>
                                    <Stack
                                        mb={"35px"}
                                    >
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Lorem ipsum dolor sit amet
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Consectetur adipiscing elit
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Ut enim ad minim
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Duis aute irure
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Excepteur sint occaecat cupidatat
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <Button
                                            bg={"#D4FD44"}
                                            border={"1px solid #D4FD44"}
                                            h={"auto"}
                                            w={"140px"}
                                            p={"5px 0px"}
                                            fontSize={"15px"}
                                            fontWeight={"500"}
                                            _hover={{
                                                bg: "none",
                                                border: "1px solid #000"
                                            }}
                                        >
                                            Edit Plan
                                        </Button>
                                        <Button
                                            bg={"none"}
                                            border={"1px solid #000"}
                                            h={"auto"}
                                            w={"140px"}
                                            p={"5px 0px"}
                                            fontSize={"15px"}
                                            fontWeight={"500"}
                                        >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box
                                maxW={"345px"}
                                borderRadius={"13px"}
                                bg={"#EEEEEE"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    bg={"#15171F"}
                                    borderRadius={"13px 13px 0px 0px"}
                                    p={"15px 20px"}
                                >
                                    <Text
                                        fontSize={"22px"}
                                        fontWeight={"600"}
                                        color={"#FFF"}
                                    >
                                        $0.00
                                    </Text>
                                    <Text
                                        bg={"#D4FD44"}
                                        fontSize={"13px"}
                                        fontWeight={"500"}
                                        p={"2px 8px"}
                                        borderRadius={"6px"}
                                    >
                                        7 Days Free Trial
                                    </Text>
                                </Stack>
                                <Box
                                    p={"15px 20px"}
                                >
                                    <Text
                                        fontWeight={"500"}
                                        mb={"10px"}
                                    >
                                        Features:
                                    </Text>
                                    <Stack
                                        mb={"35px"}
                                    >
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Lorem ipsum dolor sit amet
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Consectetur adipiscing elit
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Ut enim ad minim
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Duis aute irure
                                            </Text>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"3px"}
                                        >
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                bg={"#D4FD44"}
                                                borderRadius={"50%"}
                                                w={"18px"}
                                                h={"18px"}
                                            >
                                                <CheckIcon fontSize={"12px"} />
                                            </Stack>
                                            <Text
                                                fontSize={"14px"}
                                            >
                                                Excepteur sint occaecat cupidatat
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <Button
                                            bg={"#D4FD44"}
                                            border={"1px solid #D4FD44"}
                                            h={"auto"}
                                            w={"140px"}
                                            p={"5px 0px"}
                                            fontSize={"15px"}
                                            fontWeight={"500"}
                                            _hover={{
                                                bg: "none",
                                                border: "1px solid #000"
                                            }}
                                        >
                                            Edit Plan
                                        </Button>
                                        <Button
                                            bg={"none"}
                                            border={"1px solid #000"}
                                            h={"auto"}
                                            w={"140px"}
                                            p={"5px 0px"}
                                            fontSize={"15px"}
                                            fontWeight={"500"}
                                        >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* create new plan Modal  */}
            <Modal
                isOpen={isEditOpen}
                onClose={onEditClose}
                isCentered
            >
                <ModalOverlay bg={"#0000004f"} />
                <ModalContent
                    maxW={{ base: '350px', sm: "645px" }}
                    p={{ base: '10px 20px', sm: "15px 50px" }}
                    borderRadius={"24px"}
                >
                    <ModalCloseButton top={"-35px"} right={"10px"} color={"#FFF"} />
                    <ModalBody
                        p={"10px 0px ! important"}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={"15px"}
                        >
                            <Text
                                fontSize={"22px"}
                                fontWeight={"600"}
                            >
                                Edit Plan
                            </Text>
                            <Button
                                fontSize={"18px"}
                                fontWeight={"500"}
                                bg={"#D4FD44"}
                            >
                                Save & Continue
                            </Button>
                        </Stack>
                        <Box mb={"20px"}>
                            <Box
                                mb={"15px"}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={"5px"}
                                >
                                    <Text
                                        fontSize={"17px"}
                                        fontWeight={"500"}
                                    >
                                        Plan Type
                                    </Text>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={"5px"}
                                    >
                                        <Text
                                            fontSize={"17px"}
                                            fontWeight={"500"}
                                        >
                                            Free Trial
                                        </Text>
                                        <Switch />
                                    </Stack>
                                </Stack>
                                <Select
                                    bg={"#EEEEEE"}
                                    borderRadius={"12px"}
                                    border={"1px solid #00000073"}
                                    fontSize={"18px"}
                                    fontWeight={"500"}
                                    _focusVisible={{ boxShadow: "none" }}
                                >
                                    <option>Monthly</option>
                                    <option>Yearly</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel
                                    fontSize={"17px"}
                                    fontWeight={"500"}
                                >
                                    $Price
                                </FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    fontSize={"18px"}
                                    fontWeight={"500"}
                                    placeholder='Enter here'
                                    _focusVisible={{ boxShadow: "none" }}
                                />
                            </Box>
                        </Box>
                        <Stack gap={"10px"}>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Text
                                    fontSize={"20px"}
                                    fontWeight={"500"}
                                >
                                    Features
                                </Text>
                                <Text
                                    fontWeight={"500"}
                                >
                                    Add New Feature
                                </Text>
                            </Stack>
                            <Input
                                bg={"#EEEEEE"}
                                fontSize={"18px"}
                                fontWeight={"500"}
                                placeholder='Enter here'
                                _focusVisible={{ boxShadow: "none" }}
                            />
                            <Input
                                bg={"#EEEEEE"}
                                fontSize={"18px"}
                                fontWeight={"500"}
                                placeholder='Enter here'
                                _focusVisible={{ boxShadow: "none" }}
                            />
                            <Input
                                bg={"#EEEEEE"}
                                fontSize={"18px"}
                                fontWeight={"500"}
                                placeholder='Enter here'
                                _focusVisible={{ boxShadow: "none" }}
                            />
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Stack>
    );
};